.displayValuesWrapper
  width: 100%
  &.displayValuesResponsiveWidth1
    .displayValuesWrapper__displayValues
      &__content
        &__information
          &__item
            max-width: 42%
            p
              display: none
  &.displayValuesResponsiveWidth2
    .displayValuesWrapper__displayValues
      &__content
        &__values
          &__item
            & > span
              font-size: $fontSizeStandard4
        &__information
          &__item
            max-width: 42%
            p
              display: none
            h4
              //word-break: break-word
              display: -webkit-box
              -webkit-line-clamp: 3
              -webkit-box-orient: vertical
              overflow: hidden
              text-overflow: ellipsis
              font-size: $fontSizeStandard-1
              line-height: $fontSizeStandard
  &__displayValues
    width: 100%
    padding: 0 2px
    &__content
      &__values
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        word-break: break-word
        &__item
          max-width: 90% !important
          & > span
            font-size: $fontSizeStandard5
            font-weight: 600
      &__barHolder
        height: 6px
        background: $grey-100
        margin-top: $paddingSmall
        &__bar
          -webkit-animation: fullWidthLeft 2s
          -moz-animation: fullWidthLeft 2s
          -o-animation: fullWidthLeft 2s
          animation: fullWidthLeft 2s
          &__dynamic
            height: 6px
            background: $colorSecondary-400
      &__information
        display: flex
        justify-content: space-between
        //flex-wrap: wrap
        margin-top: $paddingMedium
        &__item
          max-width: 36%
          word-break: break-word
          &:nth-child(2)
            text-align: right
          h4
            font-size: $fontSizeStandard1
            text-transform: none
            margin-bottom: 4px
          p
            font-size: $fontSizeStandard-2
            color: $colorTextLight-1
            line-height: $fontSizeStandard
