.dropdownWrapper
  position: relative // Important since we set position in component based on this.
  width: 100%
  min-width: 120px // Widths are also set in component dynamically.
  max-width: 100vw !important
  overflow: hidden
  &.customHeaderElement
    width: unset
    min-width: unset
  &__dropdown
    &__header
      padding: 4px $paddingSmall
      border: 1px solid $grey-100
      cursor: pointer
      background: $grey-100
      display: flex
      align-items: center
      justify-content: space-between
      white-space: nowrap
      height: 36px
      &.small
        padding: 4px $paddingSmall
        border: 1px solid $grey-100
        cursor: pointer
        background: $grey-100
        display: flex
        align-items: center
        justify-content: space-between
        white-space: nowrap
        height: 26px
      &__medium
        padding: 4px $paddingSmall
        border: 1px solid $grey-100
        cursor: pointer
        background: $grey-100
        display: flex
        align-items: center
        justify-content: space-between
        white-space: nowrap
        height: 36px
      &__big
        padding: 4px $paddingSmall
        border: 1px solid $grey-100
        cursor: pointer
        background: $grey-100
        display: flex
        align-items: center
        justify-content: space-between
        white-space: nowrap
        height: 46px
      &__XL
        padding: 4px $paddingSmall
        border: 1px solid $grey-100
        cursor: pointer
        background: $grey-100
        display: flex
        align-items: center
        justify-content: space-between
        white-space: nowrap
        height: 36px
      &__text
        &__white
          color: white
          font-weight: 400
        &__black
          color: black
          font-weight: 400
      p
        font-weight: 600
        display: block
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
      svg
        width: 26px
        height: 26px
        margin-left: $paddingMedium
      &.transparent
        @extend .dropdownWrapper__dropdown__header
        background: transparent
        border: 0
        border-bottom: 1px solid $grey-400
        &:hover
          border-color: $colorLink
          color: $colorLink
    &__content
      @extend .shadowRegular
      //@extend .scrollbarSmallDark
      @extend .scrollbarRegular
      @extend .dropdownAnimation
      z-index: 8 // Higher than Popup component.
      overflow-y: auto
      overflow-x: hidden
      max-height: 494px // This is dynamically set in component.
      min-width: 0 // This is dynamically set in component.
      width: 100%
      max-width: 420px // This is dynamically changed in component.
      background: white
      position: absolute
      top: 100% // This is dynamically changed in component.
      left: 0 // This is dynamically changed in component.
      border: 1px solid transparent
      &.tableColumnFilters
        padding: 12px
        //background-color: #FFFCF5
        box-shadow: 0 4px 12px 4px rgba(0,0,0,0.15)
        &:hover
          border: 1px solid $grey-900
      &.minimize
        @extend .dropdownAnimationMinimized
        max-height: 0

.dropdownItemWrapper
  padding: $paddingSmall * 1.2 $paddingMedium
  border-bottom: 1px solid $grey-200
  width: 100%
  overflow: hidden
  flex: 1
  &:last-of-type
    border-bottom: 0
  &:hover
    background: transparent !important
    .dropdownItemWrapper__dropdownItem
      color: $colorLink
      svg
        fill: $colorLink
  &.disabled
    pointer-events: none
    .dropdownItemWrapper__dropdownItem
      color: $colorTextLight-2 !important
      pointer-events: none !important
      .dropdownItemWrapper__dropdownItem__button
        svg
          fill: $colorTextLight-2 !important
  &.active
    .dropdownItemWrapper__dropdownItem
      color: $colorLink
      svg
        fill: $colorLink
  &__dropdownItem
    cursor: pointer
    display: flex
    align-items: center
    justify-content: space-between
    color: $colorTextMain
    width: 100%
    gap: $paddingBig
    svg
      fill: $colorTextLight-1
      margin-left: $paddingMedium
      width: 14px
      height: 14px
    &__label
      white-space: nowrap
      font-size: $fontSizeStandard1
      text-overflow: ellipsis
      text-align: left
      overflow: hidden
      width: 100%//calc(100% - #{$paddingMedium} - 14px)
      display: block
    &__button
      display: flex
      align-items: center

.dropdownNoCheckboxItemWrapper
  @extend .dropdownItemWrapper
  svg
    display: none !important

.dropdownItemDelimiterWrapper
  margin-top: $paddingBig
  color: $colorTextMain
  padding: $paddingSmall $paddingMedium
  border-bottom: 1px solid $grey-200
  &:first-child
    margin-top: 0
  &__dropdownItemDelimiter
    white-space: nowrap
    font-size: $fontSizeStandard1
    text-overflow: ellipsis
    overflow: hidden
    font-weight: 600

.dropdownItemDelimiterWithCheckboxWrapper
  margin-top: $paddingBig
  color: $colorTextMain
  padding: $paddingSmall $paddingMedium
  border-bottom: 1px solid $grey-200
  &:first-child
    margin-top: 0
  &:hover
    background: transparent !important
    cursor: pointer
    color: $colorLink !important
    svg
      fill: $colorLink !important
  &__dropdownItemDelimiterWithCheckbox
    display: flex
    &__left
      white-space: nowrap
      font-size: $fontSizeStandard1
      font-weight: 600
      text-overflow: ellipsis
      overflow: hidden
      flex-grow: 1
    &__right
      display: flex
      align-items: center
      svg
        fill: $grey-500
        margin-left: $paddingMedium
        width: 14px
        height: 14px
  &__dropdownItemDelimiterWithCheckboxActive
    @extend .dropdownItemDelimiterWithCheckboxWrapper__dropdownItemDelimiterWithCheckbox
    color: $colorLink
    svg
      fill: $colorLink

.dropdown
  position: relative
  display: flex
  align-items: baseline
  user-select: none
  &.noBorder
    border: none
  &__toggle
    &__header
      padding: 4px $paddingSmall
      border: 1px solid $grey-100
      cursor: pointer
      background: $grey-100
      display: flex
      align-items: center
      justify-content: space-between
      white-space: nowrap
      flex: 1
      &.xl
        width: 240px
      &.big
        width: 180px
      &.medium
        width: 120px
      &.small
        width: min-content
        justify-content: flex-end
        //width: 60px
      &.transparent
        @extend .dropdown__toggle__header
        background: transparent
        &:hover
          border-color: $colorLink
          color: $colorLink
      &.noBorder
        border: none
      &.noPadding
        padding: 0 !important

.dropdown .dropdown-menu
  position: absolute
  top: calc(100% + 5px)
  display: none
  z-index: 9
  background-color: #fff
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3)
  padding: 5px 0
  min-width: 100px
  font-size: 1rem

.dropdown .open
  display: inline-table
  z-index: 9
  background: white
  position: absolute
  min-width: unset
  max-width: unset
  padding: 0
  width: 100%

  &.left
    right: auto
    left: 0
  &.right
    right: 0
    left: auto
  &.top
    top: -36px*3

.dropdown-item
  cursor: pointer
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  &:hover
    background-color: #f0f0f0
  &:not(:last-child)
    border-bottom: 1px solid gainsboro
.dropdown.open .dropdown-menu
  display: block
