.foldable-section
    padding: 0 $paddingMedium $paddingBig
    flex: 1
.foldable-section.active
    padding: 0 $paddingMedium $paddingBig
.half-sections
    display: flex
    width: 100%
    gap: $paddingSmall
@media (max-width: 768px)
    .half-sections
        display: flex
        flex-direction: column
