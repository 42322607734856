.grid
  display: flex
  flex-direction: column
  flex-grow: 1
  background: rgb(240, 240, 251)
  padding-bottom: 30px

.react-grid-layout
  position: relative
  transition: height 200ms ease

.react-grid-item
  transition: all 350ms cubic-bezier(.38,1.37,.53,1.2)
  transition-property: left, top
  background-color: #FFFFFF
  box-shadow: 1px 1px 6px 1px rgba(17, 112, 124, 0.2)
  &:hover
    cursor: grab
  &:active
    cursor: grabbing

  img
    pointer-events: none
    user-select: none

  &.cssTransforms
    transition-property: transform

  &.resizing
    z-index: 1
    will-change: width, height

  &.react-draggable-dragging
    transition: none
    z-index: 3
    will-change: transform

  &.dropping
    visibility: hidden

  &.react-grid-placeholder
    background: rgb(98, 98, 240)
    opacity: 0.2
    transition-duration: 100ms
    z-index: 2
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    -o-user-select: none
    user-select: none

  > .react-resizable-handle
    position: absolute
    width: 20px
    height: 20px

    &::after
      content: ""
      position: absolute
      right: 3px
      bottom: 3px
      width: 7px
      height: 7px
      border-right: 3px solid $grey-400
      border-bottom: 3px solid $grey-400

.react-resizable-hide > .react-resizable-handle
  display: none

.react-grid-item > .react-resizable-handle
  &.react-resizable-handle-sw
    bottom: 0
    left: 0
    cursor: sw-resize
    transform: rotate(90deg)

  &.react-resizable-handle-se
    bottom: 0
    right: 0
    cursor: se-resize

  &.react-resizable-handle-nw
    top: 0
    left: 0
    cursor: nw-resize
    transform: rotate(180deg)

  &.react-resizable-handle-ne
    top: 0
    right: 0
    cursor: ne-resize
    transform: rotate(270deg)

  &.react-resizable-handle-w, &.react-resizable-handle-e
    top: 50%
    margin-top: -10px
    cursor: ew-resize

  &.react-resizable-handle-w
    left: 0
    transform: rotate(135deg)

  &.react-resizable-handle-e
    right: 0
    transform: rotate(315deg)

  &.react-resizable-handle-n, &.react-resizable-handle-s
    left: 50%
    margin-left: -10px
    cursor: ns-resize

  &.react-resizable-handle-n
    top: 0
    transform: rotate(225deg)

  &.react-resizable-handle-s
    bottom: 0
    transform: rotate(45deg)
.cancelDrag
  &:hover, &:active
    cursor: auto
