@media (max-width: $breakpoint4)
	.companyInfoWrapper
		&__companyInfo
			&__content
				&__itemsWrapper
					&__item
						width: calc(50% - ((1 * 2px) / 2))
						&:nth-child(3n)
							margin-right: 0
						&:nth-child(even)
							margin-right: 0
						&:nth-child(odd)
							margin-right: 2px

@media (max-width: $breakpoint8)
	.companyInfoWrapper
		&__companyInfo
			&__content
				&__itemsWrapper
					&__item
						width: 100%
						margin-right: 0
						&:nth-child(odd)
							margin-right: 0
