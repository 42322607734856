@import './colors';
@import './sizes';

:export {
  breakpoint1: $breakpoint1;
  breakpoint2: $breakpoint2;
  breakpoint2_5: $breakpoint2_5;
  breakpoint3: $breakpoint3;
  breakpoint4: $breakpoint4;
  breakpoint5: $breakpoint5;
  breakpoint6: $breakpoint6;
  breakpoint7: $breakpoint7;
  breakpoint8: $breakpoint8;
  breakpoint9: $breakpoint9;
  breakpoint10: $breakpoint10;
  breakpoint11: $breakpoint11;
  colorChartLight0: lighten($colorChart0, 5%);
  colorChartRegular0: $colorChart0;
  colorChartDark0: darken($colorChart0, 14%);
  colorChartOpacity0: rgba($colorChart0, 0.5);
  colorChartLight1: lighten($colorChart1, 5%);
  colorChartRegular1: $colorChart1;
  colorChartDark1: darken($colorChart1, 14%);
  colorChartOpacity1: rgba($colorChart1, 0.5);
  colorChartLight2: lighten($colorChart2, 5%);
  colorChartRegular2: $colorChart2;
  colorChartDark2: darken($colorChart2, 14%);
  colorChartOpacity2: rgba($colorChart2, 0.5);
  colorChartLight3: lighten($colorChart3, 5%);
  colorChartRegular3: $colorChart3;
  colorChartDark3: darken($colorChart3, 14%);
  colorChartOpacity3: rgba($colorChart3, 0.5);
  colorChartLight4: lighten($colorChart4, 5%);
  colorChartRegular4: $colorChart4;
  colorChartDark4: darken($colorChart4, 14%);
  colorChartOpacity4: rgba($colorChart4, 0.5);
  colorChartLight5: lighten($colorChart5, 5%);
  colorChartRegular5: $colorChart5;
  colorChartDark5: darken($colorChart5, 14%);
  colorChartOpacity5: rgba($colorChart5, 0.5);
  colorChartLight6: lighten($colorChart6, 5%);
  colorChartRegular6: $colorChart6;
  colorChartDark6: darken($colorChart6, 14%);
  colorChartOpacity6: rgba($colorChart6, 0.5);
  colorChartLight7: lighten($colorChart7, 5%);
  colorChartRegular7: $colorChart7;
  colorChartDark7: darken($colorChart7, 14%);
  colorChartOpacity7: rgba($colorChart7, 0.5);
  colorChartLight8: lighten($colorChart8, 5%);
  colorChartRegular8: $colorChart8;
  colorChartDark8: darken($colorChart8, 14%);
  colorChartOpacity8: rgba($colorChart8, 0.5);
  colorChartLight9: lighten($colorChart9, 5%);
  colorChartRegular9: $colorChart9;
  colorChartDark9: darken($colorChart9, 14%);
  colorChartOpacity9: rgba($colorChart9, 0.5);
  paddingBig: $paddingBig;
  paddingGrid: $paddingGrid;
  paddingMedium: $paddingMedium;
  paddingSmall: $paddingSmall;
  widthMinimizedNavigation: $widthMinimizedNavigation;
}
