.tooltipWrapper
	&.max-content-width
		width: max-content
	&.min-content-width
		width: min-content
	&.auto-width
		width: auto
	&.inlineTooltip
		display: inline-block
	&__tooltip__indicator
		display: flex
		align-items: center

.MuiTooltip-tooltip
	background: $colorTooltip !important
	color: white !important
	border-radius: 4px !important
	padding: ($paddingSmall + 1px) $paddingMedium !important
	font-size: $fontSizeStandard !important
	transition: unset !important
.MuiTooltip-arrow
	color: $colorTooltip !important
