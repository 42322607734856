// Breakpoints
$breakpoint-3: 1440px
$breakpoint-2: 1380px
$breakpoint-1: 1260px
$breakpoint0: 1120px
$breakpoint1: 1050px
$breakpoint2: 930px
$breakpoint2_5: 870px
$breakpoint3: 768px
$breakpoint4: 660px
$breakpoint5: 620px
$breakpoint6: 590px
$breakpoint7: 514px
$breakpoint8: 464px
$breakpoint9: 415px
$breakpoint10: 376px
$breakpoint11: 340px

// Font-size
$fontSizeStandard: 15px
$fontSizeStandard1: 16px
$fontSizeStandard2: 17px
$fontSizeStandard3: 18px
$fontSizeStandard4: 20px
$fontSizeStandard5: 23px
$fontSizeStandard6: 26px
$fontSizeStandard7: 30px
$fontSizeStandard8: 36px
$fontSizeStandard9: 42px
$fontSizeStandard10: 54px
$fontSizeStandard11: 60px
$fontSizeStandard12: 70px
$fontSizeStandard13: 80px
$fontSizeStandard14: 90px
$fontSizeStandard15: 100px
$fontSizeStandard16: 114px
$fontSizeStandard17: 130px
$fontSizeStandard18: 150px
$fontSizeStandard19: 180px
$fontSizeStandard-1: 14px
$fontSizeStandard-2: 13px
$fontSizeStandard-3: 12px
$fontSizeStandard-4: 11px
$fontSizeStandard-5: 10px

// Icon sizes
$heightSmallRoundIcon: 24px
$heightSmallRoundIconSvg: 12px
$iconSizeBig: 34px
$iconSizeBigSvg: 16px
$iconSizeXL: 52px
$iconSizeXLSvg: 26px


//Heights
$heightHeaderIcons: 34px
//$heightHomepageHeader: 740px
$heightHomepageTopMenu: 92px
$heightPageHeader: 52px

//Widths
$minWidthTable: 500px
//$widthHomepageContent: 1024px
$widthHomepageContent: 1200px
$widthMinimizedNavigation: 50px
$widthNavigation: 200px
$widthPreviewWindow: 380px // Preview for leads, deals etc.
$widthPreviewFilterWindow: 340px // The preview window for filters.
$widthProspectLabel: 140px
$widthSearchMain: 440px
$widthSearchMainMinimized: 90px
$widthSubNavigation: 196px

//Paddings
$paddingGrid: 8px // "Site padding", space between widgets etc.
$paddingBig: 22px // These are just sizes to keep it consistent, use wherever.
$paddingMedium: 12px
$paddingSmall: 8px
