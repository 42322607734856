@media (max-width: $breakpoint2)
  .ordersWrapper
    &__orders
      &__content
        &__top
          &__left
            width: 100%
            margin: 0
          &__right
            width: 100%
            margin: $paddingMedium 0 0 0
        &__bottom
          &__orderInformation
            &__left
              width: 100%
              margin: 0
            &__right
              width: 100%
              margin: 0
