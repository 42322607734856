.filterBoxWrapper
  white-space: normal
  &__filterBox
    &__content
      &__section
        display: flex
        align-items: flex-start
        margin-top: $paddingSmall
        &:first-of-type
          margin-top: 0
        &__left
          width: 142px
          display: flex
          align-items: center
          white-space: nowrap
        &__right
          width: calc(100% - 80px - #{$paddingMedium})
          margin-left: $paddingMedium
      &__recreate
        display: flex
        justify-content: flex-end
        margin-top: $paddingSmall
        color: $colorLink
        &:hover
          color: $colorLinkHover
        h4
          text-transform: none
        svg
          width: 18px
          height: 18px
          margin-left: 2px
