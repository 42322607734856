// Main search box in page header.
.searchMainWrapper
	display: flex
	align-items: center
	position: relative
	width: $widthSearchMainMinimized
	&__searchMain
		position: absolute
		top: calc(-#{$heightHeaderIcons} / 2)
		left: 0
		z-index: 3
		width: $widthSearchMainMinimized
		transition: width 300ms
		&.open
			width: $widthSearchMain
			.searchMainWrapper__searchMain__header
				&__searchForm
					margin-right: $paddingBig
		&__header
			width: 100%
			border-radius: 18px
			overflow: hidden
			background: white
			display: flex
			align-items: center
			height: $heightHeaderIcons
			&__searchIcon
				background: $colorHeaderBackground
				border-radius: 50%
				min-width: calc(#{$heightHeaderIcons} - 4px)
				width: calc(#{$heightHeaderIcons} - 4px)
				height: calc(#{$heightHeaderIcons} - 4px)
				margin-left: 2px
				padding: 5px
				cursor: pointer
				svg
					width: 100%
					height: 100%
					margin: 0
					fill: white
					cursor: pointer
			&__searchForm
				margin-left: $paddingMedium
				width: 100%
				form
					width: 100%
					input
						height: $heightHeaderIcons
						border: 0
						width: 100%
						color: $colorTextMain
						padding: 4px 0
						font-size: $fontSizeStandard
						&::placeholder
							color: $colorHeaderBackground
							font-size: $fontSizeStandard-2
						&:-ms-input-placeholder
							color: $colorHeaderBackground
							font-size: $fontSizeStandard-2
						&::-ms-input-placeholder
							color: $colorHeaderBackground
							font-size: $fontSizeStandard-2
						&::-webkit-input-placeholder
							color: $colorHeaderBackground
							font-size: $fontSizeStandard-2
						&:focus
							outline-width: 0
		&__content
			@extend .shadowRegular
			border-radius: 18px
			overflow: hidden
			background: white
			margin-top: 4px
			&__searchResult
				background: white
				padding: $paddingSmall
				&__item
					color: $colorLink
					font-size: $fontSizeStandard1
					padding: $paddingMedium 0
					margin: 0 $paddingMedium
					cursor: pointer
					&:last-child
						padding-bottom: 0
					&:hover
						color: $colorLinkHover
						a
							color: $colorLinkHover
						.iconStyled
							border-color: $colorLinkHover !important
							svg
								fill: $colorLinkHover !important
					a
						display: flex
						align-items: center
					span
						white-space: nowrap
						overflow: hidden
						text-overflow: ellipsis
						margin-left: $paddingMedium
			&__submitWrapper
				margin: $paddingSmall
				//border-radius: 12px
				//overflow: hidden
				&__submit
					border-radius: 12px
					background: $grey-100
					border: 1px solid $grey-100
					color: $colorTextLight
					cursor: pointer
					padding: $paddingMedium $paddingBig
					display: flex
					justify-content: center
					align-items: center
					font-size: $fontSizeStandard2
					font-weight: 600
					&:hover
						border-color: $colorLink
						color: $colorLink
						background: transparent
						svg
							fill: $colorLink
					svg
						fill: $colorTextLight
						width: 16px
						height: 16px
						margin-left: $paddingSmall

// Search component used for selections
.searchSelectWrapper
	width: 100%
	&__searchSelect
		&__header
			&__top
				display: flex
				align-items: center
				position: relative
				&__input
					flex-grow: 1
					height: 36px
					input
						height: 100%
						padding-right: calc(#{$paddingSmall} + 7px)
				&__icon
					display: flex
					align-items: center
					justify-content: center
					position: absolute
					bottom: 1px
					right: $paddingSmall
					background: white
					height: 100%
					svg
						width: 20px
						height: 20px
						fill: $grey-500
			&__bottom
				position: relative
				&__searchResult
					position: absolute
					top: calc(100% + #{$paddingSmall})
					right: 0
					background: white
					z-index: 5
					width: 100%
					@extend .shadowRegular
					@extend .scrollbarRegular
					max-height: 280px
					overflow-y: auto
					&__item
						color: $colorTextMain
						padding: $paddingMedium
						border-bottom: 1px solid $grey-300
						font-size: $fontSizeStandard1
						//margin: $paddingMedium $paddingMedium 0 $paddingMedium
						cursor: pointer
						display: flex
						align-items: center
						&:last-of-type
							margin-bottom: $paddingMedium
							border: 0
						&:hover
							color: $colorLink
							span
								color: $colorLink
							svg
								fill: $colorLink
						span
							display: flex
							align-items: center
							font-weight: 400
							&.small
								svg
									width: 12px
									height: 12px
									margin: 0 $paddingSmall 0 0
						span.text
							white-space: nowrap
							overflow: hidden
							text-overflow: ellipsis
							flex-grow: 1
						svg
							width: 14px
							height: 14px
							margin: 0 0 0 $paddingMedium
							fill: $colorTextMain
		&__content
			display: flex
			justify-content: space-between
			align-items: flex-start
			margin-top: $paddingSmall
			&__chips
				flex-grow: 1
				display: flex
				flex-wrap: wrap
				gap: $paddingSmall
				&__item
					@extend .chip
			button
				margin: 0 0 0 $paddingMedium
