.collapsibleWrapper
  white-space: normal
  width: 100%
  &__collapsible
    //overflow: hidden
    &__header
      border-bottom: 1px solid $grey-300
      padding: $paddingSmall $paddingMedium
      display: flex
      justify-content: space-between
      align-items: center
      cursor: pointer
      color: $colorTextDark
      &:hover
        background: transparent
        border-color: $colorLink
        color: $colorLink
        svg
          fill: $colorLink
      &__left
        font-weight: 600
        font-size: $fontSizeStandard1
      &__right
        display: flex
        justify-content: flex-end
        svg
          width: 24px
          height: 24px
    &__content
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__paddingHolder
        padding: $paddingMedium $paddingBig
