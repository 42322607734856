.previewWrapper
  position: relative
  background: white
  height: 100%
  &__preview
    height: 100%
    display: flex
    align-items: center
    justify-content: flex-start
    &__left
      width: 0
      &__iconHolder
        background: $colorLink
        border: 1px solid $colorLink
        display: flex
        justify-content: center
        align-items: center
        padding-right: 4px
        border-radius: 4px 0 0 4px
        position: relative
        right: 26px
        width: 30px
        height: 46px
        cursor: pointer
        color: white
        &:hover
          background: transparent
          color: $colorLink
          svg
            fill: $colorLink
        svg
          width: 24px
          height: 24px
    &__right
      width: $widthPreviewFilterWindow
      max-width: calc(100vw - #{$widthMinimizedNavigation})
      height: 100vh
      overflow: hidden
      @extend .widgetWidthAnimation
      &.minimize
        @extend .widgetWidthAnimationMinimized
      &__header
        height: 131px
        background: $grey-50
        @extend .shadowSoft
        &__top
          margin: 0 $paddingBig
          padding: $paddingBig 0
          border-bottom: 1px solid $grey-300
          display: flex
          justify-content: space-between
          &__left
            flex-grow: 1
          &__right
            width: 20px
            margin-left: $paddingMedium
            svg
              width: 20px
              height: 20px
              cursor: pointer
              &:hover
                fill: $colorLink
        &__bottom
          padding: $paddingMedium ($paddingBig + $paddingSmall)
          border-bottom: 1px solid $grey-300
          display: flex
          align-items: center
          svg
            cursor: pointer
            width: 16px
            height: 16px
            fill: $colorLink
            margin-left: $paddingBig
            &:first-child
              margin-left: 0
            &:hover
              fill: $colorLinkHover
          svg.active
            fill: $colorLinkHover  !important
      &__content
        overflow-y: auto
        overflow-x: hidden
        @extend .scrollbarRegular
        padding-right: 0
        height: calc(100% - 131px)
        scroll-behavior: smooth
        position: relative
        top: -1px

.previewSectionWrapper
  margin-top: $paddingBig
  &.subSection
    margin: 0 !important
    padding: $paddingMedium $paddingMedium 0 $paddingMedium
    .previewSectionWrapper
      &__previewSection
        &__title
          font-weight: 600
          font-size: $fontSizeStandard
          padding: 0
          border: 0
          //color: $colorTextLight
        &__content
          padding: 0
          .previewSectionRow
            padding-right: 0
            padding-left: 0
  &:first-of-type
    margin-top: 0
  &:last-child
    margin-bottom: 1500px // Dynamically adjusted in preview_sections.js
  &__previewSection
    &__title
      font-size: $fontSizeStandard2
      padding: $paddingMedium
      border-bottom: 1px solid $grey-300
      margin: 0
      font-weight: 400
    &__content
      padding: $paddingSmall 0

.previewSectionRow
  display: flex
  padding: 4px $paddingMedium
  &__left
    min-width: 38%
    width: 38%
    overflow: hidden
    text-overflow: ellipsis
    color: $colorTextLight-1
  &__right
    flex-grow: 1
    margin-left: $paddingMedium
    word-break: break-word
    span.marginLeft
      margin-left: $paddingSmall
    div
      &.level__0
        margin-left: 0
      &.level__1
        margin-left: $paddingSmall * 2
      //color: $colorTextLight-1
      &.level__2
        margin-left: ($paddingSmall * 4)
      //color: $colorTextLight-1
      &.level__3
        margin-left: ($paddingSmall * 6)
      //color: $colorTextLight-1
      &.level__4
        margin-left: ($paddingSmall * 8)
        //color: $colorTextLight-1


