// Note that some of these classes is used in component to dynamically adjust style.
.settingsWrapper
  height: 100%
  min-width: 300px // Give loading component some space
  &__settings
    height: 100%
    background: white
    position: relative
    &__header
      &__close
        position: absolute
        bottom: 14px
        right: 14px
        border-radius: 22px
        padding: 1px
        z-index: 2
        @extend .shadowSmallDark
        background: white
        &__btn
          @extend .buttonRegular
          &:hover
            background: $colorLink
            color: white
            svg
              fill: white
    &__content
      display: flex
      align-items: stretch
      height: 100%
      &__left
        background: $grey-100
        border-right: 1px solid $grey-300
        overflow-y: auto
        overflow-x: hidden
        @extend .scrollbarSmallLight
        padding-right: 0
        flex-shrink: 0
        padding-bottom: calc(#{$paddingBig} * 2)
        &__item
          &__row
            white-space: nowrap
            display: flex
            align-items: center
            justify-content: flex-start
            cursor: pointer
            color: $colorTextDark
            &.main
              padding: $paddingMedium $paddingBig
              gap: $paddingMedium
              border-bottom: 1px solid $grey-300
              &:hover
                color: $colorLink
                span
                  color: $colorLink
              svg
                width: 18px
                height: 18px
              span
                font-weight: 600
                font-size: $fontSizeStandard2
                color: $colorTextDark
            &.sub
              padding: $paddingSmall $paddingBig $paddingSmall calc(15px + #{$paddingMedium} + #{$paddingBig})
              font-size: $fontSizeStandard-1
              border-left: 2px solid transparent
              color: $colorTextDark
              &:last-child
                margin-bottom: $paddingBig
              &:hover
                border-color: $colorLink
                background: $grey-200
              &.settingsLeftItemSubActive
                border-color: $colorLink
                background: $grey-200
              &.settingsLeftItemSubHidden
                display: none
      &__right
        flex-grow: 1
        padding-right: 0
        &__page
          height: 100%
          &.settingsRightInactivePage
            display: none
          &__header
            display: none // Hide
            padding: $paddingMedium $paddingBig
            font-weight: 600
            font-size: $fontSizeStandard2
            border-bottom: 1px solid $grey-300
            background: $grey-100
          &__content
            height: 100%
            overflow-y: auto
            overflow-x: hidden
            @extend .scrollbarRegular
            scroll-behavior: smooth

.settingsPageWrapper
  &__settingsPage
    &__section
      #contactUs > &__content
        font-size: 17px
      svg
        cursor: pointer
        width: 14px
        height: 14px
        fill: $colorLink
        &:hover
          fill: $colorLinkHover
      .tooltipWrapper__tooltip__indicator
        display: flex
        align-items: center
        justify-content: center
      &__header
        padding: $paddingMedium $paddingBig
        font-weight: 600
        font-size: $fontSizeStandard2
        border-bottom: 1px solid $grey-300
      &__content
        padding: $paddingBig
        margin-bottom: $paddingMedium
        &__admin
          &__colleaguesHolder
            max-width: 400px

        p
          margin-top: $paddingMedium
          &:first-child
            margin-top: 0
        .linkInText
          margin-left: 4px
        .topMargin
          margin-top: $paddingBig
        .infoWrapper
          margin: 0
          width: 100%
        div.linkWithIcon
          @extend .buttonLinkStyledWithIcon
          min-height: 30px // Avoid "skipping"
        div.userImageBtn
          cursor: pointer
          position: relative
          top: 86px
          left: -24px
          background: $colorLink
          border: 1px solid white
          border-radius: 50%
          display: flex
          align-items: center
          justify-content: center
          width: $heightSmallRoundIcon
          min-width: $heightSmallRoundIcon
          height: $heightSmallRoundIcon
          &:hover
            background: white
            border-color: $colorLink
            svg
              fill: $colorLink
          svg
            fill: white
            width: $heightSmallRoundIconSvg
            height: $heightSmallRoundIconSvg
        div.userInfoRow
          display: flex
          align-items: flex-start
          margin-top: $paddingSmall
          gap: $paddingMedium
          &:first-child
            margin-top: 0
          &.logout
            margin-top: $paddingBig
          &__left
            min-width: 86px
            width: 86px
            color: $colorTextLight
            font-size: $fontSizeStandard1
            text-align: right
            white-space: nowrap
          &__right
            flex-grow: 1
            color: $colorTextMain
            font-size: $fontSizeStandard1
            display: flex
            span
              flex-grow: 1
              color: $colorTextMain
              font-size: $fontSizeStandard1
            svg
              margin-left: $paddingMedium
              position: relative
              top: 2px
              cursor: pointer
            form
              flex-grow: 1
              input
                width: 100%
                max-width: 50%
                padding: 2px $paddingSmall
            .hidden
              visibility: hidden
        &__pdfArchiveWrapper
          &__pdfArchive
            &__header
              margin-bottom: $paddingBig
            &__content
              &__info
                margin-bottom: calc(#{$paddingBig} * 2)
                display: flex
                align-items: flex-start
                flex-wrap: wrap
                p
                  margin-top: $paddingMedium
                  &:first-child
                    margin-top: 0
                &__left
                  width: calc(50% - (#{$paddingBig} / 2))
                  margin-right: calc(#{$paddingBig} / 2)
                  a
                    margin-left: $paddingSmall
                &__right
                  width: calc(50% - (#{$paddingBig} / 2))
                  margin-left: calc(#{$paddingBig} / 2)
        &__holder
          display: block
          &.marginBottom
            margin-bottom: $paddingBig
          &__flexHolder
            display: flex
            align-items: center
            gap: $paddingBig
            flex-wrap: wrap
        &__flexHolder
          display: flex
          justify-content: flex-start
          flex-wrap: wrap
          gap: $paddingBig
          &__half
            width: calc(50% - (#{$paddingBig} / 2))
            margin-bottom: $paddingMedium
            &.noBottomMargin
              margin-bottom: 0
          &__third
            width: calc(33.33% - (#{$paddingBig} / 2))
            margin-bottom: $paddingMedium
            &.userImageHolder
              position: relative
              top: 2px
              display: flex
              justify-content: center
              align-items: flex-start
          &__twoThirds
            width: calc(66.66% - (#{$paddingBig} / 2))
            margin-bottom: $paddingMedium
.responsibleSellerWrapper
  padding-bottom: $paddingMedium
  img
    max-width: 160px
    clip-path: circle()
  p
    marginTop: 0
    span
      font-weight: 600
