.activityPipelineWrapper
  white-space: normal
  height: 100%
  &.activityPipelineResponsiveWidth1
    .activityPipelineWrapper
      &__activityPipeline
        &__content
          &__top
            &__statuses
              flex-direction: column
              &__item
                width: 100%
  &__activityPipeline
    height: 100%
    display: flex
    flex-direction: column
    &__content
      flex-grow: 1
      margin-top: $paddingBig
      display: flex
      flex-direction: column
      align-content: space-between
      overflow: auto
      @extend .scrollbarRegular
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__top
        flex-grow: 1
        &__title
          cursor: pointer
          display: flex
          align-items: center
          color: $colorTextMain
          margin-bottom: $paddingMedium
          &:hover
            color: $colorLink
            span
              color: $colorLink
          span
            color: $colorTextMain
            overflow: hidden
            text-overflow: ellipsis
            display: block
            white-space: nowrap
          svg
            width: 14px
            height: 14px
            fill: $colorLink
            margin-left: 4px
        &__statuses
          display: flex
          flex-wrap: wrap
          gap: $paddingBig
          &__item
            width: calc(50% - (#{$paddingBig} / 2))
            cursor: pointer
            &:nth-child(1)
              .activityPipelineWrapper__activityPipeline__content__statuses__item__barHolder__bar__dynamic
                background: $colorLink
            &:nth-child(2)
              .activityPipelineWrapper__activityPipeline__content__statuses__item__barHolder__bar__dynamic
                background: $colorLink
            &:nth-child(3)
              .activityPipelineWrapper__activityPipeline__content__statuses__item__barHolder__bar__dynamic
                background: $colorLink
            &:nth-child(4)
              .activityPipelineWrapper__activityPipeline__content__statuses__item__barHolder__bar__dynamic
                background: $colorLink
            &:hover
              .activityPipelineWrapper__activityPipeline__content__statuses__item__name
                color: $colorLink
            &__name
              font-size: $fontSizeStandard4
              line-height: $fontSizeStandard5
              color: $colorTextMain
              font-weight: 300
              display: flex
              align-items: center
              svg
                width: 16px
                height: 16px
                fill: $colorLink
                margin-left: 4px
                position: relative
                top: 1px
            &__values
              display: flex
              margin-top: $paddingMedium
              &__amount
                font-size: $fontSizeStandard5
                font-weight: 600
                margin-right: $paddingSmall
              &__percentage
                color: $colorTextLight-1
                font-size: $fontSizeStandard1
                position: relative
                top: 2px
            &__barHolder
              height: 6px
              background: $grey-100
              margin-top: $paddingSmall
              &__bar
                -webkit-animation: fullWidthLeft 2s
                -moz-animation: fullWidthLeft 2s
                -o-animation: fullWidthLeft 2s
                animation: fullWidthLeft 2s
                &__dynamic
                  height: 6px
                  background: $colorLink
      &__bottom
        margin-top: $paddingBig
