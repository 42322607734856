.card {
  width: clamp(18vw, 200px, 22vw);
  height: 100px;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(70, 110, 211, 0.2) inset;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  gap: 14px;
  font-size: 28px;
  position: relative;
}
.cardBottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 100px;
  flex: 1;
}
.card .number {
  font-size: 28px;
  text-align: end;
}
.clickable {
  cursor: pointer;
  &:hover {
    background-color: var(--grey-200);
  }
}
.link {
  color: var(--colorTextMain);
}
.linkIcon {
  position: absolute;
  right: 3px;
  top: 3px;
  height: 18px;
  width: 18px;
  svg {
    fill: black;
  }
}

.positiveChange {
  color: green;
}
.negativeChange {
  color: red;
}
.change {
  font-size: calc(var(--changeFontSize) - 4px);
  .changeIcon {
    margin-left: 4px;
    font-size: calc(var(--changeFontSize) - 6px);
  }
}

@media (max-width: 900px) {
  .cardBottom {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
@media (max-width: 800px) {
  .card {
    font-size: 22px;
    width: 200px;
  }
  .card .number {
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .card {
    padding: 8px;
    height: 80px;
  }
}
