.adjustReactQuill
  .ql-editor
    padding: 0
  .ql-hidden
    display: none
  h1
    font-size: $fontSizeStandard3
    font-weight: 600
    line-height: $fontSizeStandard4
    text-transform: none
  h2
    font-size: $fontSizeStandard1
    font-weight: 600
    line-height: $fontSizeStandard4
    text-transform: none
  h3
    font-size: $fontSizeStandard
    font-weight: 600
    line-height: $fontSizeStandard4
    text-transform: none
  span, ol, ul, p, a
    font-size: $fontSizeStandard3
    line-height: $fontSizeStandard6
  p
    margin: 0
  ul
    li
      margin-top: 4px
      &:first-child
        margin-top: 0
  img
    max-width: 100%
  ul
    li
      margin-top: $paddingSmall
      &:first-child
        margin-top: 0

.buttonUnstyled
  border: none,
  background-color: transparent

.buttonCommonStyles
  letter-spacing: 0.1px
  font-size: $fontSizeStandard-1
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  padding: 5px 16px
  white-space: nowrap
  border-radius: 22px
  font-weight: 600
  p, span
    font-weight: 600
    font-size: $fontSizeStandard-1
  svg
    width: 14px
    height: 14px
    margin-right: 8px
  &.disabled
    background: $grey-50 !important
    border-color: $grey-50 !important
    color: $colorTextLight-2 !important
    pointer-events: none !important
    svg
      fill: $colorTextLight-2 !important

.buttonRegular
  @extend .buttonCommonStyles
  background: darken($grey-100, 2%)
  border: 1px solid darken($grey-100, 2%)
  color: $colorTextMain
  &:hover
    background: transparent
    border-color: $colorLink
    color: $colorLink
    svg
      fill: $colorLink
  &.active
    background: transparent
    border-color: $colorLinkHover !important
    color: $colorLinkHover !important
    &:hover
      border-color: $colorLink !important
      color: $colorLink !important
      svg
        fill: $colorLink !important
    svg
      fill: $colorLinkHover !important
  svg
    fill: $colorTextMain

.buttonHighlight
  @extend .buttonCommonStyles
  background: $colorLink
  border: 1px solid $colorLink
  color: white
  &:hover
    background: transparent
    border-color: $colorLink
    color: $colorLink
    svg
      fill: $colorLink
  &.active
    background: transparent
    border-color: $colorLinkHover
    color: $colorLinkHover
    &:hover
      border-color: $colorLink
      color: $colorLink
      svg
        fill: $colorLink
    svg
      fill: $colorLinkHover

.buttonInfo
  @extend .buttonRegular
  border-radius: 4px

.buttonInfoRight
  @extend .buttonInfo
  svg
    margin: 0 0 0 4px

.buttonInfoLeft
  @extend .buttonInfo
  svg
    margin: 0 4px 0 0

.buttonLinkStyledWithIcon
  display: inline-flex
  align-items: center
  cursor: pointer
  gap: $paddingSmall
  color: $colorLink
  &:hover
    color: $colorLinkHover
    span
      color: $colorLinkHover
    svg
      fill: $colorLinkHover
  span
    color: $colorLink
  svg
    fill: $colorLink
    width: 16px
    height: 16px

.capitalize
  text-transform: capitalize

.chip
  background: $grey-100
  border: 1px solid $grey-200
  color: $colorTextMain
  padding: calc(#{$paddingSmall} / 2) $paddingSmall
  display: flex
  align-items: center
  font-size: $fontSizeStandard-2
  svg
    width: 14px
    height: 14px
    fill: $colorLink
    margin-left: $paddingSmall
    cursor: pointer
    &:hover
      fill: $colorLinkHover

.flexHolder
  display: flex
  justify-content: flex-start
  align-items: center
  flex-wrap: wrap
  gap: $paddingMedium

.formStyled
  background: $grey-100
  padding-top: $paddingBig
  margin-top: $paddingBig
  display: flex
  flex-wrap: wrap
  &__row
    margin: 0 $paddingBig $paddingBig $paddingBig
    width: 100%
    &:last-of-type
      margin-bottom: 0
    &.half
      width: calc(50% - (#{$paddingBig} * 1.5))
      margin: 0 $paddingBig $paddingBig calc(#{$paddingBig} / 2)
      &:nth-child(odd)
        margin: 0 calc(#{$paddingBig} / 2) $paddingBig $paddingBig
    &.noPadding
      padding: 0
    &.fillBackground
      background: white
      padding: $paddingSmall $paddingMedium
    &.noMarginBottom
      margin-bottom: 0
      &:nth-child(odd)
        margin-bottom: 0
    &.extraPadding
      padding: $paddingBig
    &.hint
      display: inline-flex
      align-items: center
      font-size: $fontSizeStandard-1
      font-weight: 600
      background: $colorFail
      color: white
      padding: $paddingSmall $paddingMedium
      width: auto
      svg
        width: 14px
        height: 14px
        fill: white
        margin-right: $paddingSmall
    &.buttons
      display: flex
      margin: 0
      & > div
        text-align: center
        width: 50%
        padding: calc(#{$paddingMedium} * 1.3) $paddingMedium
        background: $colorLinkSecondary
        color: white
        border: 1px solid $colorLinkSecondary
        cursor: pointer
        font-weight: 600
        font-size: $fontSizeStandard1
        &:hover
          background: transparent !important
          border-color: $colorLink
          color: $colorLink
        &.full
          width: 100%
        &.saveButton
          background: $colorLink
          border-color: $colorLink
        &.disabled
          background: darken($grey-100, 2%)
          border-color: darken($grey-100, 2%)
          color: $grey-400
          pointer-events: none
    textarea
      width: 100%
      max-width: 100%
      height: 92px
      background: white
      border: 1px solid $grey-200
      &:focus, &:hover
        border-color: $colorLink
    input
      background: white
      border: 1px solid
      border-color: transparent
      padding: $paddingMedium
      &:-webkit-autofill
        -webkit-box-shadow: 0 0 0 30px white inset !important
    .label
      color: $colorTextMain
      font-weight: 600
      font-size: $fontSizeStandard1
      margin: 0 0 $paddingSmall 0
    .file
      display: flex
      align-items: center
      font-weight: 600
      margin-top: $paddingSmall
      margin-left: $paddingSmall
    .answer
      display: flex
      flex-wrap: wrap
      margin-left: 4px
      min-height: 38px
      &__option
        display: flex
        align-items: center
        margin-right: calc(#{$paddingBig} * 2)
        cursor: pointer
        color: $colorTextLight
        &:last-of-type
          margin-right: 0
        &:hover
          color: $colorLinkHover
          svg
            fill: $colorLinkHover
        &.active
          svg
            fill: $colorLink
        svg
          width: 38px
          height: 38px
          margin-left: $paddingSmall
          fill: $grey-300
          &.radio
            width: 24px
            height: 24px
    .additionalText
      margin-top: $paddingSmall
      input
        margin-top: 4px
      &__link
        margin-left: -12px
        @extend .buttonLinkStyledWithIcon
    #pastedImage
      margin: $paddingBig 0

.genericFlexboxParent
  display: flex
  justify-content: space-between
  &__row
    flex-flow: row wrap
.genericFlexboxChild__button
  cursor: pointer
  &__active
    background: $grey-100
    font-weight: bold
    &:hover
      outline: 1px solid $grey-100
  &:hover
    outline: 1px solid $grey-300
    // background: $grey-100

.genericPopupContentWrapper
  &__genericPopupContent
    &__content
      padding: $paddingBig 0
      &.noPadding
        padding: 0
      p
        margin-top: $paddingMedium
        &:first-child
          margin-top: 0
        input
          width: 48%
          margin-left: $paddingMedium
        .marginLeft
          margin-left: $paddingSmall
        &.linkHolder
          display: flex
          align-items: center
          color: $colorLink
          cursor: pointer
          &:hover
            color: $colorLinkHover
          svg
            width: 18px
            height: 18px
            cursor: pointer
            margin-right: $paddingSmall
      .marginTop
        margin-top: $paddingBig
      &__section
        margin-top: $paddingBig
        &:first-child
          margin-top: 0
      &__flexHolder
        display: flex
        gap: $paddingMedium
        &.centered
          align-items: center
        &__right
          flex-grow: 1

.hidden
  display: none

.inputHolder
  margin-top: $paddingBig
  display: flex
  align-items: center
  gap: $paddingBig
  height: 45px
  width: 100%
  &.noMargin
    margin: 0
  &__left
    display: flex
    align-items: center
    justify-content: flex-start
    gap: $paddingMedium
    flex-grow: 1
    max-width: 50%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    strong
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  &__right
    display: flex
    align-items: center
    justify-content: center
    gap: $paddingMedium

.lineThrough
  display: inline-block
  position: relative
  &:before
    content: ''
    border-bottom: 1px solid $grey-700
    width: 100%
    position: absolute
    right: 0
    top: 50%

.marginTopBig
  margin-top: $paddingBig

.marginBottomBig
  margin-bottom: $paddingBig

.marginBottomMedium
  margin-bottom: $paddingMedium

.marginTopMedium
  margin-top: $paddingMedium

.newsTypeTag
  font-weight: 600
  font-size: $fontSizeStandard-3
  line-height: $fontSizeStandard-2
  border: 1px solid $colorLink
  color: $colorLink
  padding: 4px 7px
  text-transform: uppercase
  letter-spacing: 0.8px
  display: inline-block
  margin-top: 2px
  border-radius: 2px

.noShadow
  -webkit-box-shadow: unset
  -moz-box-shadow: unset
  box-shadow: unset

.paperSheet
  background: white
  border: 1px solid $grey-300

.scrollbarHidden
  scrollbar-width: none // FF
  &::-webkit-scrollbar // Chrome, Safari below
    width: 0
    height: 0

.scrollbarBlue
  padding-right: 4px
  scrollbar-width: thin
  scrollbar-color: $colorHeaderBackground transparent
  &::-webkit-scrollbar
    width: 3px
    height: 3px
  &::-webkit-scrollbar-track
    background: transparent
  &::-webkit-scrollbar-thumb
    background: $colorHeaderBackground
    border-radius: 2px

//.scrollbarSmallDark
//  padding-right: 4px
//  scrollbar-width: thin // FF
//  scrollbar-color: $grey-400 transparent // FF, different color from Chrome because scrollbar is thicker.
//  &::-webkit-scrollbar // Chrome, Safari below
//    width: 2px
//    height: 2px
//  &::-webkit-scrollbar-track
//    background: transparent
//  &::-webkit-scrollbar-thumb
//    background: $grey-700
//    border-radius: 2px

.scrollbarSmallLight
  padding-right: 4px
  scrollbar-width: thin // FF
  scrollbar-color: $grey-200 transparent // FF, different color from Chrome because scrollbar is thicker.
  &::-webkit-scrollbar // Chrome, Safari below
    width: 1px
    height: 1px
  &::-webkit-scrollbar-track
    background: transparent
  &::-webkit-scrollbar-thumb
    background: $grey-300
    border-radius: 2px

.scrollbarRegular
  padding-right: 4px
  scrollbar-width: thin
  scrollbar-color: $grey-400 transparent
  &::-webkit-scrollbar
    width: 7px
    height: 7px
  &::-webkit-scrollbar-track
    background: transparent
  &::-webkit-scrollbar-thumb
    background: $grey-400
    border-radius: 6px

.shadowMedium
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.4)
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.4)
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4)

.shadowPreview
  -webkit-box-shadow: -1px 1px 3px rgba(158, 158, 173, 0.3)
  -moz-box-shadow: -1px 1px 3px rgba(158, 158, 173, 0.3)
  box-shadow: -1px 1px 3px rgba(158, 158, 173, 0.3)

.shadowRegular
  -webkit-box-shadow: 1px 3px 6px rgba(158, 158, 173, 0.3)
  -moz-box-shadow: 1px 3px 6px rgba(158, 158, 173, 0.3)
  box-shadow: 1px 3px 6px rgba(158, 158, 173, 0.3)

.shadowRightInsetYellow
  -webkit-box-shadow: inset -3px 0 4px rgba(255, 153, 0, 0.5)
  -moz-box-shadow: inset -3px 0 4px rgba(255, 153, 0, 0.5)
  box-shadow: inset -3px 0 4px rgba(255, 153, 0, 0.5)

.shadowSmall
  -webkit-box-shadow: 0 1px 2px rgba(158, 158, 173, 0.4)
  -moz-box-shadow: 0 1px 2px rgba(158, 158, 173, 0.4)
  box-shadow: 0 1px 2px rgba(158, 158, 173, 0.4)

.shadowSmallDark
  -webkit-box-shadow: 0 1px 3px rgba(158, 158, 173, 0.6)
  -moz-box-shadow: 0 1px 3px rgba(158, 158, 173, 0.6)
  box-shadow: 0 1px 3px rgba(158, 158, 173, 0.6)

.shadowSmallBottom
  -webkit-box-shadow: 0 3px 2px rgba(158, 158, 173, 0.2)
  -moz-box-shadow: 0 3px 2px rgba(158, 158, 173, 0.2)
  box-shadow: 0 3px 2px rgba(158, 158, 173, 0.2)

.shadowSoft
  -webkit-box-shadow: 0 1px 2px rgba(158, 158, 173, 0.5)
  -moz-box-shadow: 0 1px 2px rgba(158, 158, 173, 0.5)
  box-shadow: 0 1px 2px rgba(158, 158, 173, 0.5)

.shadowRegularSoft
  -webkit-box-shadow: 0 0 5px rgba(158, 158, 173, 0.3)
  -moz-box-shadow: 0 0 5px rgba(158, 158, 173, 0.3)
  box-shadow: 0 0 5px rgba(158, 158, 173, 0.3)

.shadowGreyRed
  -webkit-box-shadow: 0 3px 5px rgba(230, 223, 223, 1)
  -moz-box-shadow: 0 3px 5px rgba(230, 223, 223, 1)
  box-shadow: 0 3px 5px rgba(230, 223, 223, 1)

.toggleOptionsHolder
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: $paddingSmall $paddingBig

  &__item
    display: flex
    align-items: center
    width: calc(50% - ($paddingBig / 2))
    gap: $paddingMedium
    padding: 0 $paddingSmall
    border-bottom: 1px solid $grey-100
    &:hover
      border-bottom: 1px solid $grey-400
    &:nth-child(even)
      margin-right: 0
    .iconHolder
      display: flex
      align-items: center
    &__left
      width: 18px
      min-width: 18px
      display: flex
      align-items: center
      justify-content: center
    &__right
      flex-grow: 1

.inputOptionsHolder
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: $paddingSmall $paddingBig

  &__item
    display: flex
    align-items: center
    justify-content: space-between
    width: calc(50% - ($paddingBig / 2))
    gap: $paddingMedium
    padding-left: $paddingSmall
    padding-bottom: $paddingSmall
    border-bottom: 1px solid $grey-100
    .iconHolder
      display: flex
      align-items: center
    &__left
      display: flex
      align-items: center
      justify-content: center
      font-weight: bold
    &__right
      flex-grow: 1
      max-width: 20%

.cancelDrag
  &:hover, &:active
    cursor: auto
