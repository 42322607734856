.fleetWidgetWrapper
  overflow-y: hidden
  width: 100%
  &__fleetWidget
    &__content
      margin-top: $paddingMedium
      overflow: hidden
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__navigate
        margin: 0 0 $paddingMedium 0
        display: flex
        justify-content: flex-end
        p
          cursor: pointer
          display: flex
          justify-content: flex-start
          align-items: center
          color: $colorLink
          font-weight: 600
          font-size: $fontSizeStandard-1
          letter-spacing: 0.7px
          text-transform: uppercase
          &:hover
            color: $colorLinkHover
          svg
            width: 16px
            height: 16px
            margin-left: 2px
