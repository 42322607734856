@media (max-width: $breakpoint4)
	.tableWrapper
		&__table
			&__footer
				&__middle
					&__rowsPerPage
						&__label
							display: none

@media (max-width: $breakpoint6)
	.tableWrapper
		&__table
			&__content
				&__top
					&__right
						display: none

@media (max-width: $breakpoint7)
	.tableWrapper
		&__table
			&__footer
				&__left
					display: none

@media (max-width: $breakpoint11)
	.tableWrapper
		&__table
			&__footer
				justify-content: flex-end
				&__middle
					display: none
