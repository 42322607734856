$agile-button-border-normal: $colorPrimary-50
$agile-button-bg-normal: transparent
$agile-button-bg-active: $colorPrimary-100
$hover-border-width: 40%
$agile-button-active-font-size: 14px
.agile-tab-button
    position: relative
    border: 1px solid $agile-button-border-normal
    border-right: none
    border-bottom: none
    padding: 12px
    flex: 1
    background-color: $agile-button-bg-normal
    &:hover
        cursor: pointer
        font-size: $agile-button-active-font-size
        &:not(.active)::after
            content: ''
            width: $hover-border-width
            height: 2px
            background: $colorPrimary-600
            position: absolute
            bottom: 0px
            left: calc((100% - $hover-border-width) / 2) // centered
.agile-tab-button:last-child
    border-right: 1px solid $agile-button-border-normal

.agile-tab-button.active
    border-bottom: 2px solid $colorPrimary-600
    font-weight: bold
    font-size: $agile-button-active-font-size
    color: $colorPrimary-600
.agile-tab.active
    display: block
    display: flex
    flex-direction: column
.agile-tab
    display: none
.deal-name
    font-size: 24px
    font-weight: bold
    padding: 0 0 $paddingSmall
