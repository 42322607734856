@media (max-width: $breakpoint-1)
  .newsWrapper
    &__news
      &__content
        &__bottom
          &__left
            &__displayNewsItem
              &__img
                img
                  &.default
                    padding: $paddingBig calc(#{$paddingBig} * 5)
                    max-width: unset
          &__right
            &__thumbs
              &__items
                grid-template-rows: 220px 220px 220px
                max-height: unset
                &__item
                  grid-column-start: 1
                  grid-column-end: 3

@media (max-width: $breakpoint1)
  .newsWrapper
    &__news
      &__content
        &__bottom
          flex-wrap: wrap
          gap: $paddingBig
          &__left
            width: 100%
            &__displayNewsItem
              &__img
                img
                  &.default
                    padding: $paddingBig calc(#{$paddingBig} * 10)
                    max-width: unset
          &__right
            width: 100%
            margin-top: $paddingBig
            &__thumbs
              &__items
                grid-auto-columns: calc(33.33% - (#{$paddingSmall} * 2 / 3))
                grid-template-rows: 200px
                &__item
                  &:nth-child(1)
                    grid-column-start: 1
                    grid-column-end: 1
                  &:nth-child(2)
                    grid-column-start: 2
                    grid-column-end: 2
                  &:nth-child(3)
                    grid-column-start: 3
                    grid-column-end: 3
            &__archive
              &__items
                display: flex
                align-items: flex-start
                flex-wrap: wrap
                gap: $paddingMedium
                &__item
                  width: calc(50% - (#{$paddingMedium} / 2))
                  margin: 0
                  &__row
                    margin: 0
                    white-space: normal

@media (max-width: $breakpoint2)
  .newsWrapper
    &__news
      &__content
        &__bottom
          &__left
            &__displayNewsItem
              &__img
                img
                  &.default
                    padding: $paddingBig calc(#{$paddingBig} * 8)

@media (max-width: $breakpoint3)
  .newsWrapper
    &__news
      &__content
        &__bottom
          &__left
            &__displayNewsItem
              &__img
                img
                  &.default
                    padding: $paddingBig calc(#{$paddingBig} * 6)
          &__right
            &__thumbs
              &__items
                grid-auto-columns: calc(50% - (#{$paddingSmall} / 2))
                grid-template-rows: 280px 200px
                &__item
                  &:nth-child(1)
                    grid-column-start: 1
                    grid-column-end: 3
                  &:nth-child(2)
                    grid-column-start: 1
                    grid-column-end: 2
                  &:nth-child(3)
                    grid-column-start: 2
                    grid-column-end: 3

@media (max-width: $breakpoint4)
  .newsWrapper
    &__news
      &__content
        &__bottom
          &__left
            &__displayNewsItem
              &__img
                img
                  &.default
                    padding: $paddingBig calc(#{$paddingBig} * 4)

@media (max-width: $breakpoint7)
  .newsWrapper
    &__news
      &__content
        &__bottom
          &__left
            &__displayNewsItem
              &__img
                max-height: 360px
                img
                  &.default
                    padding: 0 calc(#{$paddingBig} * 2)
          &__right
            &__thumbs
              &__items
                grid-template-rows: 200px 200px 200px
                &__item
                  &:nth-child(1)
                    grid-column-start: 1
                    grid-column-end: 3
                  &:nth-child(2)
                    grid-column-start: 1
                    grid-column-end: 3
                  &:nth-child(3)
                    grid-column-start: 1
                    grid-column-end: 3
            &__archive
              &__items
                gap: $paddingMedium
                &__item
                  width: 100%

@media (max-width: $breakpoint9)
  .newsWrapper
    &__news
      &__content
        &__bottom
          margin: $paddingBig
          &__left
            &__displayNewsItem
              &__headline
                font-size: $fontSizeStandard9
                line-height: $fontSizeStandard10
              &__ingress
                font-size: $fontSizeStandard4
                line-height: $fontSizeStandard6
              &__author
                padding: $paddingBig 0
                &__name
                  font-size: $fontSizeStandard3
                  line-height: $fontSizeStandard4
                  span.email
                    font-size: $fontSizeStandard-1
                    line-height: $fontSizeStandard1

