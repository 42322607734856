// Used by ListsActive and ListsArchived
.listsSubcomponentWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__listsSubcomponent
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      &__bottom
        //background: white
        padding: $paddingMedium $paddingGrid 0 $paddingGrid
        flex-grow: 1
        display: flex
        flex-direction: column

.listsHandleListsWrapper
  &__listsHandleLists
    &__content
      margin: $paddingBig 0 0 0

// Used for most popups in lists pages.
.listsGenericWrapper
  &__listsGeneric
    &__content
      margin: $paddingBig 0
      input
        margin-left: $paddingSmall
        max-width: 40%
      p.noWrap
        white-space: nowrap
      &__listHolder
        display: flex
        margin: $paddingMedium 0
        &__left
          font-weight: 600
          white-space: nowrap
          margin-right: $paddingSmall
        &__right
          display: flex
          flex-wrap: wrap
      &__subscription
        &__info
          p
            margin-bottom: $paddingMedium

// SplitList component
.splitListWrapper
  &__splitList
    &__content
      margin: $paddingBig 0
      .label
        font-weight: 600
      &__info
        margin-bottom: $paddingBig
        p
          display: flex
          span.label
            margin-right: 4px
            font-weight: 600
      &__row
        display: flex
        align-items: center
        width: 100%
        margin-top: $paddingMedium
        &:first-of-type
          margin-top: 0
        input
          &:disabled
            color: $colorTextLight-2
            pointer-events: none
        svg
          fill: $grey-300 !important
          width: 14px
          height: 14px
          cursor: default
        &__size
          flex-basis: 10%
          margin-right: $paddingMedium
          display: flex
          justify-content: center
          min-width: 52px
          input
            text-align: center
        &__touched
          flex-basis: 10%
          margin-right: $paddingMedium
          display: flex
          justify-content: center
          min-width: 0
          svg.active
            fill: $colorLink !important
        &__name
          flex-grow: 1
          margin-right: $paddingMedium
          min-width: 0
        &__remove
          flex-basis: 10%
          display: flex
          justify-content: center
          min-width: 0
          svg
            pointer-events: none
            &.active
              pointer-events: unset
              cursor: pointer
              fill: $colorLink !important
              &:hover
                fill: $colorLinkHover !important
      &__addRow
        color: $colorLink
        margin-top: $paddingBig
        display: inline-block
        cursor: pointer
        &:hover
          color: $colorLinkHover
.contractCustomerListsWrapper
  background-color: #fff
  height: 100vh
  display: flex
  flex-direction: column
  &__tableWrapper
    display: flex
    flex-direction: column
    flex-grow: 1
    padding: $paddingBig
