.contactsWrapper
  width: 100%
  &__contacts
    &__content
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin-top: $paddingBig
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__contacts
        display: flex
        flex-wrap: wrap
        flex-grow: 1
        gap: $paddingMedium
        &.info
          padding: 0
          background: transparent
        &__item
          width: calc(50% - (#{$paddingMedium} / 2))
          word-break: break-word
          padding-bottom: $paddingMedium
          //border-bottom: 2px solid $grey-100
          background: white
          &:hover
            .contactsWrapper__contacts__content__contacts__item__header__edit
              visibility: visible
          input
            margin-bottom: 6px
            &:last-child
              margin-bottom: 0
          &__header
            display: flex
            justify-content: flex-start
            align-items: center
            padding: $paddingSmall
            background: $grey-100
            color: $colorTextMain
            margin-bottom: $paddingSmall
            &.sticky
              background: $colorInformatory
              .contactsWrapper__contacts__content__contacts__item__header__name
                color: white
            &.edit, &.create
              background: transparent
            &__icon
              margin-right: $paddingMedium
              display: flex
              align-items: center
              svg
                width: 28px
                height: 28px
                &.sticky
                  fill: white
            &__name
              flex-grow: 1
              display: flex
              align-items: center
              justify-content: space-between
              overflow: hidden
              text-overflow: ellipsis
              //text-transform: uppercase
              font-weight: 600
              font-size: $fontSizeStandard2
              line-height: $fontSizeStandard3
              letter-spacing: 0.2px
            &__edit
              visibility: hidden
              display: flex
              align-items: center
              margin-left: $paddingMedium
              svg
                width: 16px
                height: 16px
                cursor: pointer
                &:hover
                  fill: $colorLinkHover
          &__content
            padding: $paddingMedium
            &__row
              display: flex
              align-items: flex-start
              margin-bottom: $paddingSmall
              &:last-of-type
                margin-bottom: 0
              &__left
                margin: 0 $paddingMedium 0 0
                color: $colorTextMain
                white-space: nowrap
                //text-transform: uppercase
                //letter-spacing: 0.7px
                font-weight: 600
                font-size: $fontSizeStandard-1
                width: 84px
                min-width: 84px
                //text-align: right
                display: flex
                align-items: center
                justify-content: flex-end
              &__right
                flex-grow: 1
                &__entities
                  display: flex
                  align-items: flex-start
                  flex-wrap: wrap
                  span.label
                    margin-right: $paddingMedium
                    color: $colorTextLight
                  &__entity
                    display: flex
                    align-items: center
                    margin-right: $paddingMedium
                    &:last-of-type
                      margin-right: 0
                    svg
                      width: 18px
                      height: 18px
                      margin-left: $paddingMedium
                      cursor: pointer
                      fill: $colorLink
                      &:hover
                        fill: $colorLinkHover
                    .tooltipWrapper__tooltip__indicator
                      display: flex
                      align-items: center
                &__addField
                  display: flex
                  align-items: center
                  cursor: pointer
                  margin-top: 4px
                  &:hover
                    svg
                      fill: $colorLinkHover
                  svg
                    width: 18px
                    height: 18px
                    fill: $colorLink
              &__buttons
                display: flex
                flex-grow: 1
                justify-content: space-between
                margin-top: $paddingBig

      &__search
        width: calc(33% - #{$paddingBig})
        flex-grow: 0
        flex-shrink: 0
        min-width: 0
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-right: $paddingBig
        h4
          color: $colorLink
          margin-top: 1em
          cursor: pointer
        &__top
          display: flex
          justify-content: flex-start
          align-items: center
          width: 100%
          span
            color: $colorTextMain
            font-weight: 600
        &__bottom
          width: 100%
