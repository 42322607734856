.prospectPresetsWrapper
  &__prospectPresets
    &__content
      display: flex
      flex-wrap: wrap
      flex-shrink: 1
      gap: $paddingSmall
      width: 100%
      &__row
        display: block
        width: 100%
      &__preset
        width: calc(50% - (#{$paddingSmall} / 2))
