.activityTypesGroupsWrapper
  height: 100%
  overflow: auto
  @extend .scrollbarRegular
  &__activityTypesGroups
    &__content
      margin-top: $paddingBig
      padding: 0 0 $paddingMedium 0
      overflow: auto
      //@extend .scrollbarRegular
      //max-height: 600px
      &__groups
        display: flex
        gap: calc(#{$paddingBig} * 2)
        justify-content: flex-start
        align-items: stretch
        flex-wrap: wrap
        &.activityTypesGroupsResponsiveWidth1
          .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group
            width: calc(20% - ((#{$paddingBig} * 2) * 4 / 5))
        &.activityTypesGroupsResponsiveWidth2
          .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group
            width: calc(25% - ((#{$paddingBig} * 2) * 3 / 4))
        &.activityTypesGroupsResponsiveWidth3
          gap: $paddingBig
          .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group
            width: calc(33.33% - (#{$paddingBig} * 2 / 3))
            &__icon
              width: $iconSizeBig
              min-width: $iconSizeBig
              height: $iconSizeBig
              border-width: 1px
              svg
                width: $iconSizeBigSvg
                height: $iconSizeBigSvg
            &__activity
              flex-direction: row
              margin-top: $paddingMedium
              font-size: $fontSizeStandard1
              &__label
                white-space: nowrap
              &__value
                font-weight: 600
                margin-left: $paddingSmall
                white-space: nowrap
                span.extraText
                  display: none
            &__barHolder
              height: 2px
        &.activityTypesGroupsResponsiveWidth4
          padding: 0 $paddingMedium
          .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group
            width: calc(50% - #{$paddingBig})
            &__icon
              width: $iconSizeBig
              min-width: $iconSizeBig
              height: $iconSizeBig
              border-width: 1px
              svg
                width: $iconSizeBigSvg
                height: $iconSizeBigSvg
            &__activity
              flex-direction: row
              margin-top: $paddingMedium
              font-size: $fontSizeStandard1
              &__label
                white-space: nowrap
              &__value
                font-weight: 600
                margin-left: $paddingSmall
                white-space: nowrap
                span.extraText
                  display: none
            &__barHolder
              height: 2px
        &.activityTypesGroupsResponsiveWidth5
          padding: 0 $paddingMedium
          .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group
            width: 100%
            &__icon
              width: $iconSizeBig
              min-width: $iconSizeBig
              height: $iconSizeBig
              border-width: 1px
              svg
                width: $iconSizeBigSvg
                height: $iconSizeBigSvg
            &__activity
              flex-direction: row
              margin-top: $paddingMedium
              font-size: $fontSizeStandard1
              &__label
                white-space: nowrap
              &__value
                font-weight: 600
                margin-left: $paddingSmall
                white-space: nowrap
                span.extraText
                  display: none
            &__barHolder
              height: 2px
        &.activityTypesGroupsResponsiveWidth6
          padding: 0
          gap: $paddingMedium
          .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group
            width: 100%
            flex-direction: row
            align-items: center
            gap: $paddingMedium
            &__icon
              border-width: 1px
              width: $heightSmallRoundIcon
              min-width: $heightSmallRoundIcon
              height: $heightSmallRoundIcon
              svg
                width: $heightSmallRoundIconSvg
                height: $heightSmallRoundIconSvg
            &__activity
              flex-direction: row
              gap: $paddingSmall
              margin: 0
              font-size: $fontSizeStandard1
              word-break: break-all
              &__label
                white-space: nowrap
                position: relative
                &:after
                  content: ":"
                  position: absolute
                  right: -5px
                  top: 0
              &__value
                font-weight: 600
                white-space: nowrap
                margin-left: 4px
                span.extraText
                  display: none
            &__barHolder
              display: none
        &__group
          color: $colorInformatory
          width: calc(16.66% - ((#{$paddingBig} * 2) * 5 / 6))
          //min-width: 154px
          display: flex
          flex-direction: column
          align-items: center
          cursor: pointer
          &:hover
            color: $colorLink
            .bar
              background: $colorLink
          &.disabled
            pointer-events: none
            .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__activity
              color: $grey-400
            .activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__icon
              color: $grey-200
          &__icon
            border: 2px solid
            min-width: $iconSizeXL
            width: $iconSizeXL
            height: $iconSizeXL
            border-radius: 50%
            display: flex
            align-items: center
            justify-content: center
            svg
              width: $iconSizeXLSvg
              height: $iconSizeXLSvg
          &__activity
            margin-top: $paddingBig
            display: flex
            flex-direction: column
            flex-grow: 1
            align-items: center
            font-size: $fontSizeStandard2
            color: $colorTextMain
            &__label
              font-weight: 600
            &__value
              font-weight: 400
              text-align: center
              word-break: break-word
              span
                font-weight: 400
          &__barHolder
            width: 100%
            height: 3px
            background: $grey-100
            margin-top: $paddingMedium
            .bar
              background: $colorInformatory
              height: 100%
              max-width: 100%
              -webkit-animation: fullWidthLeft 2s
              -moz-animation: fullWidthLeft 2s
              -o-animation: fullWidthLeft 2s
              animation: fullWidthLeft 2s
