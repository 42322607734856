.pdfArchiveManageFilesWrapper
  width: 100%
  &__pdfArchiveManageFiles
    &__content
      &__upload
        margin-bottom: $paddingMedium
        &__label
          margin-right: $paddingMedium
        &__rowOne
          display: flex
          align-items: center
          &__file
            display: flex
            align-items: center
            flex-grow: 1
            &__custom
              margin-right: $paddingBig
              width: 100%
              max-width: 320px
              form
                width: 100%
              input
                width: 100%
                &#user-pdf
                  display: none
                &.hidden
                  display: none
              label
                @extend .buttonLinkStyledWithIcon
                &.hidden
                  display: none
        &__rowTwo
          display: flex
          align-items: center
          margin: $paddingBig 0
          &__originalName
            display: flex
            align-items: center
            span.fileName
              white-space: nowrap
              max-width: 200px
              overflow: hidden
              text-overflow: ellipsis
            .tooltipWrapper__tooltip__indicator
              display: flex
              align-items: center
            svg
              width: 14px
              height: 14px
              fill: $colorLink
              cursor: pointer
              margin-left: $paddingSmall
              &:hover
                fill: $colorLinkHover
      &__archive
        margin-bottom: $paddingBig
        &__items
          display: flex
          align-items: flex-start
          flex-wrap: wrap
          gap: $paddingSmall
          max-height: 286px
          overflow-y: auto
          @extend .scrollbarRegular
          &__item
            width: calc(50% - #{$paddingSmall})
            &.fullWidth
              width: 100%
              margin-right: 0
      &__preview
        margin: $paddingBig 0
