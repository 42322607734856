@media (max-width: $breakpoint4)
	.createDealWrapper
		&__createDeal
			&__content
				&__top
					flex-direction: column
					&__item
						&:nth-child(odd)
							margin-right: 0
						&:nth-child(even)
							margin-left: 0
				&__bottom
					flex-direction: column
					&__left
						width: 100%
						margin-right: 0
					&__right
						width: 100%
						margin-left: 0
