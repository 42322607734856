// Person component
.personWrapper
  &__person
    &__content
      background: white
      &__item
        border-bottom: 1px solid $grey-300
        padding: $paddingBig
        &:last-of-type
          border-bottom: 0

// PersonInfo component
.personInfoWrapper
  &__personInfo
    &__content
      padding: 0
      display: flex
      flex-wrap: wrap
      margin-top: $paddingBig
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__item
        margin: 2px 2px 0 0
        width: calc(25% - ((3 * 2px) / 4))
        padding: $paddingMedium
        word-break: break-word
        background: $grey-100
        &:nth-child(4n)
          margin-right: 0
        div.editable
          display: flex
          justify-content: space-between
          align-items: center
          .tooltipWrapper
            margin-left: $paddingSmall
        &__personInfoLabel
          font-weight: 600
          span
            font-weight: 600
        span.marginLeft
          margin-left: 3px
        .dropdownWrapper__dropdown__header
          svg
            fill: $colorTextMain
        .tooltipWrapper__tooltip__content
          display: block
          margin-top: 4px
        svg
          color: $colorLink
          cursor: pointer
          width: 14px
          height: 14px
          &:hover
            fill: $colorLinkHover
        &__dealsHolder
          display: flex
          align-items: center
          flex-wrap: wrap
          a
            font-size: $fontSizeStandard-1
          span.dealLink
            margin-right: 4px
            &:last-of-type
              margin-right: 0
        &__field
          display: flex
          align-items: center
          min-height: 24px
          a
            width: 100%
          p
            flex-grow: 1
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
          svg
            margin-left: $paddingMedium
        &__addField
          margin-top: 4px
          display: flex
          align-items: center
