@keyframes bounceDown
  0%, 40%
    transform: translateY(0)
  50%
    transform: translateY(2px)
  60%, 100%
    transform: translateY(0)


//@keyframes bounceRightSmall
//  0%
//    transform: translateX(0)
//  10%
//    transform: translateX(-3px)
//  90%
//    transform: translateX(-3px)
//  100%
//    transform: translateX(0)
//
//@keyframes bounceRight
//  0%
//    transform: translateX(0)
//  20%
//    transform: translateX(-6px)
//  80%
//    transform: translateX(-6px)
//  100%
//    transform: translateX(0)

@keyframes fullHeight
  0%
    transform-origin: top
    transform: scaleY(0)
  100%
    transform-origin: top
    transform: scaleY(1)

@keyframes fullOpacity
  0%
    opacity: 0
  50%
    opacity: 0
  100%
    opacity: 1

@keyframes fullWidthLeft
  0%
    transform-origin: left
    transform: scaleX(0)
    opacity: 0
  100%
    transform-origin: left
    transform: scaleX(1)
    opacity: 1

@keyframes fullWidthRight
  0%
    transform-origin: right
    transform: scaleX(0)
    opacity: 0
  100%
    transform-origin: right
    transform: scaleX(1)
    opacity: 1

@keyframes slideFromBottom
  0%
    bottom: -100%
  8%
    bottom: 3%
  92%
    bottom: 3%
  100%
    bottom: -100%

.dropdownAnimation
  height: auto
  transition: 0.2s ease-in-out

.dropdownAnimationMinimized
  max-height: 0 !important
  margin: 0 !important
  padding: 0 !important
  transition: 0.1s ease-in-out
  overflow: hidden
  opacity: 0

// Used for components where we toggle 'minimize' class.
.widgetHeightAnimation
  height: auto
  max-height: 8000px
  transition: all .5s ease
  opacity: 1

.widgetHeightAnimationMinimized
  max-height: 0 !important
  margin: 0 !important
  padding: 0 !important
  transition: 0.4s margin ease-in-out, 0.4s max-height ease-in-out, 0.4s padding ease-in-out, 0.8s opacity ease-in-out
  overflow: hidden
  opacity: 0

.widgetWidthAnimation
  transition: 0.2s ease-in-out, 0.3s opacity ease-in-out
  opacity: 1

.widgetWidthAnimationMinimized
  opacity: 0
  width: 0
  padding: 0 !important
  transition: 0.2s ease-in-out, 0.1s opacity ease-in-out
  overflow: hidden
