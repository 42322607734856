.tooltipWrapper__tooltip__indicator
    svg.alerts-warning
        fill: rgb(255, 109, 51)
        &:hover
            fill: rgb(255, 0, 0)
    svg.verified
        fill: hsl(149, 80%, 43%)
        &:hover
            fill: hsl(149, 100%, 33%)
    svg.bigger-svg
        height: 18px
        width: 18px
        &:hover
            cursor: auto
input.pdf-selector-mailings
    appearance: auto
    width: auto
    display: flex
    justify-self: start
    &:hover
        cursor: pointer
    &:disabled:hover
        cursor: not-allowed
input#upload-pdf
    width: auto
    border: none
    padding: 0
