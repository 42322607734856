.prospectSearchWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__prospectSearch
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__header
      &__count
        margin: $paddingBig $paddingBig * 1.4 0 $paddingBig * 1.4
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      padding: calc(#{$paddingBig} * 2) calc(#{$paddingBig} * 1.4) 0 calc(#{$paddingBig} * 1.4)
      min-width: 0 // Important for shrinking on small screens
      &__top
        .menuFloatFreeWrapper
          margin: $paddingBig * 1.3 0 $paddingBig 0
          &.responsive
            height: 0 // When Menu switches to responsive version, the better UX is to just hide it.
            overflow: hidden
            margin: 0 0 $paddingBig 0
      &__bottom // If this class name changes update prospect_search_sections.js querySelector.
        position: relative
        flex-grow: 1
        &__holder
          margin: 0 0 4px 0
          padding: 0 $paddingMedium 0 0
          @extend .scrollbarRegular
          position: absolute
          top: 0
          right: 0
          bottom: 0
          left: 0
          scroll-behavior: smooth
          overflow-y: auto
          overflow-x: hidden

.prospectSearchHeaderWrapper
  &__prospectSearchHeader
    height: 100%
    &__content
      display: flex
      justify-content: space-between
      align-items: center
      height: 100%
      &__count
        flex-shrink: 1
        display: flex
        align-items: center
        justify-content: flex-start
        flex-wrap: wrap
        &__item
          font-size: $fontSizeStandard4
          font-weight: 300
          margin-left: $paddingSmall
          display: flex
          align-items: center
          &:first-of-type
            margin-left: 0
          strong
            margin-right: $paddingSmall
          &__icon
            display: flex
            align-items: center
            justify-content: center
            border-radius: 50%
            border: 1px solid
            height: 30px
            width: 30px
            min-width: 30px
            margin-right: $paddingMedium
            svg
              width: 16px
              height: 16px
      &__buttons
        display: flex
        justify-content: flex-end
        align-items: center
        gap: $paddingBig
        min-width: 216px
        margin-left: $paddingBig * 4
        &__reset
          @extend .buttonHighlight
          background: transparent
          border-color: transparent
          color: $colorLink
          padding: 6px 16px
          text-transform: none
          span
            font-size: $fontSizeStandard2
            font-weight: 600
          svg
            fill: $colorLink
            width: $fontSizeStandard3
            height: $fontSizeStandard3
            margin-right: $paddingSmall
        &__search
          @extend .prospectSearchHeaderWrapper__prospectSearchHeader__content__buttons__reset
          background: $colorLink
          border-color: $colorLink
          color: white
          svg
            fill: white

.prospectSearchSectionWrapper // If this class name changes update prospect_search_sections.js querySelector.
  padding: calc(#{$paddingBig} * 2) 0
  //border-bottom: 2px solid $grey-200
  &:first-of-type
    padding-top: 0
  &:last-of-type
    //border: 0
    margin-bottom: 2000px // Dynamically adjusted in prospect_search_sections.js.
  &__prospectSearchSection
    //&__header
    //  //display: flex
    //  display: none // Hide for now, but we probably want to reinstate some sort of a section header..?
    //  justify-content: space-between
    //  align-items: center
    //  cursor: pointer
    //  &:hover
    //    .prospectSearchSectionWrapper__prospectSearchSection__header__left
    //      svg
    //        background: $colorLink
    //    .prospectSearchSectionWrapper__prospectSearchSection__header__centerBorder
    //      border-color: $colorLink
    //    .prospectSearchSectionWrapper__prospectSearchSection__header__right
    //      color: $colorLink
    //  &__left
    //    svg
    //      display: flex
    //      align-items: center
    //      justify-content: center
    //      padding: $paddingSmall
    //      fill: white
    //      border-radius: 50%
    //      cursor: default
    //      width: $iconSizeBig
    //      height: $iconSizeBig
    //      background: $colorTextMain
    //  &__centerBorder
    //    border-bottom: 1px solid $colorTextMain
    //    flex-grow: 1
    //    margin: 0 $paddingBig
    //  &__right
    //    display: flex
    //    align-items: center
    //    font-size: $fontSizeStandard4
    //    color: $colorTextMain
    //    font-weight: 600
    //    svg
    //      width: 22px
    //      height: 22px
    //      margin-left: 4px
    //      fill: $colorTextMain
    //.collapsibleWrapper__collapsible__header__left
    //  font-size: $fontSizeStandard4
    &__content
      //height: auto
      margin-top: $paddingBig
      //@extend .widgetHeightAnimation
      //&.minimize
      //  @extend .widgetHeightAnimationMinimized
      & > .prospectSearchSubSectionWrapper ~ .prospectSearchSubSectionWrapper
        margin-top: $paddingSmall // Sub sections within section has a margin-top of $paddingSmall, except first one...

.prospectSearchSubSectionWrapper
  margin: $paddingBig $paddingBig 0 $paddingBig // ...for first sub section within a section regular styling applies
  &__prospectSearchSubSection
    &__content
      margin: $paddingBig 0
      position: relative

.prospectSearchRowWrapper
  margin-top: $paddingBig * 1.1
  &:first-child
    margin-top: 0
  &__prospectSearchRow
    &__content
      display: flex
      justify-content: flex-start
      gap: $paddingMedium
      &__left
        width: $widthProspectLabel
        min-width: $widthProspectLabel
        font-size: $fontSizeStandard-1
        line-height: $fontSizeStandard2
        padding-top: 2px
        //margin: 4px $paddingBig 0 0
        display: flex
        //justify-content: flex-end
        //text-align: right
        flex-wrap: wrap
        &.empty
          //margin: 0 !important
          height: 0 !important
          min-height: 0 !important
        svg
          margin-left: $paddingSmall
          width: 18px
          height: 18px
          position: relative
          top: 1px
          fill: $colorInformatory
      &__right
        width: calc(100% - #{$widthProspectLabel} - #{$paddingBig})
        display: flex
        align-items: center
        flex-wrap: wrap
        position: relative
        &__searchHolder // Search
          display: flex
          flex-direction: row
          align-items: center
          //flex-wrap: wrap
          justify-content: flex-start
          width: 100%
          margin-top: -12px
          height: 48px
          gap: $paddingSmall
          &__left
            margin-left: -12px
            &__input
              width: 340px
              padding: $paddingSmall
              overflow: hidden
              input
                width: 100%
              &__smaller
                width: 340px
                align-items: flex-end
                input
                  width: 240px
                  width: 340px
                  padding: $paddingSmall
                  overflow: hidden
              input
                width: 100%
            &__inputActive
              width: 340px
              padding: $paddingSmall
              @extend .shadowRegular
              top: -12px
              left: calc(-#{$paddingMedium})
              position: absolute
              z-index: 2
              background: white
              input
                width: 100%
              &__selectRow
                font-weight: 600
                cursor: pointer
                display: flex
                align-items: center
                justify-content: space-between
                margin: $paddingBig 0 $paddingSmall 0
                padding: 0 $paddingMedium
                &__item
                  &:hover
                    color: $colorLink
              &__suggestionsHolder
                @extend .scrollbarRegular
                z-index: 2
                margin-top: $paddingBig
                //max-width: 320px
                min-width: 100%
                max-height: 220px
                overflow-y: auto
                &__itemWrapper
                  padding: $paddingMedium
                  border-bottom: 1px solid $grey-300
                  &:last-of-type
                    border-bottom: 0
                  &__item
                    cursor: pointer
                    display: flex
                    align-items: center
                    justify-content: space-between
                    &:first-of-type
                      margin-top: 0
                    &:hover
                      color: $colorLink
                    &__left
                      flex-grow: 1
                      white-space: nowrap
                      overflow: hidden
                      text-overflow: ellipsis
                      strong
                        font-weight: 600
                    &__right
                      margin-left: $paddingMedium
                      svg
                        width: 14px
                        height: 14px
          &__right
            display: flex
            align-items: center
            flex-wrap: wrap
            gap: $paddingSmall
            &.marginLeft
              margin-left: 340px
            &__button
              //margin: 6px 0 0 $paddingBig
              display: flex
              align-items: center
              cursor: pointer
              white-space: nowrap
              //&:last-of-type
              //  margin-left: 0
              &:hover
                color: $colorLink
        &__valueHolder // Hold multiple value objects (buttons)
          display: flex
          flex-wrap: wrap
        &__radioHolder // Hold radio buttons
          display: flex
          align-items: center
          margin-top: 4px
          margin-right: $paddingMedium
          cursor: pointer
          color: $colorTextMain
          &:last-of-type
            margin-right: 0
          &:hover
            color: $colorLink
            svg
              fill: $colorLink
          &.active
            color: $colorLinkHover
            svg
              fill: $colorLinkHover
            &:hover
              cursor: default
          svg
            fill: $colorTextLight
            width: 12px
            height: 12px
            margin-right: $paddingSmall

// Value object
.prospectValue
  @extend .prospectValueButton
  &:last-child
    margin-right: 0

// Range object
.prospectRangeWrapper
  width: 94%
  &__prospectRange
    &:hover
      .prospectRangeWrapper__prospectRange__content__left
        .MuiSlider-root
          color: $colorLink !important
        .MuiSlider-rail
          color: $grey-300
    &.active
      .prospectRangeWrapper__prospectRange__content__left
        .MuiSlider-root
          //color: lighten($colorSecondary-600, 4%)
          color: $colorLinkHover
        .MuiSlider-rail
          color: $grey-300
    &__content
      display: flex
      flex-wrap: wrap
      align-items: center
      gap: $paddingSmall
      &__left
        flex-grow: 1
        width: 100%
        .MuiSlider-root
          color: $grey-300
          margin-left: 8px
          width: calc(100% - 8px)
          padding: $paddingMedium 0
        .MuiSlider-rail
          height: 2px
        .MuiSlider-track
          height: 3px
      &__center
        //margin: -4px $paddingMedium 0 $paddingBig * 1.5
        display: flex
        justify-content: flex-start
        position: relative
        //top: 3px
        &.active
          input
            border-color: $colorLinkHover
        input
          text-align: center
          border: 1px solid $grey-300
          border-radius: 4px
          &:hover
            border-color: $colorLink
        &__left
          min-width: 66px
          max-width: 120px
          text-align: center
        &__center
          margin: 6px $paddingSmall 0 $paddingSmall
          justify-self: flex-end
        &__right
          min-width: 66px
          max-width: 120px
          text-align: center
      &__right
        white-space: nowrap
        //margin-top: -4px
        //width: 44px
        overflow: hidden
        display: flex
        align-items: center
        gap: $paddingSmall

      &__reset
        color: $colorLink
        display: flex
        align-items: center
        cursor: pointer
        width: 16px
        margin-top: -3px
        margin-left: $paddingSmall
        &:hover
          color: $colorLinkHover
        svg
          width: 16px
          height: 16px

.prospectValueButton
  @extend .buttonRegular
  margin: 0 $paddingSmall $paddingSmall 0
  border: 1px solid transparent
  &:last-child
    margin-right: 0
  &.active
    background: $colorLinkHover !important
    color: white !important
    border-color: $colorLinkHover !important
    &:hover
      background: transparent !important
      border-color: $colorLink !important
      color: $colorLink !important

.prospectResultWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__prospectResult
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      //background: white
      padding: $paddingGrid $paddingGrid 0 $paddingGrid
      &__top
        &__menu
          margin: $paddingMedium 0 $paddingSmall 0
      &__bottom
        flex-grow: 1
        display: flex
        justify-content: flex-start
        &__left
          display: flex
          flex-direction: column
          flex-grow: 1
          max-width: 100%
          &__info
            width: 100%
            max-width: 600px
            margin: 0 auto
        &__right
          position: fixed
          top: 0
          right: 0
          z-index: 7
          height: 100vh
          @extend .shadowPreview
          @extend .widgetWidthAnimation
          width: $widthPreviewWindow
          max-width: calc(100vw - #{$widthMinimizedNavigation})
          &.minimize
            @extend .widgetWidthAnimationMinimized

// Keep style similar between ProspectResultPreview, AgileBoardPreview and LeadsPreview
.prospectResultPreviewWrapper
  height: 100%
  background: white
  &__prospectResultPreview
    height: 100%
    display: flex
    flex-direction: column
    &__header
      background: $grey-50
      @extend .shadowSoft
      &__top
        margin: 0 $paddingBig
        padding: $paddingBig 0
        display: flex
        justify-content: space-between
        cursor: pointer
        &__left
          max-width: calc(100% - 20px - #{$paddingMedium})
        &__right
          width: 20px
          margin-left: $paddingMedium
          svg
            width: 20px
            height: 20px
            cursor: pointer
            &:hover
              fill: $colorLink
    &__content
      overflow-y: auto
      overflow-x: hidden
      @extend .scrollbarRegular
      padding: $paddingMedium $paddingMedium ($paddingMedium * 2) $paddingMedium
      flex-grow: 1
      scroll-behavior: smooth
      &__block
        margin-top: ($paddingBig * 2)
        &:first-of-type
          margin-top: $paddingBig
        &__header
          cursor: pointer
          position: relative
          border-top: 1px solid $grey-300
          color: $colorTextLight-1
          &:hover
            color: $colorLink
          &__title
            display: flex
            align-items: center
            position: absolute
            top: -14px
            right: $paddingMedium
            padding: 4px
            background: white
            svg
              width: 18px
              height: 18px
        &__content
          display: flex
          flex-wrap: wrap
          margin-top: $paddingBig
          &__row
            padding: $paddingMedium
            display: flex
            align-items: center
            justify-content: space-between
            flex-basis: 100%
            //&:nth-child(even)
            //  background: $grey-50
            &__left
              display: flex
              align-items: center
              &__content
                margin: 0 $paddingMedium
                .lightAndItalic
                  font-style: italic
                  color: $colorTextLight-1
                .highlight
                  font-weight: 600
                  display: inline-flex
                  align-items: center
                  svg
                    fill: $colorFail
                .marginLeft
                  margin-left: 4px
                .marginRight
                  margin-right: 4px
                .extraMarginRight
                  margin-right: $paddingSmall
                &__additionalLine
                  display: block
                  margin-left: ($paddingSmall + 16px)
            &__right
              display: flex
              .tooltipWrapper
                margin-left: $paddingSmall
          &__infoRow
            display: flex
            flex-basis: 100%
            margin-bottom: 4px
            &__left
              color: $colorTextLight-1
              margin-right: $paddingSmall
              word-break: break-word
              width: 32%
              min-width: 32%
            &__right
              display: flex
              flex-grow: 1
              white-space: pre-wrap
              word-break: break-word
              flex-wrap: wrap

.prospectResultAggsWrapper
  display: flex
  flex-wrap: wrap
