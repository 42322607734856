.container {
  --selectPdfColor: var(--colorPrimary-100);
  fieldset {
    margin-top: 24px;
    border: none;
  }
  .pdfContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid var(--grey-400);
    background-color: var(--colorPrimary-50);
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    &.selectable:hover:not(.invalid) {
      background-color: var(--selectPdfColor);
      cursor: pointer;
    }
  }
  .inputContainer {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .pdfContainer.invalid {
    cursor: not-allowed;
  }
  .pdfContainer.selected {
    background-color: var(--selectPdfColor);
  }
  .imgPreview {
    max-height: 60vh;
    overflow: auto;
  }
}
