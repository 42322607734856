@media (max-width: $breakpoint2)
	.contactsWrapper
		&__contacts
			&__content
				flex-direction: column
				&__search
					margin: 0
					width: 100%
				&__contacts
					margin-top: $paddingBig
					width: 100%

@media (max-width: $breakpoint6)
	.contactsWrapper
		&__contacts
			&__content
				&__contacts
					&__item
						width: 100%
						margin-right: 0
						&:nth-child(2)
							margin-top: $paddingMedium

@media (max-width: $breakpoint9)
	.contactsWrapper
		&__contacts
			&__content
				&__contacts
					&__item
						&__content
							&__row
								margin-bottom: 4px
								&__left
									display: none
