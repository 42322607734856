.commentWrapper
  &__comment
    &__content
      &.marginBottom
        margin-bottom: $paddingBig
      &.marginTop
        margin-top: $paddingBig
      &__input
        display: flex
        flex-direction: column
        align-items: flex-start
        textarea
          margin-bottom: $paddingSmall
