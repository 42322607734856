@media (max-width: $breakpoint6)
  .pdfArchiveManageFilesWrapper
    &__pdfArchiveManageFiles
      &__content
        &__upload
          &__rowOne
            align-items: flex-start
            &__file
              flex-direction: column
              align-items: flex-start
              &__custom
                max-width: unset
                margin: -4px 0 0 0
              &__save
                margin: $paddingMedium 0 0 0
        &__archive
          &__items
            &__item
              width: 100%
