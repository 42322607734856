$minWidthCell: 120px
$minWidthCellResponsive2: 100px
$minWidthNameCell: 200px
$minWidthNameCellResponsive2: 140px

.activityTypesTableWrapper
  height: 100%
  overflow: hidden
  &__activityTypesTable
    height: 100%
    overflow: hidden
    &__content
      margin-top: $paddingBig
      height: calc(100% - #{$paddingBig})
      padding: 0 0 $paddingMedium 0
      overflow: auto
      @extend .scrollbarRegular
      &__tableColumnHeadersWrapper
        position: sticky
        top: 0
        z-index: 2
        display: inline-block // Keeps from scrolling out of view
        &.activityTypesTableResponsiveWidth1
          .activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders__cell
            &:first-child
              position: unset
        &.activityTypesTableResponsiveWidth2
          .activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders__cell
            width: $minWidthCellResponsive2
            min-width: $minWidthCellResponsive2
            &:first-child
              position: unset
              width: $minWidthNameCellResponsive2
              min-width: $minWidthNameCellResponsive2
              padding-left: 0
              padding-right: 0
        &__tableColumnHeaders
          display: flex
          gap: $paddingBig
          &__cell
            background: white
            min-width: $minWidthCell
            font-size: $fontSizeStandard1
            line-height: $fontSizeStandard2
            font-weight: 600
            white-space: nowrap
            padding-bottom: $paddingMedium
            &:first-child
              position: sticky
              left: 0
              padding-left: $paddingBig
              padding-right: $paddingSmall
              min-width: $minWidthNameCell
              justify-content: flex-start
              flex-grow: 1
            &:last-child
              padding-right: $paddingBig
              min-width: calc(#{$minWidthCell} + #{$paddingBig})
              width: calc(#{$minWidthCell} + #{$paddingBig})
      &__table
        display: inline-block // Keeps from scrolling out of view
        &.activityTypesTableResponsiveWidth1
          .activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell
            &:first-child
              position: unset
        &.activityTypesTableResponsiveWidth2
          .activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell
            padding-top: $paddingSmall
            padding-bottom: $paddingSmall
            width: $minWidthCellResponsive2
            min-width: $minWidthCellResponsive2
            &:first-child
              position: unset
              width: $minWidthNameCellResponsive2
              min-width: $minWidthNameCellResponsive2
              padding-left: 0
              padding-right: 0
        &__rowsHolder
          margin-top: calc(#{$paddingBig} * 2)
          &:first-child
            margin-top: 0
          &__rows
            &__rowHolder
              cursor: pointer
              &.summary
                cursor: default
                .activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell
                  color: $colorTextMain !important
                  a, span, .tooltipWrapper, .tooltipWrapper__tooltip__indicator
                    font-weight: 600
              & > .activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder // When rowsHolder is embedded inside a row.
                margin-top: 0
                &.closed
                  display: none
                  visibility: hidden
                  @extend .widgetHeightAnimationMinimized
                &.open
                  @extend .widgetHeightAnimation
              &__row
                display: flex
                gap: $paddingBig
                &:hover
                  .activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell
                    &:first-child
                      color: $colorLink
                &__cell
                  background: white
                  padding-top: $paddingMedium
                  padding-bottom: $paddingMedium
                  display: flex
                  flex-direction: column
                  width: $minWidthCell
                  min-width: $minWidthCell
                  color: $colorTextLight
                  &:first-child
                    position: sticky
                    left: 0
                    padding-left: $paddingBig
                    padding-right: $paddingSmall
                    align-items: flex-start
                    justify-content: center
                    min-width: $minWidthNameCell
                    width: $minWidthNameCell
                    flex-grow: 1
                    color: $colorTextMain
                    a, span, .tooltipWrapper, .tooltipWrapper__tooltip__indicator
                      font-size: $fontSizeStandard1
                      line-height: $fontSizeStandard2
                  &:last-child
                    padding-right: $paddingBig
                    min-width: calc(#{$minWidthCell} + #{$paddingBig})
                    width: calc(#{$minWidthCell} + #{$paddingBig})
                  &:nth-child(2)
                    .bar
                      background: $colorChart0
                  &:nth-child(3)
                    .bar
                      background: $colorChart1
                  &:nth-child(4)
                    .bar
                      background: $colorChart2
                  &:nth-child(5)
                    .bar
                      background: $colorChart3
                  &:nth-child(6)
                    .bar
                      background: $colorChart4
                  &:nth-child(7)
                    .bar
                      background: $colorChart5
                  &:nth-child(8)
                    .bar
                      background: $colorChart6
                  &:nth-child(9)
                    .bar
                      background: $colorChart7
                  &:nth-child(10)
                    .bar
                      background: $colorChart8
                  &:nth-child(10)
                    .bar
                      background: $colorChart9
                  &:nth-child(11)
                    .bar
                      background: $colorChart0
                  &:nth-child(12)
                    .bar
                      background: $colorChart1
                  &:nth-child(13)
                    .bar
                      background: $colorChart2
                  &__value
                    width: 100%
                    display: flex
                    justify-content: space-between
                    a, span, .tooltipWrapper, .tooltipWrapper__tooltip__indicator
                      white-space: nowrap
                      overflow: hidden
                      text-overflow: ellipsis
                      font-size: $fontSizeStandard4
                      line-height: $fontSizeStandard4
                      width: 100%
                    span.dealerTotalSum
                      text-align: right
                      span.delimiter
                        font-size: $fontSizeStandard-2
                        //margin: 0 $paddingSmall
                        color: $colorTextLight-1
                        margin-right: 6px
                        position: relative
                        top: -1px
                      span.lowlight
                        font-size: $fontSizeStandard
                        color: $colorTextLight-1
                        //margin-left: $paddingSmall
                  &__barHolder
                    width: 100%
                    height: 3px
                    background: $grey-100
                    margin-top: $paddingSmall
                    .bar
                      background: $colorChart0
                      height: 100%
                      max-width: 100%
                      // -webkit-animation: fullWidthLeft 2s
                      // -moz-animation: fullWidthLeft 2s
                      // -o-animation: fullWidthLeft 2s
                      // animation: fullWidthLeft 2s
                      // this animation makes rendering severely more expensive, leave out for now
.backIcon
  border-radius: 50%
  width: calc($iconSizeBig + 6px)
  height: calc($iconSizeBig + 6px)
  background-color: transparent
  border: none
  display: flex
  align-items: center
  justify-content: center
  transition: background-color 0.5s

  svg
    fill: $grey-700
    transition: fill 0.75s

  &:hover
    background-color: $colorPrimary-100
    cursor: pointer

    svg
      fill: black

  &.disabled
    background-color: transparent
    &:hover
      cursor: not-allowed
    svg
      fill: $grey-400
.forwardIcon
  @extend .backIcon
