.carWrapper
  &__car
    &__content
      background: white
      //border-left: 1px solid $grey-300
      &__item
        border-bottom: 1px solid $grey-300
        padding: $paddingBig
        &:last-of-type
          border-bottom: 0

// Used by CarInfo and CarUser components.
.carInfoWrapper
  &__carInfo
    &__content
      margin-top: $paddingBig
      padding: 0
      display: flex
      flex-wrap: wrap
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__item
        margin: 2px 2px 0 0
        width: calc(25% - ((3 * 2px) / 4))
        padding: $paddingMedium
        word-break: break-word
        background: $grey-100
        &:nth-child(4n)
          margin-right: 0
        div.editable
          display: flex
          justify-content: space-between
          align-items: center
          .tooltipWrapper
            margin-left: $paddingSmall
        .dropdownWrapper__dropdown__header
          svg
            fill: $colorTextMain
        svg
          color: $colorLink
          cursor: pointer
          width: 14px
          height: 14px
          &:hover
            fill: $colorLinkHover
        &__label
          font-weight: 600
        &__dealsHolder
          display: flex
          align-items: center
          flex-wrap: wrap
          a
            font-size: $fontSizeStandard-1
          span.dealLink
            margin-right: 4px
            &:last-of-type
              margin-right: 0

.carUserHistoryWrapper
  &__carUserHistory
    &__content
      margin-top: $paddingBig
      overflow: hidden
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__row
        display: flex
        justify-content: space-between
        padding: $paddingMedium
        &:nth-child(even)
          background: $grey-50
        &__left
          width: 34%
          margin-right: $paddingBig
          cursor: pointer
          color: $colorLink
          &:hover
            color: $colorLinkHover
        &__center
          width: calc(41% - #{$paddingBig})
          margin-right: $paddingBig
        &__right
          width: calc(25% - #{$paddingBig})
        &__leftLabel
          width: 34%
          font-weight: 600
          margin-right: $paddingBig
        &__centerLabel
          width: calc(41% - #{$paddingBig})
          font-weight: 600
          margin-right: $paddingBig
        &__rightLabel
          width: calc(25% - #{$paddingBig})
          font-weight: 600

.carVehicleDataWrapper
  &__carVehicleData
    &__content
      margin-top: $paddingBig
      overflow: hidden
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__block
        margin-top: $paddingBig
        &:last-of-type
          padding-bottom: 8px
        &__header
          cursor: pointer
          position: relative
          border-top: 1px solid $grey-300
          color: $colorTextLight
          &:hover
            color: $colorLink
          &__title
            display: flex
            align-items: center
            position: absolute
            top: -17px
            right: $paddingMedium
            padding: 4px
            background: white
            svg
              width: 18px
              height: 18px
        &__content
          display: flex
          flex-wrap: wrap
          margin-top: $paddingMedium
          &__item
            margin: 2px 2px 0 0
            width: calc(25% - ((3 * 2px) / 4))
            padding: $paddingMedium
            word-break: break-word
            background: $grey-100
            &:nth-child(4n)
              margin-right: 0
            &__label
              font-weight: 600

