.container {
  .stepInstruction {
    user-select: text;
    margin-bottom: 12px;
    margin-top: 6px;
  }
  .stepWarning {
    user-select: text;
    margin-bottom: 16px;
  }

  button {
    padding: 4px 8px;
    cursor: pointer;
    width: 100%;
  }

  .radioSelector,
  .checkbox {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 4px;
    padding: 4px;
    &.disabled {
      background-color: var(--grey-300);
      cursor: not-allowed;
    }
    &:hover:not(.disabled) {
      cursor: pointer;
      background-color: var(--colorPrimary-100);
    }
  }
}
