.newsWidgetLabel
  font-weight: 700
  font-size: $fontSizeStandard-2
  line-height: $fontSizeStandard-1
  color: $colorTextMain
  text-transform: uppercase
  letter-spacing: 0.3px

.newsWidgetWrapper
  height: 100%
  overflow: hidden // Important for responsive solution
  &.newsWidgetResponsiveWidth1
    .newsWidgetWrapper
      &__newsWidget
        &__content
          &__archive
            &__items
              flex-direction: column
              gap: $paddingMedium
              &__item
                width: 100%
                display: flex
                gap: 0
                border-bottom: 1px solid $grey-300
                padding-bottom: $paddingMedium
                &:last-child
                  border-bottom: 0
                &__top
                  display: none
                &__bottom
                  width: 100%
                  height: unset
                  max-height: unset
                  gap: $paddingSmall
                  &__headline
                    width: 100%
                    height: unset
                    max-height: unset
                    font-size: $fontSizeStandard4
                    line-height: $fontSizeStandard6
                  &__dateAndName
                    flex-direction: row-reverse
                    flex-wrap: wrap
                    gap: 4px
                    &__date
                      max-width: unset
                      min-width: unset
                      text-transform: none
                      letter-spacing: unset
                      font-size: $fontSizeStandard-2
                      line-height: $fontSizeStandard-2
                    &__name
                      max-width: unset
                      font-size: $fontSizeStandard-2
                      line-height: $fontSizeStandard-2
  &__newsWidget
    height: 100%
    display: flex
    flex-direction: column
    //overflow: hidden // Important for responsive solution
    &__content
      flex-grow: 1
      display: flex
      flex-direction: column
      //overflow: hidden // Important for responsive solution
      &__archive
        flex-grow: 1
        display: flex
        flex-direction: column
        // commented out because said responsive solution was creating an infinite loop of updating
        // on some specific resolutions, needs to be looked into further in the future 
        //overflow: hidden // Important for responsive solution
        position: relative
        &__itemsHidden
          position: absolute
          top: 0
          z-index: -1
          max-height: 10px
          overflow: hidden
          @extend .scrollbarHidden
        &__items
          display: flex
          flex-direction: column
          gap: $paddingMedium
          overflow: auto
          @extend .scrollbarRegular
          flex-grow: 1
          &__item
            width: 100%
            display: flex
            gap: $paddingSmall
            border-bottom: 1px solid $grey-300
            padding-bottom: $paddingMedium
            //height: 100%
            &:last-child
              border-bottom: 0
            &__top
              width: 60px
              img
                clip-path: circle()
              &__img
                width: 100%
                height: 100%
                img
                  width: 100%
                  height: 100%
                  object-fit: cover
                  &.default
                    object-fit: scale-down
                    max-width: 100%
            &__bottom
              width: calc(100% - 60px)
              gap: $paddingSmall
              padding: $paddingMedium
              display: flex
              flex-direction: column
              justify-content: space-between
              &__headline
                font-size: $fontSizeStandard4
                line-height: $fontSizeStandard6
                font-weight: 700
                letter-spacing: 0.2px
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                word-break: break-word
                &:hover
                  color: $colorLink
                  cursor: pointer
              &__dateAndName
                flex-direction: row-reverse
                flex-wrap: wrap
                gap: 4px
                display: flex
                justify-content: space-between
                overflow: hidden
                &__date
                  text-transform: none
                  font-size: $fontSizeStandard-2
                  line-height: $fontSizeStandard-2
                  color: $colorTextLight
                  white-space: nowrap
                  overflow: hidden
                &__name
                  font-size: $fontSizeStandard-2
                  line-height: $fontSizeStandard-2
                  @extend .newsWidgetWrapper__newsWidget__content__archive__items__item__bottom__dateAndName__date
                  min-width: unset
                  text-overflow: ellipsis
    &__footer
      margin-top: $paddingBig
      &__navigate
        display: flex
        justify-content: flex-end
        a
          cursor: pointer
          display: flex
          justify-content: flex-start
          align-items: center
          color: $colorLink
          &:hover
            color: $colorLinkHover
          h4
            //text-transform: none
            font-weight: 600
            font-size: $fontSizeStandard-1
            letter-spacing: 0.7px
          svg
            width: 18px
            height: 18px
            margin-left: 2px
