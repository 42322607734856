@media (max-width: $breakpoint4)
  .createListSubscriptionWrapper
    &__createListSubscription
      &__content
        &__section
          &__mailings
            &__info
              flex-direction: column
              &__left
                width: 100%
                margin: 0
              &__right
                width: 100%
                margin: 0

@media (max-width: $breakpoint9)
  .createListSubscriptionWrapper
    &__createListSubscription
      &__content
        &__section
          &__mailings
            &__section
              &__header
                &__left
                  width: unset
              &__content
                margin-left: 0
