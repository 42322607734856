.popupWrapper
  background: rgba(0, 0, 0, 0.3)
  cursor: default
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 7
  display: flex
  align-items: flex-start
  justify-content: center
  padding: 3% 0
  &.center
    align-items: center
  &__popup
    // -webkit-animation: fullHeight 0.3s
    // -moz-animation: fullHeight 0.3s
    // -o-animation: fullHeight 0.3s
    // animation: fullHeight 0.3s
    max-height: 100%
    width: 50%
    z-index: 8
    padding: $paddingBig
    background: white
    overflow: auto
    -webkit-overflow-scrolling: touch
    @extend .scrollbarRegular
    &__content
      // -webkit-animation: fullOpacity 0.4s
      // -moz-animation: fullOpacity 0.4s
      // -o-animation: fullOpacity 0.4s
      // animation: fullOpacity 0.4s
      max-height: 100%
    &.noPadding
      padding: 0
    &.noScroll
      height: 100%
      overflow: hidden
      .popupWrapper__popup__content
        height: 100%
    &.xl
      width: 86% !important
    &.big
      width: 70% !important
    &.medium
      width: 50% !important
    &.small
      width: 36% !important
    &.onlyContent
      width: unset !important
      max-width: 86%
      padding: 0
      background: transparent
    &.borderRadius
      border-radius: 8px
    &.overflow
      overflow: visible

//.popupNoScrollWrapper
//  background: rgba(0, 0, 0, 0.2)
//  position: fixed
//  top: 0
//  left: 0
//  width: 100%
//  height: 100%
//  z-index: 9
//  display: flex
//  align-items: flex-start
//  justify-content: center
//  padding: 3% 0
//  &__popupNoScroll
//    -webkit-animation: fullHeight 0.3s
//    -moz-animation: fullHeight 0.3s
//    -o-animation: fullHeight 0.3s
//    animation: fullHeight 0.3s
//    overflow: hidden
//    height: 100%
//    width: 50%
//    z-index: 9
//    padding: $paddingBig
//    &__content
//      height: 100%
//      overflow: hidden
//      -webkit-animation: fullOpacity 0.4s
//      -moz-animation: fullOpacity 0.4s
//      -o-animation: fullOpacity 0.4s
//      animation: fullOpacity 0.4s
//    &.noPadding
//      padding: 0
//    &.fullscreenPopup
//      width: 95vw !important
//      height: 95vh !important
//      padding: 0 0
//    &.bigPopup
//      width: 70% !important
//    &.mediumPopup
//      width: 50% !important
//    &.smallPopup
//      width: 36% !important

.mapPopupWrapper
  background: rgba(0, 0, 0, 0.6)
  cursor: default
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 7
  display: flex
  align-items: center
  justify-content: center
  padding: 0 0

.popup-new-bg
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  z-index: 2
  background-color: rgba(0,0,0,0.3)

.popup-new-content
  background-color: #fff
  width: 65vw
  min-width: 50vw
  max-width: 80vw
  min-height: 50vh
  max-height: 90vh
  overflow: auto

.popup-new-content.small
  width: 50vw

.popup-new-content.mini
  width: 30vw
  max-width: 30vw
  max-height: 90vh
  min-height: 0
