.excelWrapper
  &.excelResponsiveWidth1
    .excelWrapper
      &__excel
        &__content
          &__columns
            &__bottom
              &__section
                &__content
                  flex-direction: column
                  gap: 4px
                  &__item
                    width: 100%
  &__excel
    &__content
      margin: $paddingBig 0
      p
        margin-top: $paddingMedium
        &:first-child
          margin-top: 0
      &__target
        margin-bottom: $paddingBig
      &__selectTemplate
        &__select
          margin: calc(#{$paddingBig} * 1.5) 0
      &__columns
        &__top
          display: flex
          flex-direction: column
          gap: $paddingMedium
          &__button
            max-width: 500px
        &__bottom
          margin-top: $paddingBig
          &__section
            margin-top: $paddingBig
            &:first-of-type
              margin-top: 0
            &__content
              display: flex
              flex-wrap: wrap
              gap: 4px $paddingBig
              &__toggleAll
                width: 100%
                display: inline-flex
                align-items: flex-start
                margin-top: $paddingMedium
                cursor: pointer
                color: $colorTextMain
                font-size: $fontSizeStandard1
                line-height: $fontSizeStandard5
                font-weight: 600
                &:hover
                  color: $colorLink
                  svg
                    fill: $colorLink
                svg
                  width: 16px
                  height: 16px
                  fill: $colorTextLight
                  margin-right: $paddingSmall
                  position: relative
                  top: 4px
                &.active
                  color: $colorLink
                  svg
                    fill: $colorLink
              &__item
                width: calc(50% - (#{$paddingBig} / 2))
                display: flex
                align-items: flex-start
                color: $colorTextMain
                cursor: pointer
                font-size: $fontSizeStandard1
                line-height: $fontSizeStandard5
                &:hover
                  color: $colorLink
                  svg
                    fill: $colorLink
                svg
                  width: 16px
                  height: 16px
                  fill: $colorTextLight
                  margin-right: $paddingSmall
                  position: relative
                  top: 4px
                &.active
                  color: $colorLink
                  &:hover
                    color: $colorLinkHover
                    svg
                      fill: $colorLinkHover
                  svg
                    fill: $colorLink
      &__summary
        span.label
          display: inline-block
          color: $colorTextMain
          margin: 0 0 4px 0
          font-weight: 600
          font-size: $fontSizeStandard1
        &__selectedFields
          &__items
            display: flex
            align-items: flex-start
            gap: $paddingSmall
            flex-wrap: wrap
            span
              @extend .chip
        &__price
          margin-top: $paddingBig
          &__row
            &.priceSum
              margin: $paddingMedium 0
              border-top: 1px solid $grey-300
              padding-top: $paddingMedium
              font-size: $fontSizeStandard4
              font-weight: 700
            .loadingWrapperSmall
              display: inline-flex
              position: relative
              bottom: 4px
              left: 10px
        &__approveCost
          margin-top: $paddingBig
          .loadingWrapperSmall
            position: relative
            bottom: 4px
            margin: 0 $paddingSmall
            width: 30px
            overflow: hidden
          &__button
            margin-top: $paddingBig
        &__download
          margin-top: $paddingBig
          &__button
            margin-top: calc(#{$paddingBig} * 2)
            width: 100%
            display: flex
            justify-content: center
