.analyseWrapper
	&__analyse
		.infoWrapper
			width: calc(100% - (#{$paddingGrid} * 4))
			margin: 0 calc(#{$paddingGrid} * 2)
		&__header
			&__bottom
				.menuFloatFreeWrapper
					margin: $paddingBig 0
		&__content
			background-color: $grey-100
			width: 100%
			&__cars,
			&__sales,
			&__ledningsrapport
				width: 100%
				display: flex
				flex-wrap: wrap
				justify-content: center
			&__ledningsrapport
				.myAreaPicker
					margin: auto
					margin-top: 1em
					p
						padding-bottom: 1em

.analyseOptionsWrapper
	&__header
		margin-bottom: 1em
	&__content
		&__option,
		&__preview
			padding: $paddingBig
			margin: 0.5em 0
			background-color: $grey-100
			display: flex
			flex-direction: column
			justify-content: space-between
			&__selector
				&__radio
					&__option
						width: 40%
						display: flex
						flex-direction: row
						justify-content: space-between
						align-items: center
						.svg
							width: .7em
			&__label
				padding-bottom: 1em !important
				span
					display: block

.analyseChartWrapper
	background-color: #FFFFFF
	margin: calc(#{$paddingGrid} / 2)
	@extend .paperSheet
	padding: $paddingBig
	max-height: 100%
	overflow-y: auto
	overflow-x: hidden
	position: relative
	@extend .scrollbarRegular
	&__groupHeader
		position: sticky
		top: 0
		z-index: 2
		background-color: #fff
		padding-top: $paddingBig
	&.noShadow
		box-shadow: unset
		-webkit-box-shadow: unset
		-moz-box-shadow: unset
		padding: unset
		border: unset
	&.fullWidth
		width: 97.5%
		margin: auto !important
	&.halfWidth
		width: calc(50% - #{$paddingBig})
	&__info
		&__content
			&__section
				display: flex
				flex-direction: column
				margin: 2em 0
				h4
					border-bottom: 1px solid $grey-300
					padding-bottom: $paddingGrid
					margin-bottom: $paddingBig
					text-transform: none
				&__subsection
					display: flex
					flex-direction: row
					margin: .5em 0
					&__header
						width: 20%
						color: $colorTextLight
					&__value
						span
							display: block

	&__header
		//&__options
		//	border: 1px solid green
		//	display: flex
		//	&__relative
		//		position: relative
		//	.tooltipWrapper
		//		&:first-of-type
		//			padding-left: 12px
		//	&__selector
		//		z-index: 100
		//		position: relative
		//		padding: $paddingGrid
		//		background-color: white
		//		@extend .shadowSmallDark
		//		position: absolute
		//		top: 100%
		//		right: 0
		//		display: flex
		//		flex-direction: row
		//		align-items: center
		//		justify-content: center
		//		svg
		//			&:hover
		//				transform: scale(1.1)
		//		.tooltipWrapper__tooltip__indicator
		//			display: flex
		//			align-items: center
		//			justify-content: center
		//		.tooltipWrapper
		//			&:first-of-type
		//				padding-left: 0px
	&__content
		&__granularity, &__drilldown
			display: flex
			align-items: center
			span
				&:first-of-type
					color: $colorTextLight-1
				&:after
					content: ":"
					padding: 0 $paddingGrid
			.granularity, .drilldown
				color: $colorTextLight-1
				cursor: pointer
				&:hover
					color: #6262f0
				&:after
					content: "/"
					margin: 0 $paddingGrid
					color: $colorTextLight-1
				&:last-of-type
					&:after
						content: ""
						display: none
			.highlight
				font-weight: bold
				color: $colorTextMain
			svg
				transform: scale(0.65)
		&__chart
			width: 100%
			position: relative
			display: inline-block
			&__dummy
				margin-top: 50%
			&__elementContainer
				position: absolute
				top: 0
				left: 0
				right: 0
				bottom: 0
				.infoWrapper
					margin-top: $paddingBig
				&__number
					width: 100%
					height: 100%
					display: flex
					justify-content: center
					align-items: center
					flex-direction: column
					span
						font-weight: bold
						font-size: 4em
						cursor: pointer
						padding: .5em
				&__loading
					position: relative
					width: 100%
					height: 100%
					&__loder
						position: absolute
						z-index: 10
						top: 50%
						left: 50%
						transform: translate(-50%, -50%)

.analyseDashboardWrapper
	display: flex
	flex-direction: column
	flex-grow: 1
	&__analyseDashboard
		display: flex
		flex-direction: column
		flex-grow: 1
		&__content
			display: flex
			flex-direction: column
			flex-grow: 1
			&__info
				margin-left: $paddingGrid
				margin-top: $paddingGrid
				background: white
				padding: $paddingBig
				width: 50%
				@extend .paperSheet
