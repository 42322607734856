@media (max-width: $breakpoint-1)
  .widgetKanbanBoardWrapper
    &__widgetKanbanBoard
      &__content
        & > .react-trello-board
          & > div
            & > .smooth-dnd-container
              &.horizontal
                grid-auto-columns: 100%
                grid-auto-flow: row
        &__info
          width: 100%
