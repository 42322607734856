*
  margin: 0
  padding: 0
  box-sizing: border-box
  //font-size: $fontSizeStandard
  line-height: 1.53em
  font-family: 'Source sans pro', sans-serif
  font-weight: 400
  white-space: normal // smooth-dnd adds white-space nowrap to every element

body
  background: white
  color: $colorTextMain
  font-size: $fontSizeStandard
  overflow-x: hidden

.appHolder
  margin: 0

img
  max-width: 100%

input
  border: 0
  background: transparent
  border-bottom: 1px solid $grey-400
  font-size: $fontSizeStandard
  color: $colorTextMain
  padding: 4px $paddingSmall
  width: 100%
  max-width: 100%
  -webkit-appearance: none // Safari fix
  filter: none // Remove FF auto-fill color.
  &:hover
    border-color: $colorLink
  &:focus
    border-color: $colorLink
    outline: none
  &::placeholder
    color: $colorTextLight-1
    font-size: $fontSizeStandard-2
  &:-ms-input-placeholder
    color: $colorTextLight-1
    font-size: $fontSizeStandard-2
  &::-ms-input-placeholder
    color: $colorTextLight-1
    font-size: $fontSizeStandard-2

svg
  width: 14px
  height: 14px
  transition: none

svg.customSvg
  padding: 4px

svg.active
  fill: $colorLinkHover

svg.disabled
  pointer-events: none
  fill: $grey-300

.MuiSvgIcon-root
  transition: none !important // Unset Material-UI transition for svg icons.

textarea
  height: 32px
  width: 100%
  max-width: 100%
  color: $colorTextMain
  border: 0
  background: transparent
  border-bottom: 1px solid $grey-400
  padding: 4px $paddingSmall
  &:hover
    border-color: $colorLink
  &:focus
    border-color: $colorLink
    outline: none
  &::placeholder
    color: $colorTextLight-1
    font-size: $fontSizeStandard-2
  &:-ms-input-placeholder
    color: $colorTextLight-1
    font-size: $fontSizeStandard-2
  &::-ms-input-placeholder
    color: $colorTextLight-1
    font-size: $fontSizeStandard-2

// react-datepicker input
.react-datepicker-wrapper
  .react-datepicker__input-container
    input
      cursor: pointer
      width: 100%
      max-width: 100%
      border: 0
      border-bottom: 1px solid $grey-400
      padding: $paddingSmall
      color: $colorTextMain

// react-datepicker selection
.react-datepicker
  .react-datepicker__navigation
    &:focus
      outline: none
  .react-datepicker__navigation--previous
    border-right-color: $colorTextMain
    &:hover
      border-right-color: $colorLink
  .react-datepicker__navigation--next
    border-left-color: $colorTextMain
    &:hover
      border-left-color: $colorLink
  .react-datepicker__navigation--next--with-time
    right: 90px
  .react-datepicker__month-container
    .react-datepicker__header
      background: $grey-100
    .react-datepicker__year-dropdown
      background: white
    span.react-datepicker__year-read-view--down-arrow
      margin-left: $paddingBig * 1.5
      border-top-color: $colorTextMain
      &:hover
        border-top-color: $colorLink
    .react-datepicker__year-option
      padding: 3px 0
      border: 1px solid transparent
      &:hover, &--selected_year
        background: white
        border-color: $colorLink
        color: $colorLink
        a.react-datepicker__navigation--years-upcoming
          border-bottom-color: $colorLink
        a.react-datepicker__navigation--years-previous
          border-top-color: $colorLink
        svg
          fill: $colorLink
        &:last-of-type, &:first-of-type
          &:hover
            border-color: transparent
      a.react-datepicker__navigation--years-upcoming
        border-bottom-color: $colorTextMain
        &:hover
          border-bottom-color: $colorLink
      a.react-datepicker__navigation--years-previous
        border-top-color: $colorTextMain
        &:hover
          border-top-color: $colorLink
      .react-datepicker__current-month
        text-transform: capitalize
        color: $colorTextMain
      .react-datepicker__day-names
        .react-datepicker__day-name
          color: $colorTextLight
    .react-datepicker__month
      .react-datepicker__week
        .react-datepicker__day
          color: $colorTextLight
          border: 1px solid transparent
          &:focus
            outline: none
          &:hover
            background: transparent
            border-color: $colorLink
            color: $colorLink
        .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range
          background: $colorLink !important
          color: white !important
          font-weight: 400 !important
        .react-datepicker__day--keyboard-selected
          background: transparent
        .react-datepicker__day--in-selecting-range
          background: $colorLink
          color: white
        .react-datepicker__day--disabled
          color: $colorTextLight-2
  .react-datepicker__time-container
    .react-datepicker__header--time
      background: $grey-100
    .react-datepicker__time
      .react-datepicker__time-box
        .react-datepicker__time-list
          .react-datepicker__time-list-item
            border: 1px solid transparent
            &:hover
              background: transparent
              border-color: $colorLink
              color: $colorLink
          .react-datepicker__time-list-item--selected
            background: $colorLink
            font-weight: 400
