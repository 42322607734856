.createDealWrapper
  &__createDeal
    position: relative
    &__header
      &__right
        position: absolute
        display: flex
        justify-content: space-between
        //padding: $paddingSmall
        grid-gap: 5px
        right: 0
        top: 0
        align-items: center
      &__center
        display: flex
        align-items: center
      &__left
        &__icon .MuiSvgIcon-root
          display: flex
          align-items: center
          font-size: 36px
          margin-left: 8px

    &__content
      &__top
        margin: $paddingBig 0
        display: grid
        grid-template-columns: repeat(2,1fr)
        justify-content: space-between
        grid-auto-flow: dense // This property ensures that items fill the grid space dynamically
        &:last-child
          grid-column: 1 / -1 // Make the last item span all columns

        &__item
          display: flex
          flex-basis: calc(50% - (#{$paddingMedium} / 2))
          flex-direction: column
          justify-content: space-between
          align-items: flex-start
          margin-top: $paddingSmall
          max-height: fit-content
          width: 100%

          &:nth-child(odd)
            margin-right: calc(#{$paddingMedium} / 2)
          &:nth-child(even)
            margin-left: calc(#{$paddingMedium} / 2)
          p.label
            color: $colorTextLight
            font-size: $fontSizeStandard-2
            margin: 0
          &__container
            width: 100%
            &__flex
              display: flex
              justify-content: space-between
              width: 100%
          &__inputField
            width: 100%
            resize: none
            border-bottom: 1px solid #bab8d0
            &__disabled
              padding: 6px
              position: relative
              background: linear-gradient(135deg, rgba(0, 0, 0, 0.04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.04) 50%, rgba(0, 0, 0, 0.04) 75%, transparent 75%, transparent)
              color: #666
              border-color: 1px solid #bab8d0
          &__search
            display: flex
            flex-basis: calc(50% - (#{$paddingMedium} / 2))
            flex-direction: column
            justify-content: space-between
            align-items: flex-start
            margin-top: $paddingSmall

            &:nth-child(odd)
              margin-right: calc(#{$paddingMedium} / 2)
            &:nth-child(even)
              margin-left: calc(#{$paddingMedium} / 2)
            p.label
              color: $colorTextLight
              font-size: $fontSizeStandard-2
              margin: 0
          &__chipholders
            display: flex
            flex-direction: row

            font-size: $fontSizeStandard-4
            grid-column: span 2

      &__bottom
        margin: $paddingBig 0 $paddingBig 0
        display: flex
        flex-wrap: wrap
        &__left
          width: 100%

        &__right
          width: 100%
          margin-left: 6px
          padding-left: 6px

        &__item
          display: flex
          align-items: flex-start
          flex-wrap: wrap
          &__buttons
            display: flex
            gap: $paddingSmall
            margin: 8px 0 0 0
            justify-content: flex-start
            align-items: center

          &__chipholder
            flex-grow: 1
            display: flex
            flex-wrap: wrap
            font-size: $fontSizeStandard-4

            &__noItem
              display: flex

            &__dropdownItem
              display: flex
              justify-content: space-between
              &__button
                cursor: pointer
                border: none
                background: #ffffff
            &__label
              margin-right: $paddingSmall
              display: flex
              align-items: center
              margin-top: $paddingSmall
              font-size: $fontSizeStandard-4

            &__info
              display: flex
              align-items: center
              font-style: italic
              margin-top: $paddingSmall
              font-size: $fontSizeStandard-4

            &__chip
              margin: $paddingSmall
              display: flex
              justify-content: space-between
              @extend .chip
              margin: $paddingSmall $paddingSmall 0 0
              font-size: $fontSizeStandard-4
