.wrapper {
  display: flex;
  gap: 25px;
  padding: 8px 4px;
  width: 100%;
  justify-content: space-evenly;
  @media (max-width: 700px) {
    gap: 8px;
    flex-wrap: wrap;
  }
}
