@media (max-width: $breakpoint-1)
  .prospectSearchRowWrapper__prospectSearchRow__content__right
    .prospectRangeWrapper
      width: 90%

@media (max-width: $breakpoint1)
  .prospectSearchRowWrapper__prospectSearchRow__content__right
    .prospectRangeWrapper
      width: 100%

@media (max-width: $breakpoint2)
  .prospectRangeWrapper
    &__prospectRange
      margin-top: -12px
      margin-bottom: 6px
      &__content
        &__left
          width: 100%
        &__center
          margin: -4px $paddingSmall 8px 0
  .prospectSearchRowWrapper
    &__prospectSearchRow
      &__content
        flex-direction: column
        margin: 0 $paddingBig
        &__left
          width: 100%
          margin: 0 0 $paddingSmall 0
          justify-content: flex-start
          font-weight: 600
        &__right
          width: 100%
          &__searchHolder
            &__left
              margin-left: 0
              &__input
                left: -#{$paddingMedium}
            &__right
              &.marginLeft
                margin-left: 341px

@media (max-width: $breakpoint3)
  .prospectSearchSectionWrapper
    &__prospectSearchSection
      .collapsibleWrapper
        &__collapsible
          &__content
            &__paddingHolder
              padding: $paddingMedium 0
  .prospectSearchSubSectionWrapper
    margin: $paddingBig 0 0 0
  .prospectSearchHeaderWrapper
    &__prospectSearchHeader
      &__content
        &__count
          &__item
            &:nth-child(1)
              display: none
            &:nth-child(2)
              margin-left: 0
  .prospectSearchRowWrapper__prospectSearchRow__content
    margin: 0 $paddingMedium

@media (max-width: $breakpoint4)
  .prospectSearchRowWrapper
    &__prospectSearchRow
      &__content
        &__right
          &__searchHolder
            flex-wrap: wrap
            height: unset
            &__left
              height: 48px
              width: 100%
              &__input
                width: 100%
              &__inputActive
                width: 100%
            &__right
              &.marginLeft
                margin-left: 0
              &__button
                &:first-of-type
                  margin-left: 0

@media (max-width: $breakpoint6)
  .prospectSearchWrapper
    &__prospectSearch
      &__content
        padding-left: $paddingSmall
        padding-right: $paddingSmall
  .prospectSearchHeaderWrapper
    &__prospectSearchHeader
      &__content
        &__count
          display: none
        &__buttons
          min-width: 100%
          margin: 0
          justify-content: space-evenly

@media (max-width: $breakpoint8)
  .prospectSearchSectionWrapper
    &__prospectSearchSection
      &__header
        justify-content: flex-start
        border-bottom: 1px solid $grey-500
        padding-bottom: $paddingSmall
        &__left
          display: none
        &__centerBorder
          display: none
        &__centerText
          font-size: $fontSizeStandard4
          svg
            display: none
        &__right
          display: none
  .prospectSearchHeaderWrapper
    &__prospectSearchHeader
      &__content
        &__buttons
          &__reset
            font-size: $fontSizeStandard2
            padding: 6px $paddingMedium
            svg
              width: $fontSizeStandard1
              height: $fontSizeStandard1
          &__search
            font-size: $fontSizeStandard2
            padding: 6px $paddingMedium
            svg
              width: $fontSizeStandard1
              height: $fontSizeStandard1

