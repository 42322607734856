.iconStyled
  display: flex
  align-items: center
  justify-content: center
  min-width: $heightSmallRoundIcon
  width: $heightSmallRoundIcon
  height: $heightSmallRoundIcon
  color: inherit
  background: rgba(255, 255, 255, 0.44)
  border-radius: 50%
  border: 1px solid
  svg
    width: $heightSmallRoundIconSvg
    height: $heightSmallRoundIconSvg
    margin: 0
  // Not great, but set color, border-color and fill explicitly to counteract any other styles.
  &.active
    color: $colorLinkHover !important
    border-color: $colorLinkHover !important
    svg
      fill: $colorLinkHover !important
  &.disabled
    pointer-events: none
    color: $colorTextLight-2 !important
    border-color: $colorTextLight-2 !important
    svg
      fill: $colorTextLight-2 !important
  &.event
    color: $colorEvent !important
    border-color: $colorEvent !important
    svg
      fill: $colorEvent !important
  &.fail
    color: $colorFail !important
    border-color: $colorFail !important
    svg
      fill: $colorFail !important
  &.info
    color: $colorInformatory !important
    border-color: $colorInformatory !important
    svg
      fill: $colorInformatory !important
  &.link
    cursor: pointer
    color: $colorLink !important
    border-color: $colorLink !important
    svg
      fill: $colorLink !important
    &:hover
      color: $colorLinkHover !important
      border-color: $colorLinkHover !important
      svg
        fill: $colorLinkHover !important
  &.linkDark
    cursor: pointer
    color: $colorTextDark !important
    border-color: $colorTextDark !important
    svg
      fill: $colorTextDark !important
    &:hover
      color: $colorLink !important
      border-color: $colorLink !important
      svg
        fill: $colorLink !important
  &.passed
    color: $colorEventPassedDate !important
    border-color: $colorEventPassedDate !important
    svg
      fill: $colorEventPassedDate !important
  &.fail
    color: $colorFail !important
    border-color: $colorFail !important
    svg
      fill: $colorFail !important
  &.success
    color: $colorSuccess !important
    border-color: $colorSuccess !important
    svg
      fill: $colorSuccess !important
  &.successFill
    background: $colorSuccess !important
    border-color: $colorSuccess !important
    color: white !important
    svg
      fill: white !important
  &.white
    background: transparent !important
    color: white !important
    border-color: white !important
    svg
      fill: white !important
  &.withinOneDay
    color: $colorEventWithinOneDay !important
    border-color: $colorEventWithinOneDay !important
    svg
      fill: $colorEventWithinOneDay !important
