.pageHeaderWrapper
	&__pageHeader
		display: flex
		justify-content: space-between
		align-items: center
		height: $heightPageHeader
		padding: 0 $paddingBig
		background: $colorHeaderBackground
		position: relative
		&__left
			&__search
				margin-right: $paddingBig
		&__center
			overflow: hidden
			color: $colorHeaderPrimary
			display: flex
			align-items: center
			&__breadcrumbs
				&__routes
					display: flex
					align-items: center
					justify-content: flex-start
					&__route
						margin-right: $paddingMedium
						display: flex
						align-items: center
						a
							font-size: $fontSizeStandard-2
							line-height: $fontSizeStandard
							color: $colorHeaderSuplementary
							&:hover
								color: $colorHeaderBackgroundDark
						span.delimiter
							margin-left: $paddingSmall
							font-size: $fontSizeStandard-3
							color: $colorHeaderSuplementary
			&__pageTitle
				display: flex
				overflow: hidden
				h2
					font-size: $fontSizeStandard3
					line-height: $fontSizeStandard4
					font-weight: 600
					text-transform: none
					overflow: hidden
					display: block
					white-space: nowrap
					text-overflow: ellipsis
					&.click
						cursor: pointer
					& > span
						font-size: $fontSizeStandard3
						line-height: $fontSizeStandard4
						font-weight: 600
						text-transform: none
						white-space: nowrap
				h3
					font-size: $fontSizeStandard-2
					line-height: 17px
					color: $colorHeaderSuplementary
					font-weight: normal
					text-transform: none
					margin-left: $paddingBig * 1.5
					position: relative
					top: 1px
					white-space: nowrap
					&:before
						content: '|'
						position: relative
						top: -1px
						margin-right: $paddingSmall
					.loadingWrapperSmall
						.moving-ellipsis
							& > div
								background: white
		&__right
			display: flex
			justify-content: flex-end
			align-items: center
			margin-left: $paddingBig * 2
			flex-grow: 1
			&__icon
				cursor: pointer
				display: flex
				justify-content: center
				align-items: center
				margin-left: $paddingBig
				width: $heightHeaderIcons
				min-width: $heightHeaderIcons
				height: $heightHeaderIcons
				border: 2px solid $colorHeaderPrimary
				border-radius: 50%
				padding: 7px
				&:first-of-type
					margin-left: 0
				&:hover
					border-color: $colorHeaderBackgroundDark
					svg
						fill: $colorHeaderBackgroundDark
				&.userImage
					border: 0
				svg
					width: 100%
					height: 100%
					fill: $colorHeaderPrimary


.dropdownWrapper.customHeaderElement .pageHeaderWrapper__pageHeader__right__icon
	margin-left:22px

	@media screen and (max-width: 460px)
		margin-left: 12px

.pageHeader_news_badge
	position: absolute
	bottom:0
	right:0
	
	background-color: red
	color: white
	border-radius: 50%
	padding: 2px 5px
	font-size: 9px
	font-weight: 800

.pageHeader_news_badge_content
	display: block
	text-align: center
	font-weight: 800

.MuiSvgIcon-root
	&.pageHeader_news_notification_icon
		cursor: pointer
		

.tooltipWrapper__tooltip
	&__indicator
		.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.pageHeader_news_notification_icon.css-i4bv87-MuiSvgIcon-root
			color: rgba(255, 0, 0, 0.8)




.pageHeader__newsNotification

	&__news_section
		padding-bottom: 5px
		margin-bottom: 5px
		margin-top: 5px
		width: 100%
		margin-left: 5px
		cursor: pointer
		font-size: 12px
		line-height: 1rem
		padding-left:12px

	&__no_news_section
		display: flex
		flex-direction: column
		padding: 25px 0px
		margin: 0
		margin-top: 0px
		width: 100%
		justify-content: center
		overflow: hidden
		&::before
			content: ''
			position: absolute
			top: -0.01px
			left: -0.01px
			right: 0.01px
			bottom: -0.01px
			border: 3px solid #ddd
		

	&__no_news_text_container
		display: flex
		flex-direction: column
		text-decoration: none
		width: 100%
		text-decoration: none
		justify-content: space-between

	&__no_news_flex_container
		display: flex
		text-align: center
		justify-content: space-between
		position: relative

	&__no_news_bottom_text
		position: absolute
		bottom: 0
		right: 0
		text-align: right

	&__navigate_to_news
		display: flex
		flex-direction: flex
		align-items: center

	&__flex_container
		display: flex
		justify-content: space-between
		align-items: center
		margin: 0
		padding: 0

	&__text_container
		display: flex
		flex-direction: column
		align-items: flex-start
		text-decoration: none
	&__newsItemLast
		display: flex
		margin-right: 10px
		width: 100%
		justify-content: space-between
		align-items: center
		border-bottom: none
		padding-left: 12px
		border-top: 1px solid #ddd
		border-bottom: 1px solid #ddd
		border-left: 1px solid #ddd
	&__newsItem
		display: flex
		margin-right: 10px
		width: 100%
		justify-content: space-between
		align-items: center
		border-bottom: 1px solid #ccc
		padding-left: 12px
		border-top: 1px solid #ddd
		border-bottom: 1px solid #ddd
		border-left: 1px solid #ddd
