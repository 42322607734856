.wrapper {
  padding: 16px 24px;
  padding-bottom: 36px;
  &:first-of-type {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
  .sectionHeader {
    font-size: 19px;
    font-weight: bolder;
    padding-bottom: 8px;
  }
  .event {
    border-bottom: 1px solid var(--grey-400);
    margin: 12px 0;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
  }
  .eventName {
    font-size: 16px;
    padding-bottom: 2px;
  }
}
