@media (max-width: $breakpoint-3)
  .agileKanbanBoardWrapper
    &__agileKanbanBoard
      &__content
        & > .react-trello-board
          & > div
            & > .smooth-dnd-container
              &.horizontal
                grid-auto-columns: calc(25% - (#{$paddingGrid} - 1px))

@media (max-width: $breakpoint1)
  .agileKanbanBoardWrapper
    &__agileKanbanBoard
      &__content
        &.columns4
          & > .react-trello-board
            & > div
              & > .smooth-dnd-container
                &.horizontal
                  grid-auto-columns: calc(33.33% - (#{$paddingGrid} - 1px))
        & > .react-trello-board
          & > div
            & > .smooth-dnd-container
              &.horizontal
                grid-auto-columns: calc(33.33% - (#{$paddingGrid} - 1px))

@media (max-width: $breakpoint3)
  .agileBoardWrapper
    &__agileBoard
      &__content
        &__left
          &__top
            &__search
              display: none
  .agileKanbanBoardWrapper
    &__agileKanbanBoard
      &__content
        &.columns3, &.columns2, &.columns1, &.columns0
          & > .react-trello-board
            & > div
              & > .smooth-dnd-container
                &.horizontal
                  grid-auto-columns: calc(50% - (#{$paddingGrid} - 1px))
        &.columns4
          & > .react-trello-board
            & > div
              & > .smooth-dnd-container
                &.horizontal
                  grid-auto-columns: calc(50% - (#{$paddingGrid} - 1px))
        & > .react-trello-board
          & > div
            & > .smooth-dnd-container
              &.horizontal
                grid-auto-columns: calc(50% - (#{$paddingGrid} - 1px))

@media (max-width: $breakpoint4)
  .agileBoardAddActivityWrapper
    &__agileBoardAddActivity
      &__content
        &__isPerformed
          width: 100%
          margin-right: 0
        &__actions
          width: 100%
          margin-left: 0

@media (max-width: $breakpoint6)
  .agileBoardWrapper
    &__agileBoard
      &__content
        &__left
          &__bottom
            margin-top: $paddingBig
          &__top
            display: none

@media (max-width: $breakpoint7)
  .agileKanbanBoardWrapper
    &__agileKanbanBoard
      &__content
        &.columns3, &.columns2, &columns1, &.columns0
          & > .react-trello-board
            & > div
              & > .smooth-dnd-container
                &.horizontal
                  grid-auto-columns: calc(100% - 1px)
        &.columns4
          & > .react-trello-board
            & > div
              & > .smooth-dnd-container
                &.horizontal
                  grid-auto-columns: calc(100% - 1px)
        & > .react-trello-board
          & > div
            & > .smooth-dnd-container
              &.horizontal
                grid-auto-columns: calc(100% - 1px)
  .agileBoardAddActivityWrapper
    &__agileBoardAddActivity
      &__content
        &__date
          width: 100%
          margin-right: 0
          margin-bottom: $paddingBig
        &__comment
          width: 100%
          margin-left: 0
