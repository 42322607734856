.tableWrapper
  width: 100%
  &.resizable
    height: 100%
    flex-grow: 1
    overflow: hidden
    table.MuiTable-root
      min-width: unset !important
      td
        white-space: nowrap !important
        overflow: hidden
        max-width: 170px // Correlates to columnWidth value in component.
        text-overflow: ellipsis
  &.adjustToContainer
    flex-grow: 1 // flex-grow: 1 rather than height: 100% because of safari.For adjustToContainer to work in all browsers, the parent container should have display: flex, flex-direction: column
    position: relative
    .tableWrapper__table
      position: absolute
      top: 1px
      right: 1px
      bottom: 1px
      left: 1px
      overflow: hidden
      display: flex
      flex-direction: column
      justify-content: space-between
      &__content
        overflow: hidden
        display: flex
        flex-direction: column
        flex-grow: 1
        .MuiTableContainer-root
          flex-grow: 1 // flex-grow: 1 rather than height: 100% because of safari.
      &__footer
        width: calc(100% - 9px)
        margin-top: -1px
        margin-left: 1px
        border-top: 1px solid $grey-300
        padding-bottom: $paddingGrid
        height: 64px
  &__table
    &__content
      @extend .scrollbarRegular
      overflow-y: auto // Overflow is setup like this to work in both chrome and safari.
      overflow-x: hidden
      -webkit-overflow-scrolling: touch
      &__top
        display: flex
        justify-content: space-between
        align-items: center
        margin: $paddingSmall $paddingMedium
        &__left
          margin-bottom: $paddingMedium
          &.noSelected
            visibility: hidden
        &__right
          width: 220px
          margin-bottom: $paddingBig
      .MuiTableContainer-root
        //width: 100%
        @extend .scrollbarRegular
        -webkit-overflow-scrolling: touch
        overflow: auto
        max-height: 75vh
        table.MuiTable-root
          min-width: $minWidthTable
          background: white
          thead
            // position: relative
            // z-index: 1 // Fixes some overlay problems with popups, don't increase.
            tr
              th
                background: white
                //border-top: 1px solid $grey-300
                border-bottom: 1px solid $grey-300
                border-right: 1px solid $grey-100
                color: $colorTextDark !important
                font-size: $fontSizeStandard
                font-weight: 600
                padding: 0 $paddingSmall 6px $paddingSmall
                font-family: 'Source sans pro', sans-serif !important
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                &:last-child
                  border-right: 0
                & > span // Sometimes the text is inside span elements.
                  color: $colorTextDark !important
                  font-size: $fontSizeStandard
                  font-weight: 600
                  white-space: nowrap
                .MuiTableSortLabel-icon
                  fill: $colorLink
                .MuiTableSortLabel-root
                  &:hover
                    .MuiTableSortLabel-icon
                      opacity: 1
          tbody
            tr
              &.rowPointer
                cursor: pointer
              td
                border-right: 1px solid $grey-100
                //color: $colorTextLight !important
                color: $colorTextLight !important
                font-size: $fontSizeStandard
                padding: $paddingSmall $paddingSmall
                font-family: 'Source sans pro', sans-serif !important
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                &:first-child
                  padding: $paddingMedium
                  top: -15px
                  left: -6px
                &:last-child
                  border-right: 0
                &.MuiTableCell-root
                  border-bottom: 1px solid $grey-300
                .editableCell
                  display: flex
                  justify-content: space-between
                  align-items: center
                  &:hover
                    .editableCell__icon
                      visibility: visible
                  &__icon
                    visibility: hidden
                    display: flex
                    align-items: center
                    .tooltipWrapper__tooltip__indicator
                      display: flex
                      align-items: center
                  &__icons
                    display: flex
                    align-items: center
                    justify-content: flex-end
                  svg
                    margin-left: $paddingSmall
                    &:hover
                      fill: $colorLinkHover
                  input
                    padding: 0
                    border: 0
                    background: transparent
                    font-size: $fontSizeStandard-1
                    border-bottom: 1px solid $colorLink
                .tableCellIconHolder
                  display: flex
                  align-items: center
                  justify-content: center
                  .tooltipWrapper
                    margin-left: $paddingSmall
                    &:first-of-type
                      margin-left: 0
                    &__tooltip
                      &__indicator
                        display: flex
                        align-items: center
                        justify-content: center
            .Mui-selected
              background: $grey-100 !important
              td
                color: $colorTextDark !important
                border-right: 1px solid $grey-300
            .MuiTableRow-hover
              &:hover
                background: $grey-100 !important
                td
                  color: $colorTextDark !important
                  border-right: 1px solid $grey-300
                  //border-bottom: 1px solid $grey-400
                  &:last-child
                    border-right: 0
          svg
            width: 16px
            height: 16px
            fill: $colorLink
            margin: 0 1px
            cursor: pointer
            &.waystarFlag
              width: 20px
              height: 20px
              &.red
                fill: $colorFail
              &.yellow
                fill: $colorInformatory
              &.green
                fill: $colorSuccess

            &:hover
              fill: $colorLinkHover
            &.available
              fill: $colorSuccess
              cursor: default
            &.notAvailable
              fill: $colorFail
              cursor: default
            &.pending
              fill: $colorSuccess
              cursor: default
            &.notification
              fill: $colorTextMain
              cursor: default
              &.pending
                fill: $colorInformatory
            &.inactive
              fill: $colorTextLight-2 !important
          .MuiTableSortLabel-root
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%
            &:hover
              color: $colorLink
              svg
                fill: $colorLink
            svg
              width: 14px
              height: 14px
          .Mui-checked
            svg
              fill: $colorLink
          .MuiTableCell-sizeSmall
            &:last-child
              padding-right: $paddingSmall
          .MuiCheckbox-indeterminate
            svg
              fill: $colorLink
          .MuiCheckbox-root
            display: flex
            align-items: center
            justify-content: center
            padding: 0
            &:hover
              background-color: transparent
              svg
                fill: $colorLinkHover
            svg
              width: 16px
              height: 16px
          //.MuiIconButton-root
          //  padding: 0
            //&:hover
            //  background-color: transparent
            //  svg
            //    fill: $colorLinkHover
    &__footer
      display: flex
      justify-content: space-between
      align-items: center
      &__left
        display: flex
        align-items: center
        margin-right: $paddingBig
        input
          margin: $paddingSmall 0
          //@extend .whiteInputField
        .MuiFormControl-root
          margin: $paddingSmall
          label
            font-size: $fontSizeStandard-1
            white-space: nowrap
      &__middle
        display: flex
        align-items: center
        margin-right: $paddingBig
        &__rowsPerPage
          color: $colorTextLight
          &__option
            margin-left: $paddingSmall
            cursor: pointer
            color: $colorLink
            &:hover
              color: $colorLinkHover
          &__optionActive
            @extend .tableWrapper__table__footer__middle__rowsPerPage__option
            color: $colorLinkHover
      &__right
        display: flex
        align-items: center
        margin-right: -12px
        .MuiTablePagination-root
          color: $colorTextLight
          overflow: hidden
          svg
            fill: $colorLink
            width: 16px
            height: 16px
          .MuiIconButton-root
            &:hover
              background: transparent
              svg
                fill: $colorLinkHover
          .Mui-disabled
            svg
              fill: $colorTextLight-1
          .MuiTypography-body2
            font-size: $fontSizeStandard
          .MuiTablePagination-actions
            margin-left: $paddingSmall
.generalButton
  display: flex
  background-color: transparent
  align-items: center
  border: none
.tableHeadFlex
  display: flex
  gap: 12px
.buttonMargin
  margin: $paddingMedium 0
