@media (max-width: $breakpoint4)
	.pageHeaderWrapper
		&__pageHeader
			&__center
				&__breadcrumbs
					display: none

@media (max-width: $breakpoint7)
	.pageHeaderWrapper
		&__pageHeader
			&__center
				&__pageTitle
					h3
						display: none
			&__right
				margin-left: $paddingBig

@media (max-width: $breakpoint8)
	.pageHeaderWrapper
		&__pageHeader
			&__right
				&__icon
					margin-left: $paddingMedium

@media (max-width: $breakpoint10)
	.pageHeaderWrapper
		&__pageHeader
			&__center
				&__pageTitle
					span.searchQuery
						display: none

