// Used by Company and Koncern components
.companyWrapper
  &__info
    width: 100%
  &__company
    &__content
      background: white

      &__item
        border-bottom: 1px solid $grey-400
        padding: $paddingBig
        display: flex
        flex-wrap: nowrap
        max-width: 100%
        &:last-of-type:has(.minimize)
          border-bottom: none
        .minimize &
          transition: background-color 1s ease-out 0s
        &.minimize:hover
          background-color: $grey-200
        &__icon__cursor
          cursor: pointer
        &__block
          &__header
            &__icon
              width: 20px
              height: 20px
              fill: $colorTextLight
      &__maximize_arrow,
      &__minimize_arrow
        display: inline-flex
        justify-content: flex-end
        align-items: center

// Used by CompanyInfo and KoncernInfo components.
.companyInfoWrapper
  width: 100%
  &__companyInfo
    &__header
      &__big
        display: flex
        &__content
          display: flex
          margin-left: .5em
          margin-bottom: .5em
          &__link
            display: flex
            justify-content: center
            align-items: center
            a
              margin-left: .5em
    &__content
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__navigate
        display: flex
        justify-content: flex-end
        margin-top: $paddingBig
        margin-bottom: $paddingSmall
        a
          cursor: pointer
          display: flex
          justify-content: flex-start
          align-items: center
          color: $colorLink
          font-weight: 600
          font-size: $fontSizeStandard-1
          letter-spacing: 0.7px
          text-transform: uppercase
          &:hover
            color: $colorLinkHover
          svg
            width: 16px
            height: 16px
            margin-left: 2px
      &__itemsWrapper
        display: flex
        flex-wrap: wrap
        &__item
          position: relative
          margin: 2px 0 0 2px
          width: calc(50% - 4px)
          padding: $paddingMedium
          background: $grey-100
          flex-grow: 1
          &:nth-child(3n)
            margin-right: 0
          div.editable
            display: flex
            justify-content: space-between
            align-items: center
            .tooltipWrapper
              margin-left: $paddingSmall
          &__companyInfoLabel
            font-weight: 600
            width: 100%
          .dropdownWrapper__dropdown__header
            svg
              fill: $colorTextMain
          svg
            color: $colorLink
            cursor: pointer
            width: 14px
            height: 14px
            &:hover
              fill: $colorLinkHover
          &__dealsHolder
            display: flex
            align-items: center
            flex-wrap: wrap
            a
              font-size: $fontSizeStandard-1
            span.dealLink
              margin-right: 4px
              &:last-of-type
                margin-right: 0
          &__field
            display: flex
            align-items: center
            min-height: 24px
            a
              width: 100%
            p
              flex-grow: 1
              overflow: hidden
              text-overflow: ellipsis
              white-space: nowrap
            svg
              margin-left: $paddingMedium
          &__addField
            margin-top: 4px
            display: flex
            align-items: center
          &__editField
            @extend .companyInfoWrapper__companyInfo__content__itemsWrapper__item__field
            svg
              display: inline
      &__flexContainer
        display: flex
        justify-content: flex-start
        padding: 0
        @media only screen and (max-width: 864px)
          display: inline

      &__widthContainer
        flex: 1
        min-width: 160px
        max-width: 160px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        display: flex
        @media only screen and (max-width: 768px)
          min-width: auto
          max-width: aut
      &__iconContainer
        position: absolute
        top: 0
        right: 0
      &__flexContainer__flex
        display: flex
        justify-content: flex-start
        flex-wrap: wrap
        align-items: flex-start
      &__flexContainer__custom__width
        flex: 1
        display: flex
        justify-content: flex-start
        flex-wrap: wrap
        align-items: center
        max-width: 60%
      &__flexContainer__custom__col
        flex: 1
        display: flex
        justify-content: flex-start
        word-break: break-word
        flex-direction: column
        flex-wrap: wrap
      &__valueText
        display: inline

      &__iconContainer
        display: flex
        justify-content: center
        align-items: center
      &__copyIcon
        margin-left: 5px

.companyStructureWrapper__customComponent__column__toolTip
  display: block

// KoncernStructure component.
.companyStructureWrapper
  width: 100%
  & .MuiTreeItem-group
    margin-left: 0

  &__customComponent
    display: flex
    align-items: stretch
    margin-top: 2px
    border-bottom: 1px solid $grey-300
    &__filial
      background-color: $grey-50
      box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.03)
    & .companyStructureWrapper__customComponent__column:first-of-type
      padding-left: $paddingBig
    &__group
      background-color: $grey-100
    &__column
      display: flex
      width: 15%
      justify-content: center
      align-items: center
      gap: 8px
      padding: $paddingSmall
      &:nth-of-type(n + 1)
        border-right: 1px solid $colorPrimary-800
      &:last-of-type
        border-right: none
      span
        margin-right: .5em
      svg
        color: $colorSecondary-700
      &:first-of-type
        justify-content: flex-start
        flex: 1
        svg
          color: $colorLink
      &__showFilial
        color: $colorLink
        cursor: pointer
        padding-left: $paddingMedium
        font-weight: bold
    &__icon
      color: $colorLink
      &:hover
        cursor: pointer
        color: $colorLinkHover
    &__iconContainer
      display: flex
      justify-content: center
      align-items: center
      &__noIcon
        padding-left: 25px
  &__companyStructure
    &__content
      display: flex
      flex-direction: column
      overflow: auto
      margin-top: $paddingBig
      display: flex
      position: relative
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__buttons
        display: flex
        margin-bottom: $paddingSmall
        justify-content: flex-end
        button
          margin: 0 .5em
      &__table
        min-width: 800px
        overflow: auto
        &__header
          position: sticky
          top: 0
          margin-top: 2px
          padding: $paddingSmall 0
          background-color: $grey-100
          margin: $paddingMedium 0
          display: flex
          div
            margin-right: 1em
            display: flex
          span
            display: inline-block
            text-align: center
            width: 15%
            font-weight: 600
            &:first-of-type
              text-align: left
              padding-left: 1em
              flex: 1

.companyInListsWrapper
  width: 100%
  &__companyInLists
    &__content
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      div
        &.MuiList-root
          width: 100%
          max-width: 100%
      span,
      p
        &.MuiTypography-root
          font-family: "Source sans pro", sans-serif
@media (max-width: 800px)
  .hideOnMobile, span.hideOnMobile
    display: none
  .companyStructureWrapper__companyStructure__content__table
    min-width: 500px
    &__header span
      width: 20%
  .companyStructureWrapper__customComponent__column
    width: 20%

.company__subNavigation
  &__buttons
    display: inline-flex
    padding: 2.5px
    text-decoration: none
    border-radius: 5px
    background-color: #6363f0
    color: #ffffff
    margin-top: 2.5px
    margin-bottom: 2.5px
    margin-left: 2.5px
    cursor: pointer
    max-width: fit-content
    max-height: fit-content
    span.company__subNavigation__buttons__icon
      display: flex
      align-items: center
    span.company__subNavigation__buttons__text
      padding: 2.5px
      margin-left: 2.5px
      display: flex
      align-items: center
    @media screen and (max-width: 1024px)
      margin-top: 2.5px
      margin-bottom: 2.5px
      margin-left: 2.5px
      font-size: 12px
    &__dropdownHeader
      margin: $paddingSmall auto $paddingSmall $paddingSmall
      padding: $paddingSmall auto $paddingSmall auto
      font-weight: bold
    &__dropdownItem
      display: flex
      justify-content: space-between
      align-items: flex-end
      align-content: flex-end
      align-self: flex-end
