.listWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__list
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      &__bottom
        //background: white
        padding: $paddingGrid $paddingGrid 0 $paddingGrid
        flex-grow: 1
        display: flex
        flex-direction: column
        &__menu
          margin: $paddingMedium 0 $paddingSmall 0

