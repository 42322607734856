.flashMessagesWrapper
  position: fixed
  bottom: 0
  width: 100%
  display: flex
  justify-content: center
  z-index: 10
  &__flashMessages
    background: white
    color: $colorTextDark
    border-radius: 4px
    border-left: 6px solid $colorInformatory
    @extend .shadowMedium
    padding: $paddingMedium * 1.5 $paddingBig * 1.5
    font-size: $fontSizeStandard3
    line-height: $fontSizeStandard4
    font-weight: 600
    z-index: 10
    position: fixed
    bottom: 0
    -webkit-animation: slideFromBottom 5s
    -moz-animation: slideFromBottom 5s
    -o-animation: slideFromBottom 5s
    animation: slideFromBottom 5s
    max-width: 40%
    &.fail
      border-color: $colorFail
    &.success
      border-color: $colorSuccess
