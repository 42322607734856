@media (max-width: $breakpoint0)
  .homeWrapper
    &__home
      h3
        font-size: $fontSizeStandard4
      p
        font-size: $fontSizeStandard1
  .homeFlowWrapper
    &__homeFlow
      &__content
        gap: calc(#{$paddingBig} * 3)
        &__item
          width: calc(33.33% - (#{$paddingBig} * 6 / 3))
          &__left
            &__icon
              width: 50px
              min-width: 50px
              height: 50px
              svg
                width: 24px
                height: 24px

@media (max-width: $breakpoint1)
  .homeHeaderWrapper
    &__homeHeader
      &__topWrapper
        background-color: white !important
        -webkit-box-shadow: 0 1px 3px rgba(158, 158, 173, 0.6) !important
        -moz-box-shadow: 0 1px 3px rgba(158, 158, 173, 0.6) !important
        box-shadow: 0 1px 3px rgba(158, 158, 173, 0.6) !important
      &__bottom
        display: block
        position: relative
        max-height: 400px
        margin-top: $paddingMedium
        &__left
          width: unset
          height: unset
          min-width: unset
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0
          z-index: 1
          padding: 0
          justify-content: flex-end
          align-items: flex-start
          &__holder
            //margin: calc(#{$paddingBig} * 2)
            max-width: 100%
            width: 100%
            height: 100%
            background: rgba(0,0,0,0.3)
            padding: calc(#{$heightHomepageTopMenu} + #{$paddingBig}) calc(#{$paddingBig} * 1.5) calc(#{$paddingBig} * 1.5) calc(#{$paddingBig} * 1.5)
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            h2
              color: white
              font-size: $fontSizeStandard13
              text-align: center
              &.subHeading
                margin: 0
            h4
              display: none
            &__buttonWrapper
              &__button
                background: rgba(0,0,0,0.2) !important
                color: white
                border-color: white
                &:hover
                  background: rgba(0,0,0,0.4) !important
                  color: white
                  border-color: white
        &__right
          width: unset
          height: unset
          -webkit-clip-path: unset
          clip-path: unset
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0
          z-index: 0
          img
            height: 100%
            width: 100%

@media (max-width: $breakpoint2)
  .homeFlowWrapper
    &__homeFlow
      padding: calc(#{$paddingBig} * 2) $paddingBig
      &__content
        gap: calc(#{$paddingBig} * 2)
        &__item
          width: calc(50% - ((#{$paddingBig} * 2) / 2))
          &__left
            &__icon
              width: 48px
              min-width: 48px
              height: 48px
              svg
                width: 22px
                height: 22px
  .homeTeamWrapper
    &__homeTeam
      &__content
        gap: calc(#{$paddingBig} * 2) calc(#{$paddingBig} * 3)
        &__item
          width: calc(50% - (#{$paddingBig} * 3) / 2)
  .homeContactWrapper
    &__homeContact
      flex-direction: column
      &__left
        width: 100%
        padding: calc(#{$paddingBig} * 2)
        &__content
          max-width: unset
          margin: 0
      &__right
        width: 100%
        padding: calc(#{$paddingBig} * 2)
        &__content
          max-width: unset
          margin: 0

@media (max-width: $breakpoint3)
  .homeWrapper
    &__home
      h2
        font-size: $fontSizeStandard9
        line-height: 0.94em
        &.subHeading
          font-size: $fontSizeStandard6
      h4
        font-size: $fontSizeStandard5
        letter-spacing: 0.38px
      .homeHeaderWrapper__homeHeader__bottom__left__holder__elevatorPitch
        h2
          font-size: $fontSizeStandard11
          &.subHeading
            font-size: $fontSizeStandard7
  .homeHeaderWrapper
    &__homeHeader
      &__topWrapper
        &__top
          &__left
            background-image: url('../../images/bilprospekt_logo_darkgrey_minimize.png')
            background-size: 30px
            height: 38px
            width: 34px
          &__right
            &__item
              font-size: $fontSizeStandard2
              letter-spacing: 0.4px
              &.login
                svg
                  width: 15px
                  height: 15px
  .homeInfoWrapper
    &__homeInfo
      padding: calc(#{$paddingBig} * 2) $paddingBig
      &__section
        max-width: 96%

@media (max-width: $breakpoint4)
  .homeWrapper
    &__home
      h2
        font-size: $fontSizeStandard8
        line-height: 0.94em
        &.subHeading
          font-size: $fontSizeStandard5
        & + h4
          margin-top: -2px
      h4
        font-size: $fontSizeStandard4
        letter-spacing: 0.34px
  .homeHeaderWrapper
    &__homeHeader
      &__topWrapper
        &__top
          &__left
            background-size: 24px
            height: 30px
            width: 26px
      &__bottom
        &__left
          &__holder
            &__buttonWrapper
              &__button
                font-size: $fontSizeStandard4 !important
                //line-height: 1em !important
  .homeInfoWrapper
    &__homeInfo
      gap: calc(#{$paddingBig} * 3)
      &__section
        max-width: 96%
        .imageHolder
          display: none
        .textHolder
          width: 100%

@media (max-width: $breakpoint5)
  .homeHeaderWrapper
    &__homeHeader
      &__topWrapper
        &__top
          padding: 0
          &__left
            display: none
          &__right
            width: 100%
            justify-content: space-evenly
            gap: unset
            &__item
              font-size: $fontSizeStandard4
              letter-spacing: 0.7px
              svg
                width: 16px
                height: 16px
  .homeFlowWrapper
    &__homeFlow
      &__content
        gap: calc(#{$paddingBig} * 2)
        &__item
          width: 100%
          &__left
            &__icon
              border: 0
              min-width: unset
              width: unset
              height: unset
              svg
                width: 26px
                height: 26px
                position: relative
                top: -4px

@media (max-width: $breakpoint7)
  .homeHeaderWrapper
    &__homeHeader
      &__topWrapper
        height: 72px
        &__top
          &__right
            &__item
              font-size: $fontSizeStandard2
              letter-spacing: 0.4px
              svg
                width: 15px
                height: 15px
  .homeReviewsWrapper
    &__homeReviews
      &__content
        &__items
          &__itemWrapper
            &__item
              &__quote
                font-size: $fontSizeStandard10
                line-height: 0.3em
              &__text
                &__row
                  p
                    span
                      font-size: $fontSizeStandard5
                      letter-spacing: 0.34px
              &__reviewer
                &__user
                  font-size: $fontSizeStandard3
                &__dealer
                  font-size: $fontSizeStandard1

@media (max-width: $breakpoint8)
  .homeHeaderWrapper
    &__homeHeader
      &__topWrapper
        &__top
          &__right
            &__item
              font-size: $fontSizeStandard1
              letter-spacing: 0.4px
              svg
                display: none
  .homeTeamWrapper
    &__homeTeam
      &__content
        gap: calc(#{$paddingBig} * 2)
        &__item
          width: 100%
          &__image
            width: 50%

@media (max-width: $breakpoint10)
  .homeHeaderWrapper
    &__homeHeader
      &__topWrapper
        &__top
          &__right
            &__item
              font-size: $fontSizeStandard
              letter-spacing: 0.3px
      &__bottom
        max-height: 360px
        &__left
          &__holder
            &__elevatorPitch
              h2
                font-size: $fontSizeStandard9 !important
                &.subHeading
                  font-size: $fontSizeStandard6 !important
