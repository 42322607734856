h1
  font-size: $fontSizeStandard6
  line-height: 0.92em
  font-weight: 600

h2
  font-size: $fontSizeStandard5
  line-height: 0.95em
  font-weight: 600
  text-transform: uppercase

h3
  font-size: $fontSizeStandard3
  line-height: 1.08em
  font-weight: 600
  text-transform: uppercase

h4
  font-size: $fontSizeStandard1
  line-height: 1.1em
  font-weight: 600
  text-transform: uppercase

h5
  font-size: $fontSizeStandard-1
  font-weight: 600
  line-height: 1.28em
  text-transform: uppercase

.italic
  font-style: italic
  color: $colorTextLight-1

strong
  font-weight: 600

.validationError
  color: red
