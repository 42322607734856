@media (max-width: $breakpoint3)
	.fleetAnalysisWrapper
		&__fleetAnalysis
			&__content
				&__item
					width: 100%
					margin-right: 0
					margin-left: 0
					&:nth-child(2)
						margin-top: $paddingSmall
