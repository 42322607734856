.leadsWidgetWrapper
  white-space: normal
  height: 100%
  max-height: 1000px // Might remove this when new widget system is in place and every widget parent has a specific height.
  overflow: hidden
  &__leadsWidget
    height: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    max-height: 1000px // Might remove this when new widget system is in place and every widget parent has a specific height.
    &__content
      margin-top: $paddingBig
      flex-grow: 1
      display: flex
      flex-direction: column
      justify-content: space-between
      overflow: hidden
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__top
        flex-grow: 1
        display: flex
        flex-direction: column
        overflow: hidden
        &__info
          margin-bottom: $paddingMedium
        &__table
          flex-grow: 1
          min-height: 150px
          display: flex
          flex-direction: column
          overflow: auto
          @extend .scrollbarRegular
      &__bottom
        display: flex
        justify-content: flex-end
        margin-top: $paddingMedium
        a
          display: flex
          align-items: center
          &:hover
            svg
              fill: $colorLinkHover
          h4
            //text-transform: none
            font-weight: 600
            font-size: $fontSizeStandard-1
            letter-spacing: 0.7px
          svg
            fill: $colorLink
            width: 18px
            height: 18px
            margin-left: 2px
