$newsUserImageWidth: 38px
$regularUserImageWidth: $iconSizeXL
$pageHeaderUserImageWidth: $heightHeaderIcons
$settingsUserImageWidth: 110px

.userImageWrapper
  &__userImage
    display: flex
    align-items: center
    justify-content: center
    &.settings
      min-width: $settingsUserImageWidth
      width: $settingsUserImageWidth
      height: $settingsUserImageWidth
      border-radius: 50%
      @extend .shadowSmallDark
      background: white
      img
        min-width: calc(#{$settingsUserImageWidth} - 8px)
        width: calc(#{$settingsUserImageWidth} - 8px)
        height: calc(#{$settingsUserImageWidth} - 8px)
        clip-path: circle()
        object-fit: cover
      svg
        width: calc(#{$settingsUserImageWidth} * 0.75)
        height: calc(#{$settingsUserImageWidth} * 0.75)
        fill: $grey-300
    &.pageHeader
      //background: transparent
      border: 2px solid $colorHeaderPrimary
      min-width: $pageHeaderUserImageWidth
      width: $pageHeaderUserImageWidth
      height: $pageHeaderUserImageWidth
      border-radius: 50%
      &:hover
        border-color: $colorHeaderBackgroundDark
        span.initial
          color: $colorHeaderBackgroundDark !important
          border-color: $colorHeaderBackgroundDark
      img
        min-width: 100%
        width: 100%
        height: 100%
      span.initial
        font-size: $fontSizeStandard1
        color: $colorHeaderPrimary !important
        border: 0
        min-width: $pageHeaderUserImageWidth
        width: $pageHeaderUserImageWidth
        height: $pageHeaderUserImageWidth
    &.news
      //background: transparent
      //border: 2px solid $colorTextMain
      min-width: $newsUserImageWidth
      width: $newsUserImageWidth
      height: $newsUserImageWidth
      border-radius: 50%
      pointer-events: none
      img
        min-width: 100%
        width: 100%
        height: 100%
      span.initial
        font-size: $fontSizeStandard1
        color: $colorTextMain !important
        border: 2px solid
        min-width: $newsUserImageWidth
        width: $newsUserImageWidth
        height: $newsUserImageWidth
    img
      min-width: $regularUserImageWidth
      width: $regularUserImageWidth
      height: $regularUserImageWidth
      clip-path: circle()
      object-fit: cover
    .tooltipWrapper__tooltip__indicator
      display: flex
      align-items: center
      justify-content: center
    span.initial
      font-weight: 700
      font-size: $fontSizeStandard4
      //width: 100%
      //height: 100%
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      border: 2px solid
      min-width: $regularUserImageWidth
      width: $regularUserImageWidth
      height: $regularUserImageWidth
      color: $colorChartTurquoise
      &.color-0
        color: $colorChartGreen
      &.color-1
        color: $colorChartLime
      &.color-2
        color: $colorChartYellow
      &.color-3
        color: $colorChartOrange
      &.color-4
        color: $colorChartTurquoise
      &.color-5
        color: $colorChartYellow
      &.color-6
        color: $colorChartPurple
      &.color-7
        color: $colorChartPurpleDark
      &.color-8
        color: $colorChartBlue
      &.color-9
        color: $colorChartTurquoise


