.container {
  .filename {
    font-weight: bold;
  }
  label {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    input[type='text'] {
      background-color: var(--grey-300);
      border: 1px solid var(--grey-500);
    }
    &:hover {
      cursor: pointer;
      input {
        background-color: var(--grey-400);
      }
    }
  }
  .buttonContainer {
    display: flex;
    gap: 16px;
    margin-top: 32px;

    .cancel {
      background: var(--grey-300);
      color: var(--grey-900);
      &:hover {
        background: var(--grey-400);
        color: var(--grey-900);
        border: 1px solid transparent;
      }
    }
    .upload:hover {
      background: var(--colorPrimary-600);
      color: white;
    }
  }
  .uploadPdfInput {
    cursor: pointer;
  }
}
