.itemPanel
  border: 1px solid $grey-300
  padding: 12px 12px
  border-radius: 4px
  display: flex
  align-items: flex-start
  justify-content: space-between
  margin-right: 4px
  margin-top: 4px
  width: 290px
  height: 144px

.detailPanel // flexbox container
  display: flex
  flex-flow: column
  justify-content: space-between
  align-items: flex-start
  height: 100%
  margin-right: 8px
  h4
    color: #5050cf
    overflow-wrap: break-word
    width: 9em
  &__text
    overflow: hidden
    text-overflow: ellipsis
    &__description
      overflow: hidden
      text-overflow: ellipsis
// flex-grow: 1
// white-space: nowrap

.simpleShadow
  &__120
    width: 120px
    height: 120px
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)
  &__240
    width: 240px
    height: 240px
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)


.mapEdit
  transform: scale(0.6)

.postNummerTools
  display: flex
  .pointer
    svg
      width: 1em
      height: 1em

.grid-container
  font-family: sans-serif
  background: white
  text-align: center
  width: auto
  height: auto
  display: grid
  grid-template-columns: auto 120px
  grid-template-rows: 60px auto 120px
  gap: 2px 2px
  border-radius: 12px 12px 12px 12px
  grid-template-areas: 'map_header map_header' 'map map_panel' 'map_footer map_footer'

.pointer
  cursor: pointer
  &:hover
    color: #0AB7CF

.item__row__separator
  svg
    width: 24px !important
    height: 24px !important
