.fleetWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__fleet
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__content
      flex-grow: 1
      //background: white
      &__menu
        margin-top: $paddingBig
      &__item
        padding: $paddingBig
        border-bottom: 1px solid $grey-300
        &:last-of-type
          border-bottom: 0

.fleetAnalysisWrapper
  &__fleetAnalysis
    &__content
      display: flex
      justify-content: flex-start
      flex-wrap: wrap
      background: $grey-100
      margin-top: $paddingBig
      padding: $paddingSmall
      overflow: hidden
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      svg
        width: unset
        height: unset
        transition: unset
      &__item
        width: calc(50% - (#{$paddingSmall} / 2))
        flex-grow: 0
        flex-shrink: 0
        min-width: 0
        margin-top: $paddingSmall
        margin-left: 4px
        margin-right: 0
        padding: $paddingMedium
        overflow: hidden
        background: white
        &:nth-child(odd)
          margin-right: 4px
          margin-left: 0
        &:nth-child(1), &:nth-child(2)
          margin-top: 0
        h5
          text-transform: none
          text-align: center
          margin-bottom: $paddingMedium
      //&__infoHolder
      //  width: 100%
      //  background: white
      //  padding: $paddingSmall

