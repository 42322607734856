$heightRegularMenu: 52px
$regularMenuFontWeight: 600
$regularMenuFontSize: $fontSizeStandard
$regularMenuFontTransform: none
$regularMenuLetterSpacing: 0.2px
//$regularMenuBackground: transparent
$regularMenuBackground: white
//$regularMenuBackground: $grey-100
//$regularMenuBackgroundTransparent: $grey-100-00
$regularMenuBackgroundTransparent: rgba(255, 255, 255, 0)
$regularMenuItemBackground: transparent
$regularMenuItemBackgroundActive: transparent
$regularMenuItemBackgroundDisabled: transparent
$regularMenuItemBackgroundHover: transparent
$regularMenuItemBorderColorActive: transparent
$regularMenuItemBorderColorHover: transparent
$regularMenuItemDelimiterColor: white
$regularMenuItemColor: $colorTextDark
$regularMenuItemColorActive: $colorLink
$regularMenuItemColorDisabled: $colorTextLight-1
$regularMenuItemColorHover: $colorLink
$regularMenuItemPadding: 0 4px
$regularMenuItemMargin: 0 ($paddingBig * 1.4)

.menuWrapper
  background: $regularMenuBackground
  @extend .shadowSmallBottom
  //border-bottom: 1px solid $grey-300
  position: relative
  &__border
    width: 100%
    margin-top: -1px
  &__menu
    overflow-x: auto
    scroll-behavior: smooth
    @extend .scrollbarHidden
    &__scrollIndicatorLeft
      background: linear-gradient(to right, $regularMenuBackground 60%, $regularMenuBackgroundTransparent)
      position: absolute
      left: 0
      top: 0
      z-index: 3
      height: calc(#{$heightRegularMenu} - 1px) // Give room for bottom border
      padding: 0 26px 0 6px
      display: flex
      align-items: center
      justify-content: center
      svg
        fill: $regularMenuItemColor
        width: 18px
        height: 18px
    &__scrollIndicatorRight
      @extend .menuWrapper__menu__scrollIndicatorLeft
      background: linear-gradient(to left, $regularMenuBackground 60%, $regularMenuBackgroundTransparent)
      left: unset
      right: 0
      padding: 0 6px 0 26px
    //&__toggle // For responsive menu version, not used atm.
    //  display: none
    &__items
      display: flex
      align-items: center
      justify-content: flex-start
      width: 100%
      color: $regularMenuItemColor
      font-size: $regularMenuFontSize
      letter-spacing: $regularMenuLetterSpacing
      padding: 0
      svg
        width: 12px
        height: 12px
        fill: $regularMenuItemColor
        margin-right: $paddingSmall
      .menuButtonWrapper
        &__menuButton
          background: $regularMenuItemBackground
          flex: 1 1 0
          display: flex
          align-items: center
          padding: $regularMenuItemPadding
          margin: $regularMenuItemMargin
          cursor: pointer
          height: $heightRegularMenu
          white-space: nowrap
          font-weight: $regularMenuFontWeight
          text-transform: $regularMenuFontTransform
          border-bottom: 1px solid transparent
          &:hover
            background: $regularMenuItemBackgroundHover
            color: $regularMenuItemColorHover
            border-color: $regularMenuItemBorderColorHover
            svg
              fill: $regularMenuItemColorHover
          &.disabled
            color: $regularMenuItemColorDisabled
            background: $regularMenuItemBackgroundDisabled
            pointer-events: none
            svg
              fill: $regularMenuItemColorDisabled
          &.active
            background: $regularMenuItemBackgroundActive
            color: $regularMenuItemColorActive
            border-color: $regularMenuItemBorderColorActive
            svg
              fill: $regularMenuItemColorActive
      .menuDropdownWrapper
        &__menuDropdown
          background: $regularMenuItemBackground
          display: flex
          align-items: center
          padding: $regularMenuItemPadding
          margin: $regularMenuItemMargin
          cursor: pointer
          height: $heightRegularMenu
          white-space: nowrap
          border-bottom: 1px solid transparent
          &:hover
            background: $regularMenuItemBackgroundHover
            color: $regularMenuItemColorHover
            border-color: $regularMenuItemBorderColorHover
            svg
              fill: $regularMenuItemColorHover
            .dropdownWrapper__dropdown__header
              color: $regularMenuItemColorHover
              svg
                fill: $regularMenuItemColorHover
          &.disabled
            color: $regularMenuItemColorDisabled
            background: $regularMenuItemBackgroundDisabled
            pointer-events: none
            svg
              fill: $regularMenuItemColorDisabled
          &.active
            background: $regularMenuItemBackgroundActive
            color: $regularMenuItemColorActive
            border-color: $regularMenuItemBorderColorActive
            svg
              fill: $regularMenuItemColorActive
            .dropdownWrapper__dropdown__header
              color: $regularMenuItemColor
              svg
                fill: $regularMenuItemColor
          .dropdownWrapper
            min-width: 0
            &__dropdown
              &__header
                border: 0
                color: $regularMenuItemColor
                padding: 0
                p
                  font-weight: $regularMenuFontWeight
                  text-transform: $regularMenuFontTransform
                svg
                  fill: $regularMenuItemColor
                  width: 22px
                  height: 22px
                  margin-right: 0
              //&__content
              //  top: 41px
              //  left: -32px
              //  padding: 0
              //  .dropdownItemWrapper, .dropdownItemDelimiterWithCheckboxWrapper
              //    padding: $paddingMedium $paddingBig
              //    cursor: pointer
              //    svg
              //      fill: $grey-500
              //      margin-left: $paddingMedium
              //      width: 14px
              //      height: 14px
      .menuElementWrapper
        &__menuElement
          background: $regularMenuItemBackground
          display: flex
          align-items: center
          height: $heightRegularMenu
          padding: $regularMenuItemPadding
          margin: $regularMenuItemMargin
          cursor: pointer
          white-space: nowrap
          font-weight: $regularMenuFontWeight
          text-transform: $regularMenuFontTransform
          border-bottom: 1px solid transparent
          &:hover
            background: $regularMenuItemBackgroundHover
            color: $regularMenuItemColorHover
            border-color: $regularMenuItemBorderColorHover
            svg
              fill: $regularMenuItemColorHover
          &.disabled
            color: $regularMenuItemColorDisabled
            background: $regularMenuItemBackgroundDisabled
            pointer-events: none
            svg
              fill: $regularMenuItemColorDisabled
          &.active
            background: $regularMenuItemBackgroundActive
            color: $regularMenuItemColorActive
            border-color: $regularMenuItemBorderColorActive
            svg
              fill: $regularMenuItemColorActive
          svg
            width: 16px
            height: 16px
            fill: $regularMenuItemColor
            margin-right: $paddingSmall
      .menuSearchWrapper
        &__menuSearch
          background: $regularMenuItemBackground
          display: flex
          align-items: center
          padding: $regularMenuItemPadding
          margin: $regularMenuItemMargin
          cursor: pointer
          height: $heightRegularMenu
          white-space: nowrap
          position: relative // Important for search suggestions
          font-weight: $regularMenuFontWeight
          text-transform: $regularMenuFontTransform
          border-bottom: 1px solid transparent
          &:hover
            background: $regularMenuItemBackgroundHover
            color: $regularMenuItemColorHover
            border-color: $regularMenuItemBorderColorHover
            svg
              fill: $regularMenuItemColorHover
          &.disabled
            color: $regularMenuItemColorDisabled
            background: $regularMenuItemBackgroundDisabled
            pointer-events: none
            svg
              fill: $regularMenuItemColorDisabled
            input
              border-color: transparent
          &.active
            background: $regularMenuItemBackgroundActive
            color: $regularMenuItemColorActive
            border-color: $regularMenuItemBorderColorActive
            svg
              fill: $regularMenuItemColorActive
          svg
            width: 14px
            height: 14px
            margin: 0 $paddingSmall 0 0
          input
            margin-left: $paddingMedium
            width: 180px
            @extend .widgetWidthAnimation
            &.minimize
              @extend .widgetWidthAnimationMinimized

.menuDatepickerWrapper
  &__menuDatepicker
    background: $regularMenuItemBackground
    display: flex
    align-items: center
    padding: $regularMenuItemPadding
    margin: $regularMenuItemMargin
    cursor: pointer
    height: $heightRegularMenu
    white-space: nowrap
    font-weight: $regularMenuFontWeight
    text-transform: $regularMenuFontTransform
    border-bottom: 2px solid transparent
    &:hover
      background: $regularMenuItemBackgroundHover
      color: $regularMenuItemColorHover
      border-color: $regularMenuItemBorderColorHover
      svg
        fill: $regularMenuItemColorHover
    &__selection
      background: white
      padding: $paddingBig
      overflow: auto
      &__content
        display: flex
        justify-content: space-evenly
        margin-top: $paddingBig
        &__datepicker
          margin-right: $paddingBig
          &:last-of-type
            margin-right: 0
          p
            margin-bottom: $paddingMedium
            strong
              margin-right: 6px

.menuFloatFreeWrapper
  &.responsive
    .menuFloatFreeWrapper
      &__menuFloatFree
        &__items
          flex-direction: column
          .menuButtonWrapper
            margin: $paddingSmall 0 0 0
            width: 100%
            &:first-child
              margin-top: 0
            &__menuButton
              border: 1px solid $grey-400
              padding: 6px
              border-radius: 4px
              &.active, &:hover
                color: $colorLink
  &__menuFloatFree
    overflow: hidden
    max-width: 100%
    &__items
      display: inline-flex
      align-items: center
      justify-content: flex-start
      padding: 0
      border-bottom: 2px solid transparent
      width: 100%
      &.border
        border-color: $grey-200
        .menuButtonWrapper
          margin: 0
          &__menuButton
            padding: 0 $paddingBig $paddingSmall $paddingBig
      svg
        width: 14px
        height: 14px
        margin-right: $paddingSmall
      &.centered
        justify-content: center
      .menuButtonWrapper
        margin-right: $paddingBig * 1.3
        &:last-child
          margin-right: 0
        &__menuButton
          padding: 0 0 $paddingSmall 0
          background: transparent
          color: $colorTextMain
          border-bottom: 3px solid transparent
          cursor: pointer
          white-space: nowrap
          font-size: $fontSizeStandard1
          display: flex
          align-items: center
          justify-content: center
          position: relative
          bottom: -2px
          font-weight: 600
          &.active, &:hover
            color: $colorLinkHover
            border-color: $colorLink
          &.active
            pointer-events: none

.menuDelimitedWrapper
  &.responsive
    .menuDelimitedWrapper
      &__menuDelimited
        &__items
          flex-direction: column
          .menuButtonWrapper
            margin: $paddingSmall 0 0 0
            width: 100%
            &:first-child
              margin-top: 0
            &__menuButton
              border: 1px solid $grey-400
              padding: 6px
              border-radius: 4px
              &.active, &:hover
                color: $colorLink
  &.centered
    .menuDelimitedWrapper__menuDelimited__items
      justify-content: center
  &.startFromRight
    .menuDelimitedWrapper__menuDelimited__items
      flex-direction: row-reverse
  &__menuDelimited
    overflow: hidden
    max-width: 100%
    &__items
      display: inline-flex
      align-items: center
      justify-content: space-between
      padding: 0
      border-bottom: 2px solid transparent
      width: 100%
      svg
        width: 14px
        height: 14px
        margin-right: $paddingSmall
      .menuButtonWrapper
        &:first-child
          .menuButtonWrapper__menuButton
            margin-left: 0
        &__menuButton
          @extend .buttonInfoLeft
          margin-left: $paddingMedium
          &.iconToTheRight
            flex-direction: row-reverse
            @extend .buttonInfoRight

.menuLargeWrapper
  &__menuLarge
    &__items
      width: 100%
      display: flex
      justify-content: space-evenly
      text-transform: none
      align-items: stretch
      flex-wrap: wrap
      gap: calc(#{$paddingBig} * 2) $paddingBig
      .menuButtonWrapper
        flex: 1 1 0
        min-width: 180px
        width: 0
        &.disabled
          display: none
        &__menuButton
          height: 100%
          display: flex
          flex-direction: column
          justify-content: space-between
          align-items: center
          background: transparent
          color: $colorTextMain
          overflow: hidden
          word-break: break-word
          &__icon
            display: flex
            align-items: center
            justify-content: center
            border: 2px solid $colorTextMain
            border-radius: 50%
            min-width: 54px
            height: 54px
            width: 54px
            margin-bottom: $paddingMedium
            svg
              width: 24px
              height: 24px
              fill: $colorTextMain
          &__info
            border-radius: 2px
            width: 100%
            color: $colorTextMain
            display: flex
            flex-direction: column
            align-items: center
            &__label
              font-size: $fontSizeStandard3
              line-height: $fontSizeStandard4
              font-weight: 700
              overflow: hidden
              text-overflow: ellipsis
              text-align: center
              word-break: break-word
            &__labelSub
              text-align: center
              color: $colorTextMain
              font-size: $fontSizeStandard
              line-height: $fontSizeStandard3
              margin-top: 4px
              max-width: 90%
              word-break: break-word
          &__button
            margin-top: $paddingBig
            @extend .buttonRegular
            //border-color: $colorInformatory
            //background: $colorInformatory
            //color: white
            padding-left: calc(#{$paddingBig} * 2)
            padding-right: calc(#{$paddingBig} * 2)
            &.active
              border-color: $colorSuccess !important
              background: $colorSuccess !important
              color: white !important
              svg
                fill: white !important
            &:hover
              background: transparent !important
              border-color: $colorLink !important
              color: $colorLink !important
              svg
                fill: $colorLink !important
            svg
              width: 18px
              height: 18px
              margin: 0 $paddingMedium 0 0
            span
              white-space: nowrap
              font-size: $fontSizeStandard1

.menuSearchSuggestionsWrapper
  @extend .shadowRegular
  //@extend .scrollbarSmallDark
  @extend .scrollbarRegular
  @extend .dropdownAnimation
  z-index: 2
  overflow-y: auto
  overflow-x: hidden
  max-height: 494px // This is dynamically set in component.
  min-width: 0 // This is dynamically set in component.
  width: 100%
  max-width: 420px // This is dynamically changed in component.
  background: white
  position: absolute
  top: 100% // This is dynamically changed in component.
  left: 0 // This is dynamically changed in component.
  &.minimize
    @extend .dropdownAnimationMinimized
    max-height: 0
  &__menuSearchSuggestions
    &__itemWrapper
      padding: $paddingMedium
      border-bottom: 1px solid $grey-300
      &:last-of-type
        border-bottom: 0
      &.active
        color: $colorLink
      &__item
        cursor: pointer
        display: flex
        align-items: center
        justify-content: space-between
        &:first-of-type
          margin-top: 0
        &:hover
          color: $colorLink
        &__left
          flex-grow: 1
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          strong
            font-weight: 600
        &__right
          margin-left: $paddingMedium
          svg
            width: 14px
            height: 14px

//.menuResponsiveWrapper
//  background: $colorMenu
//  border-bottom: 1px solid $grey-50
//  &.delimited
//    background: transparent
//    border: 0
//    .menuResponsiveWrapper
//      &__menuResponsive
//        border: 1px solid $grey-300
//        &__toggle
//          &:hover
//            background: $colorMenu
//          &.active
//            background: $colorMenu
//        &__items
//          &__item
//            &:hover
//              background: $colorMenu
//            &.active
//              background: $colorMenu
//  &.floatFree
//    background: transparent
//    border: 0
//    .menuResponsiveWrapper
//      &__menuResponsive
//        border: 1px solid $grey-300
//        &__toggle
//          &:hover
//            background: $colorMenu
//          &.active
//            background: $colorMenu
//        &__items
//          &__item
//            &:hover
//              background: $colorMenu
//            &.active
//              background: $colorMenu
//  &__menuResponsive
//    border-bottom: 1px solid $grey-300
//    &__toggle
//      display: flex
//      align-items: center
//      justify-content: center
//      height: 12px
//      cursor: pointer
//      padding: $paddingMedium 0
//      &:hover
//        background: darken($colorMenu, 3%)
//      &.active
//        background: darken($colorMenu, 3%)
//        &:hover
//          background: darken($colorMenu, 6%)
//      svg
//        width: 22px
//        height: 22px
//    &__items
//      @extend .widgetHeightAnimation
//      &.minimize
//        @extend .widgetHeightAnimationMinimized
//      &__item
//        width: 100%
//        display: flex
//        align-items: center
//        justify-content: center
//        padding: $paddingSmall 0
//        border-bottom: 1px solid $grey-300
//        cursor: pointer
//        font-size: $fontSizeStandard-1
//        &:hover
//          background: darken($colorMenu, 3%)
//        &.disabled
//          color: $colorTextLight-2
//          pointer-events: none
//          svg
//            fill: $colorTextLight-2
//        &.active
//          background: darken($colorMenu, 3%)
//        svg
//          width: 12px
//          height: 12px
//          fill: $colorTextMain
//          margin-left: $paddingSmall
//        // Adjust the dropdown when it appears in item
//        .dropdownWrapper
//          min-width: 0
//          &__dropdown
//            &__header
//              border: 0
//              background: transparent
//              padding: 0
//              min-height: 0
//              display: flex
//              align-items: center
//              justify-content: center
//              svg
//                fill: $colorTextMain
//                width: 18px
//                height: 18px
//                margin-left: $paddingSmall
//            &__content
//              top: calc((#{$paddingMedium} * 2) + 5px)
//              width: 100%
//              max-width: unset
//              min-width: unset
//        .dropdownItemWrapper, .dropdownItemDelimiterWithCheckboxWrapper
//          padding: $paddingMedium $paddingBig
//          cursor: pointer
//          svg
//            fill: $grey-500
//            margin-left: $paddingMedium
//            width: 14px
//            height: 14px
//      &__element
//        width: 100%
//        display: flex
//        align-items: center
//        justify-content: center
//        padding: $paddingSmall 0
//        border-bottom: 1px solid $grey-300
//        position: relative
//        &:hover
//          background: darken($colorMenu, 3%)
//        div.holder
//          display: flex
//          align-items: center
//        h5
//          margin-right: $paddingSmall
//          text-transform: none
//          font-size: $fontSizeStandard-1
//          font-weight: 400
//        svg
//          width: 16px
//          height: 16px
//          fill: $colorTextMain
//          margin-right: $paddingSmall
//        .react-datepicker-popper
//          top: 100% !important
//          left: 50% !important
//          transform: unset !important
//          z-index: 3 !important
//          margin: 0 0 0 -121px !important
//        .react-datepicker
//          width: 242px !important
//        .react-datepicker__input-container
//          input
//            border: 0
//            padding: 0
//            width: 80px
//            cursor: pointer
//            font-size: $fontSizeStandard-1
