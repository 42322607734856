:root {
  /* // ----------- Material-ui palette -----------
// Commented out whats not currently used.
//$amber-50:    #fff8e1
//$amber-100:   #ffecb3
//$amber-200:   #ffe082
//$amber-300:   #ffd54f
//$amber-400:   #ffca28
//$amber-500:   #ffc107
//$amber-600:   #ffb300
//$amber-700:   #ffa000
//$amber-800:   #ff8f00
//$amber-900:   #ff6f00 */
  --amber-A100: #ffe57f;
  --amber-A200: #ffd740;
  --amber-A400: #ffc400;
  --amber-A700: #ffab00;

  /* //$blue-grey-50:   #eceff1; */
  /* //$blue-grey-100:  #cfd8dc; */
  /* //$blue-grey-200:  #b0bec5; */
  /* //$blue-grey-300:  #90a4ae; */
  /* //$blue-grey-400:  #78909c; */
  /* //$blue-grey-500:  #607d8b; */
  /* //$blue-grey-600:  #546e7a; */
  /* //$blue-grey-700:  #455a64; */
  /* //$blue-grey-800:  #37474f; */
  /* //$blue-grey-900:  #263238; */

  /* //$brown-50:   #efebe9; */
  /* //$brown-100:  #d7ccc8; */
  /* //$brown-200:  #bcaaa4; */
  /* //$brown-300:  #a1887f; */
  /* //$brown-400:  #8d6e63; */
  /* //$brown-500:  #795548; */
  /* //$brown-600:  #6d4c41; */
  /* //$brown-700:  #5d4037; */
  /* //$brown-800:  #4e342e; */
  /* //$brown-900:  #3e2723; */
  /*  */
  /* //$cyan-50:   #e0f7fa; */
  /* //$cyan-100:  #b2ebf2; */
  /* //$cyan-200:  #80deea; */
  /* //$cyan-300:  #4dd0e1; */
  /* //$cyan-400:  #26c6da; */
  /* //$cyan-500:  #00bcd4; */
  /* //$cyan-600:  #00acc1; */
  /* //$cyan-700:  #0097a7; */
  /* //$cyan-800:  #00838f; */
  /* //$cyan-900:  #006064; */
  /* //$cyan-A100: #84ffff; */
  /* //$cyan-A200: #18ffff; */
  /* //$cyan-A400: #00e5ff; */
  /* //$cyan-A700: #00b8d4; */

  /* //$deep-orange-50:    #fbe9e7; */
  /* //$deep-orange-100:   #ffccbc; */
  /* //$deep-orange-200:   #ffab91; */
  /* //$deep-orange-300:   #ff8a65; */
  /* //$deep-orange-400:   #ff7043; */
  /* //$deep-orange-500:   #ff5722; */
  --deep-orange-600: #f4511e;
  /* //$deep-orange-700:   #e64a19; */
  /* //$deep-orange-800:   #d84315; */
  /* //$deep-orange-900:   #bf360c; */
  /* //$deep-orange-A100:  #ff9e80; */
  /* //$deep-orange-A200:  #ff6e40; */
  /* //$deep-orange-A400:  #ff3d00; */
  /* //$deep-orange-A700:  #dd2c00; */

  /* //$deep-purple-50:    #ede7f6; */
  /* //$deep-purple-100:   #d1c4e9; */
  /* //$deep-purple-200:   #b39ddb; */
  /* //$deep-purple-300:   #9575cd; */
  /* //$deep-purple-400:   #7e57c2; */
  /* //$deep-purple-500:   #673ab7; */
  /* //$deep-purple-600:   #5e35b1; */
  /* //$deep-purple-700:   #512da8; */
  /* //$deep-purple-800:   #4527a0; */
  /* //$deep-purple-900:   #311b92; */
  /* //$deep-purple-A100:  #b388ff; */
  /* //$deep-purple-A200:  #7c4dff; */
  /* //$deep-purple-A400:  #651fff; */
  /* //$deep-purple-A700:  #6200ea; */

  /* //$green-50:    #e8f5e9; */
  /* //$green-100:   #c8e6c9; */
  /* //$green-200:   #a5d6a7; */
  /* //$green-300:   #81c784; */
  /* //$green-400:   #66bb6a; */
  /* //$green-500:   #4caf50; */
  /* //$green-600:   #43a047; */
  /* //$green-700:   #388e3c; */
  /* //$green-800:   #2e7d32; */
  /* //$green-900:   #1b5e20; */
  /* //$green-A100:  #b9f6ca; */
  /* //$green-A200:  #69f0ae; */
  /* //$green-A400:  #00e676; */
  /* //$green-A700:  #00c853; */

  /* // Original greys */
  --grey-50: #fafafa;
  /* //$grey-100:  #f5f5f5; */
  /* //$grey-200:  #eeeeee; */
  /* //$grey-300:  #e0e0e0; */
  /* //$grey-400:  #bdbdbd; */
  /* //$grey-500:  #9e9e9e; */
  /* //$grey-600:  #757575; */
  /* //$grey-700:  #616161; */
  /* //$grey-800:  #424242; */
  /* //$grey-900:  #212121; */

  /* //$indigo-50:   #e8eaf6; */
  /* //$indigo-100:  #c5cae9; */
  /* //$indigo-200:  #9fa8da; */
  /* //$indigo-300:  #7986cb; */
  /* //$indigo-400:  #5c6bc0; */
  /* //$indigo-500:  #3f51b5; */
  /* //$indigo-600:  #3949ab; */
  /* //$indigo-700:  #303f9f; */
  /* //$indigo-800:  #283593; */
  /* //$indigo-900:  #1a237e; */
  /* //$indigo-A100: #8c9eff; */
  /* //$indigo-A200: #536dfe; */
  /* //$indigo-A400: #3d5afe; */
  /* //$indigo-A700: #304ffe; */

  /* //$light-green-50:    #f1f8e9; */
  /* //$light-green-100:   #dcedc8; */
  /* //$light-green-200:   #c5e1a5; */
  /* //$light-green-300:   #aed581; */
  /* //$light-green-400:   #9ccc65; */
  /* //$light-green-500:   #8bc34a; */
  /* //$light-green-600:   #7cb342; */
  /* //$light-green-700:   #689f38; */
  /* //$light-green-800:   #558b2f; */
  /* //$light-green-900:   #33691e; */
  /* //$light-green-A100:  #ccff90; */
  /* //$light-green-A200:  #b2ff59; */
  /* //$light-green-A400:  #76ff03; */
  /* //$light-green-A700:  #64dd17; */

  /* //$lime-50:   #f9fbe7; */
  /* //$lime-100:  #f0f4c3; */
  /* //$lime-200:  #e6ee9c; */
  /* //$lime-300:  #dce775; */
  /* //$lime-400:  #d4e157; */
  /* //$lime-500:  #cddc39; */
  /* //$lime-600:  #c0ca33; */
  /* //$lime-700:  #afb42b; */
  /* //$lime-800:  #9e9d24; */
  /* //$lime-900:  #827717; */
  /* //$lime-A100: #f4ff81; */
  /* //$lime-A200: #eeff41; */
  /* //$lime-A400: #c6ff00; */
  /* //$lime-A700: #aeea00; */

  /* //$orange-50:   #fff3e0; */
  /* //$orange-100:  #ffe0b2; */
  /* //$orange-200:  #ffcc80; */
  /* //$orange-300:  #ffb74d; */
  /* //$orange-400:  #ffa726; */
  /* //$orange-500:  #ff9800; */
  /* //$orange-600:  #fb8c00; */
  /* //$orange-700:  #f57c00; */
  /* //$orange-800:  #ef6c00; */
  /* //$orange-900:  #e65100; */
  /* //$orange-A100: #ffd180; */
  /* //$orange-A200: #ffab40; */
  /* //$orange-A400: #ff9100; */
  /* //$orange-A700: #ff6d00; */

  /* //$pink-50:   #fce4ec; */
  /* //$pink-100:  #f8bbd0; */
  /* //$pink-200:  #f48fb1; */
  /* //$pink-300:  #f06292; */
  /* //$pink-400:  #ec407a; */
  /* //$pink-500:  #e91e63; */
  /* //$pink-600:  #d81b60; */
  /* //$pink-700:  #c2185b; */
  /* //$pink-800:  #ad1457; */
  /* //$pink-900:  #880e4f; */
  /* //$pink-A100: #ff80ab; */
  /* //$pink-A200: #ff4081; */
  /* //$pink-A400: #f50057; */
  /* //$pink-A700: #c51162; */

  /* //$purple-50:   #f3e5f5; */
  /* //$purple-100:  #e1bee7; */
  /* //$purple-200:  #ce93d8; */
  /* //$purple-300:  #ba68c8; */
  /* //$purple-400:  #ab47bc; */
  /* //$purple-500:  #9c27b0; */
  /* //$purple-600:  #8e24aa; */
  /* //$purple-700:  #7b1fa2; */
  /* //$purple-800:  #6a1b9a; */
  /* //$purple-900:  #4a148c; */
  /* //$purple-A100: #ea80fc; */
  /* //$purple-A200: #e040fb; */
  /* //$purple-A400: #d500f9; */
  /* //$purple-A700: #aa00ff; */

  /* //$red-50:    #ffebee; */
  /* //$red-100:   #ffcdd2; */
  /* //$red-200:   #ef9a9a; */
  /* //$red-300:   #e57373; */
  /* //$red-400:   #ef5350; */
  /* //$red-500:   #f44336; */
  /* //$red-600:   #e53935; */
  /* //$red-700:   #d32f2f; */
  /* //$red-800:   #c62828; */
  /* //$red-900:   #b71c1c; */
  /* //$red-A100:  #ff8a80; */
  /* //$red-A200:  #ff5252; */
  /* //$red-A400:  #ff1744; */
  /* //$red-A700:  #d50000; */

  /* //$teal-50:   #e0f2f1; */
  /* //$teal-100:  #b2dfdb; */
  /* //$teal-200:  #80cbc4; */
  /* //$teal-300:  #4db6ac; */
  /* //$teal-400:  #26a69a; */
  /* //$teal-500:  #009688; */
  /* //$teal-600:  #00897b; */
  /* //$teal-700:  #00796b; */
  /* //$teal-800:  #00695c; */
  /* //$teal-900:  #004d40; */
  /* //$teal-A100: #a7ffeb; */
  /* //$teal-A200: #64ffda; */
  /* //$teal-A400: #1de9b6; */
  /* //$teal-A700: #00bfa5; */

  /* // ----------- Site colors -----------
// (Most of these are generated with: https://hihayk.github.io/scale/) */

  /* // Site primary color */
  --colorPrimary-50: #d9d9ff;
  --colorPrimary-100: #bcbcff;
  --colorPrimary-200: #a2a2ff;
  --colorPrimary-300: #8b8bff;
  --colorPrimary-400: #7575f8;
  --colorPrimary-500: #6262f0;
  /* // If this changes, also change theme color in: index.html, manifest.json, favicons, header_graphic.svg and Helpcrunch chat icon. */
  --colorPrimary-600: #5050cf;
  --colorPrimary-700: #4040ad;
  --colorPrimary-800: #30308a;
  --colorPrimary-900: #27275e;
  /* // If this changes, change arrow svg-files in home_page/flow_graphic/ as well. */

  /* // Site secondary color */
  --colorSecondary-50: #fff9e0;
  --colorSecondary-100: #ffefb3;
  --colorSecondary-200: #ffe482;
  --colorSecondary-300: #ffdc4f;
  --colorSecondary-400: #ffd129;
  --colorSecondary-500: #ffc908;
  --colorSecondary-600: #ffbb00;
  --colorSecondary-700: #ffaa00;
  --colorSecondary-800: #ff9900;
  --colorSecondary-900: #ff8000;
  --colorSecondary-A100: var(--amber-A100);
  --colorSecondary-A200: var(--amber-A200);
  --colorSecondary-A400: var(--amber-A400);
  --colorSecondary-A700: var(--amber-A700);

  /* // Site tertiary color */
  /* //$colorTertiary-50: #fbf9f9 // If this changes, also change header_graphic.svg. */
  --colorTertiary-50: #fcf7f5; /*// If this changes, also change header_graphic.svg.*/
--colorTertiary-100: #fcf6f0;
--colorTertiary-300: #e6c4ae;

/* // Greys (with touch of blue) */
  /* //$grey-50: #f7f7ff */
  /* //$grey-100: #f0f0fb */
  /* //$grey-100-00: #f0f0fb00 // With alpha value */
  /* //$grey-200: #e6e6f6 */
  /* //$grey-300: #d8d5e6 */
  /* //$grey-400: #bfbdd0 */
  /* //$grey-500: #a09eb1 */
  /* //$grey-600: #77758a */
  /* //$grey-700: #636174 */
  /* //$grey-800: #444254 */
  /* //$grey-900: #212037 */
  --grey-50: #f6f6ff;
  --grey-100: #ededfd;
  --grey-100-00: #ededfd00;
  --grey-200: #e1e1fa;
  --grey-300: #d6d3e8;
  --grey-400: #bab8d0;
  --grey-500: #9b99b1;
  --grey-600: #72708a;
  --grey-700: #5e5c74;
  --grey-800: #3f3d54;
  --grey-900: #1e1e37;

  /* // Text */
  --colorTextMain: var(--grey-800);
  --colorTextDark: var(--grey-900);
  --colorTextLight: var(--grey-600);
  --colorTextLight-1: var(--grey-500);
  --colorTextLight-2: var(--grey-400);
  --colorTextLight-3: var(--grey-300);

  /* // Navigation */
  /* --colorNavigation: hsl(225, 31%, 21%);*/
  /* --colorNavigationDark: darken(var(--colorNavigation), 6%); */
  /* --colorNavigationLight: lighten(var(--colorNavigation), 50%); */
  /* --colorNavigationLighter: lighten(var(--colorNavigation), 54%); */
  --colorNavigationHeader: #2a3144;
  --colorNavigationBackground: #141827;
  --colorNavigation: #273049;
  --colorNavigationDark: #1f2639;
  --colorNavigationDarker: #151b2e;
  --colorNavigationLight: #bac4e2;
  --colorNavigationLighter: #abb5d3;

  /* // Chart colors */
  --colorChartBlue: rgba(110, 176, 235, 1);
  --colorChartBlueDark: rgba(112, 128, 219, 1);
  --colorChartGreen: rgba(87, 201, 196, 1);
  --colorChartLime: rgba(199, 227, 75, 1);
  --colorChartOrange: rgba(255, 138, 101, 1);
  --colorChartPurple: rgba(149, 125, 212, 1);
  --colorChartPurpleDark: rgba(125, 92, 191, 1);
  --colorChartTurquoise: rgba(95, 204, 232, 1);
  --colorChartYellow: rgba(255, 213, 79, 1);
  --colorChartYellowDark: rgba(255, 191, 71, 1);

  /* // These are for exporting. */
  --colorChart0: var(--colorChartYellow);
  --colorChart1: var(--colorChartYellowDark);
  --colorChart2: var(--colorChartLime);
  --colorChart3: var(--colorChartGreen);
  --colorChart4: var(--colorChartTurquoise);
  --colorChart5: var(--colorChartBlue);
  --colorChart6: var(--colorChartBlueDark);
  --colorChart7: var(--colorChartPurple);
  --colorChart8: var(--colorChartPurpleDark);
  --colorChart9: var(--colorChartOrange);

  /* // Page header */
  --colorHeaderBackground: var(--colorPrimary-500);
  /* //--colorHeaderBackgroundDark: var(--colorPrimary-700); */
  --colorHeaderBackgroundDark: var(--colorNavigation);
  --colorHeaderPrimary: white;
  --colorHeaderSuplementary: rgba(255, 255, 255, 0.8);

  /* // Links */
  --colorLink: var(--colorPrimary-500);
  --colorLinkHover: var(--colorNavigation);
  --colorLinkSecondary: var(--colorChartTurquoise);

  /* // Informatory colors */
  --colorInformatory: var(--colorSecondary-500);
  --colorInfoHighlightBorder: rgba(255, 201, 8, 0.9);
  /* // RGB correlates to $colorSecondary-500 */
  --colorInfoHighlightBackground: rgba(255, 201, 8, 0.14);
  /* // Same as rgba(255, 201, 8, 0.14) */
  --colorInfoHighlightText: #3d361d;

  /* // Events */
  --colorFail: rgba(255, 109, 51, 1);
  --colorFailOpacity: rgba(255, 109, 51, 0.9);
  --colorSuccess: rgba(121, 209, 111, 1);
  --colorSuccessOpacity: rgba(121, 209, 111, 0.86);
  --colorEvent: rgba(255, 201, 8, 1);
  /* // RGB same as $colorSecondary-500 */
  --colorEventOpacity: rgba(255, 201, 8, 0.9);
  --colorEventPassedDate: var(--colorFail);
  --colorEventPassedDateOpacity: var(--colorFailOpacity);
  --colorEventWithinOneDay: var(--colorSuccess);
  --colorEventWithinOneDayOpacity: var(--colorSuccessOpacity);

  /* // Tooltip */
  --colorTooltip: var(--colorNavigation);

  /* // Home */
  --homeMainColor: var(--colorSecondary-500);
  --homeMainColorDark: var(--colorSecondary-600);
  --homeMainColorDarker: var(--colorSecondary-700);
  --homeSecondaryBackground: #f6f5f5;
}
