.mailingsParamsWrapper
  &__mailingsParams
    &__content
      //padding: $paddingMedium
      //background: $grey-100
      border-radius: 4px
      color: $colorTextDark
      .highlightWrapper
        margin-top: $paddingMedium
        &:first-child
          margin-top: 0
      p.parameterInfo
        margin-top: 0
