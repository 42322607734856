.container {
  .stepInstruction {
    user-select: text;
    margin-bottom: 12px;
    margin-top: 6px;
  }
  .stepWarning {
    user-select: text;
    margin-bottom: 16px;
  }

  button {
    padding: 4px 8px;
    cursor: pointer;
    width: 100%;
  }

  label {
    min-width: 200px;
  }

  .radioSelector,
  .checkbox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 4px;
    padding: 4px;
    span {
      min-width: 100px;
      justify-self: start;
    }
    &.disabled {
      background-color: var(--grey-200);
      cursor: not-allowed;
      &:hover {
        background-color: var(--grey-300);
      }
    }
    &:hover:not(.disabled) {
      cursor: pointer;
      background-color: var(--colorPrimary-100);
    }
  }
}
