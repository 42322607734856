.container {
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 2px;
}
