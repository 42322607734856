.newsLabel
  font-weight: 700
  font-size: $fontSizeStandard-1
  line-height: $fontSizeStandard-1
  color: $colorTextMain
  text-transform: uppercase
  letter-spacing: 0.3px

.newsWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__news
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__content
      &__bottom
        margin: calc(#{$paddingBig} * 2)
        display: flex
        align-items: flex-start
        gap: calc(#{$paddingBig} * 2)
        &__left
          width: 56%
          &__displayNewsItem
            &__headline
              font-size: $fontSizeStandard11
              line-height: $fontSizeStandard11
              font-weight: 700
              margin: 0
              color: $colorTextDark
            &__dateAndNewsType
              margin-top: $paddingBig
              &__date
                @extend .newsLabel
                display: flex
                justify-content: space-between
                width: 100%
                &__read
                  @extend .newsLabel
                  margin-left: $paddingBig
                  display: flex
                  align-items: center
                  svg
                    width: 18px
                    height: 18px
                    margin-right: $paddingSmall
              &__newsType
                margin-top: 1px
                display: flex
                justify-content: flex-start
                gap: 4px $paddingSmall
                flex-wrap: wrap
                span.tag
                  @extend .newsTypeTag
                  cursor: pointer
                  &:hover, &.active
                    border-color: $colorLinkHover
                    color: $colorLinkHover
            &__ingress
              margin-top: calc(#{$paddingBig} * 1.5)
              font-size: $fontSizeStandard5
              line-height: $fontSizeStandard7
              font-weight: 300
            &__author
              border-top: 1px solid $grey-300
              border-bottom: 1px solid $grey-300
              padding: calc(#{$paddingBig} * 1.5) 0
              margin: calc(#{$paddingBig} * 1.5) 0
              display: flex
              justify-content: flex-start
              align-items: center
              gap: $paddingBig
              &__name
                display: flex
                flex-direction: column
                font-size: $fontSizeStandard4
                line-height: $fontSizeStandard5
                font-weight: 600
                color: $colorTextDark
                span.email
                  font-size: $fontSizeStandard
                  line-height: $fontSizeStandard2
                  font-weight: 400
                  color: $colorTextLight
                  margin-top: 1px
            &__content
              @extend .adjustReactQuill
              margin-top: $paddingBig
            &__img
              margin-top: $paddingBig
              max-height: 500px
              overflow: hidden
              display: flex
              align-items: center
              img
                width: 100%
                object-fit: cover
                &.default
                  padding: $paddingBig calc(#{$paddingBig} * 7)
                  max-width: 760px
            &__footer
              display: flex
              justify-content: space-between
              flex-direction: row-reverse
              align-items: center
              margin-top: $paddingBig
              &__draft
                color: $colorFail
                display: inline-flex
                font-weight: 600
              &__editButtons
                display: inline-flex
                .actionButtonWrapper
                  margin-left: $paddingMedium
        &__right
          width: 44%
          &__thumbs
            background: white
            padding: 0 $paddingSmall
            &__label
              @extend .newsLabel
              margin-bottom: $paddingSmall
              border-bottom: 3px solid $grey-200
              padding-bottom: 4px
            &__items
              display: grid
              grid-gap: $paddingSmall
              grid-auto-flow: column
              grid-auto-columns: calc(50% - (#{$paddingSmall} / 2))
              grid-template-rows: calc(60% - #{$paddingSmall}) 40%
              max-height: 440px
              &__item
                cursor: pointer
                height: 100%
                overflow: hidden
                &:nth-child(1)
                  grid-column-start: 1
                  grid-column-end: 3
                &__img
                  position: relative
                  height: 100%
                  display: flex
                  align-items: center
                  justify-content: center
                  &:hover
                    .newsWrapper__news__content__bottom__right__thumbs__items__item__img__box
                      background: rgba(255, 255, 255, 0.9)
                    &:before
                      content: ""
                      position: absolute
                      top: 0
                      right: 0
                      bottom: 0
                      left: 0
                      background: rgba(0, 0, 0, 0.1)
                  img
                    width: 100%
                    height: 100%
                    object-fit: cover
                    &.default
                      object-fit: scale-down
                      max-width: 300px
                  &__box
                    background: rgba(255, 255, 255, 0.82)
                    padding: $paddingBig
                    position: absolute
                    bottom: 0
                    left: 0
                    right: 0
                    &__headline
                      color: $colorTextDark
                      font-size: $fontSizeStandard2
                      line-height: $fontSizeStandard3
                      font-weight: 600
                    &__date
                      color: $colorTextMain
                      font-size: $fontSizeStandard-1
                      line-height: $fontSizeStandard-1
                      margin-bottom: 4px
          &__archive
            margin: calc(#{$paddingBig} * 2) $paddingMedium 0 $paddingMedium
            &__label
              @extend .newsLabel
              margin-bottom: $paddingMedium
              border-bottom: 3px solid $grey-200
              padding-bottom: 4px
            &__items
              overflow: auto
              @extend .scrollbarRegular
              padding-right: 0
              max-height: 500px
              &__item
                background: white
                margin-top: $paddingMedium
                &:first-child
                  margin-top: 0
                &__row
                  white-space: nowrap
                  cursor: pointer
                  font-size: $fontSizeStandard-1
                  &:hover
                    color: $colorLink
                    .newsWrapper__news__content__bottom__right__archive__items__item__row__date
                      color: $colorLink
                  &__headline
                    font-weight: 600
                    font-size: $fontSizeStandard2
                  &__date
                    font-size: $fontSizeStandard1
                    line-height: $fontSizeStandard1
                    color: $colorTextLight
