.dealWrapper
  &__deal
    &__content
      background: white
      //border-left: 1px solid $grey-300
      &__item
        border-bottom: 1px solid $grey-300
        padding: $paddingBig
        &:last-of-type
          border-bottom: 0

// DealMain component
.dealMainWrapper
  &__dealMain
    &__content
      display: flex
      flex-wrap: wrap
      margin-top: $paddingBig
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &.editDeal
        .dealMainWrapper__dealMain__content__item
          background: white
      &__item
        margin: 2px 2px 0 0
        width: calc(25% - ((3 * 2px) / 4))
        padding: $paddingMedium
        word-break: break-word
        background: $grey-100
        &:nth-child(4n)
          margin-right: 0
        &__label
          font-weight: 600
        input
          min-height: 36px

// DealProspects component.
.dealProspectsWrapper
  &__dealProspects
    &__content
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin-top: $paddingBig
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__prospects
        display: flex
        flex-direction: column
        flex-grow: 1
        &__item
          display: flex
          width: 100%
          padding: $paddingMedium
          &:nth-child(even)
            background: $grey-50
          &:hover
            .dealProspectsWrapper__dealProspects__content__prospects__item__icon__visible
              display: none
            .dealProspectsWrapper__dealProspects__content__prospects__item__icon__hidden
              display: flex
          svg
            cursor: pointer
            width: 16px
            height: 16px
          &__icon
            padding: 0 ($paddingMedium * 1.5) 0 0
            display: flex
            align-items: center
            justify-content: center
            min-height: 32px
            &__visible
              svg
                fill: $colorTextLight
            &__hidden
              display: none
              svg
                fill: $colorLink
              &:hover
                svg
                  fill: $colorLinkHover
          a
            display: flex
            align-items: center
            justify-content: space-between
            color: $colorTextLight-1
            flex-shrink: 1
            flex-basis: calc(100% - (18px + (#{$paddingMedium} * 1.5) ) )
            &:hover
              .dealProspectsWrapper__dealProspects__content__prospects__item__linkHolder
                svg
                  fill: $colorLink
          &__infoHolder
            word-break: break-word
            flex-basis: calc(100% - (18px + (#{$paddingMedium} * 1.5) ) )
            min-width: 0
            &__info
              &__name
                color: $colorTextMain
                font-weight: 600
          &__linkHolder
            padding: 0 0 0 ($paddingMedium * 1.5)
            border-radius: 0 4px 4px 0
            display: flex
            align-items: center
            justify-content: center
            min-width: 0
            svg
              fill: $colorTextLight
        &__itemNoRemove
          @extend .dealCarsWrapper__dealCars__content__cars__item
          &:hover // Same as item, but no remove icon for this class
            .dealProspectsWrapper__dealProspects__content__prospects__item__icon__visible
              display: inline
            .dealProspectsWrapper__dealProspects__content__prospects__item__icon__hidden
              display: none
      &__search
        width: calc(33% - #{$paddingBig})
        flex-grow: 0
        flex-shrink: 0
        min-width: 0
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-right: $paddingBig
        &__top
          color: $colorTextLight
          display: flex
          justify-content: flex-start
          align-items: center
          width: 100%
          span
            color: $colorTextMain
            font-weight: 600
        &__bottom
          width: 100%

// DealCars component
.dealCarsWrapper
  &__dealCars
    &__content
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin-top: $paddingBig
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__cars
        display: flex
        flex-direction: column
        flex-grow: 1
        &__item
          display: flex
          flex-basis: 100%
          padding: $paddingMedium
          &:nth-child(even)
            background: $grey-50
          &:hover
            .dealCarsWrapper__dealCars__content__cars__item__icon__visible
              display: none
            .dealCarsWrapper__dealCars__content__cars__item__icon__hidden
              display: flex
          svg
            cursor: pointer
            width: 16px
            height: 16px
          &__icon
            padding: 0 ($paddingMedium * 1.5) 0 0
            display: flex
            align-items: center
            justify-content: center
            min-height: 32px
            flex-basis: calc (18px + ($paddingMedium * 1.5))
            &__visible
              svg
                fill: $colorTextLight
            &__hidden
              display: none
              svg
                fill: $colorLink
              &:hover
                svg
                  fill: $colorLinkHover
          a
            display: flex
            align-items: center
            justify-content: space-between
            color: $colorTextLight-1
            flex-shrink: 1
            flex-basis: calc(100% - (18px + (#{$paddingMedium} * 1.5) ) )
            &:hover
              .dealCarsWrapper__dealCars__content__cars__item__linkHolder
                svg
                  fill: $colorLink
          &__infoHolder
            word-break: break-word
            flex-basis: calc(100% - (18px + (#{$paddingMedium} * 1.5) ) )
            min-width: 0
            &__regNum
              color: $colorTextMain
              font-weight: 600
          &__linkHolder
            padding: 0 0 0 ($paddingMedium * 1.5)
            border-radius: 0 4px 4px 0
            display: flex
            align-items: center
            justify-content: center
            min-width: 0
            svg
              fill: $colorTextLight
        &__itemNoRemove
          @extend .dealCarsWrapper__dealCars__content__cars__item
          &:hover // Same as item, but no remove icon for this class
            .dealCarsWrapper__dealCars__content__cars__item__icon__visible
              display: inline
            .dealCarsWrapper__dealCars__content__cars__item__icon__hidden
              display: none
      &__search
        width: calc(33% - #{$paddingBig})
        flex-grow: 0
        flex-shrink: 0
        min-width: 0
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-right: $paddingBig
        &__top
          color: $colorTextLight
          display: flex
          justify-content: flex-start
          align-items: center
          width: 100%
          span
            color: $colorTextMain
            font-weight: 600
        &__bottom
          width: 100%

// DealFiles component
.dealFilesWrapper
  &__dealFiles
    &__content
      margin-top: $paddingBig
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &__files
        display: flex
        justify-content: flex-start
        flex-wrap: wrap
        &__file
          margin-right: $paddingSmall
          margin-top: $paddingSmall
          width: calc(33.33% - ((#{$paddingSmall} * 2) / 3))
          &:nth-child(3n)
            margin-right: 0
          &:nth-child(1), &:nth-child(2), &:nth-child(3)
            margin-top: 0
