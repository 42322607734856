.newLine
    min-height: 1rem

.scriptWrapper
    padding: $paddingBig
    &__read
        &__top
            display: flex
            gap: 10px
            align-items: center
            padding-bottom: $paddingBig
            svg
                cursor: pointer
        &__bottom
            &__textFields
                &__title
                    font-size: 1.2rem
                    border-bottom: 1px solid #cecece
                    margin-bottom: $paddingSmall
                &__text
                    white-space: pre-line
    &__edit
        display: flex
        flex-direction: column
        gap: $paddingBig
        &__textFields
            display: flex
            flex-direction: column
            gap: $paddingBig
            label
                font-size: $fontSizeStandard4
            input, textarea
                font-size: $fontSizeStandard1
            input, textarea
                background-color: $grey-50
            textarea
                min-height: 180px
        &__buttons
            display: flex
            gap: $paddingMedium
            align-items: center
@media (min-width: 768px)
    .showPreviewOnLargeScreens
        margin-right: 380px
