.version_error_container {
  margin: auto;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  gap: 12px;
  text-align: center;
  position: relative;
}

.version_error_close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.version_error_img {
  margin-bottom: 10vh;
}

@media (max-width: 768px) {
  .version_error_container {
    font-size: 16px;
  }

  button:not(.version_error_close) {
    min-height: 40px;
    min-width: 140px;
  }
}
