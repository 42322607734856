.eventsWrapper
  width: 100%
  height: 100%
  overflow: hidden
  &__events
    height: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    &__content
      margin-top: $paddingBig
      height: 100%
      display: flex
      flex-direction: column
      flex-grow: 1
      overflow: hidden
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &.eventsResponsiveWidth1
        .eventsWrapper__events__content__top
          margin-bottom: $paddingMedium
          &__right
            width: 100%
            min-width: 100%
            &__selectActivities
              width: 100%
              min-width: 100%
      &__top
        margin-bottom: calc(#{$paddingBig} * 1.5)
        display: flex
        justify-content: space-between
        align-items: flex-start
        width: 100%
        &__right
          width: 100%
          display: flex
          justify-content: flex-end
          align-items: flex-end
          &__selectActivities
            min-width: 240px
      &__middle
        flex-grow: 1
        overflow: hidden // Important for responsive solution

.eventsCalendarWrapper
  min-width: 250px
  height: 100%
  overflow: auto
  @extend .scrollbarRegular
  &.eventsCalendarResponsiveWidth1
    .eventsCalendarDayWrapper
      &__eventsCalendarDay
        &__date
          font-size: $fontSizeStandard1
          padding: 28% 0 6% 0
        &__notification
          font-size: $fontSizeStandard-4
          line-height: $fontSizeStandard-4
          height: $fontSizeStandard-4
  &.eventsCalendarResponsiveWidth2
    .eventsCalendarDayWrapper
      &__eventsCalendarDay
        &__date
          font-size: $fontSizeStandard
  &.eventsCalendarResponsiveWidth3
    .eventsCalendarWrapper__eventsCalendar
      &__content
        &__title
          font-size: $fontSizeStandard-2
        &__days
          margin-top: $paddingBig
    .eventsCalendarDayWrapper
      &.empty
        &.weekday
          display: none
      &__eventsCalendarDay
        &:before
          width: 4px !important
          height: 4px !important
          top: 9px !important
          right: 4px !important
        &__date
          padding: 14% 0 18% 0
          font-size: $fontSizeStandard-2
        &__notification
          display: none
  &__eventsCalendar
    &__content
      &__title
        margin-bottom: $paddingMedium
        display: flex
        justify-content: center
        align-items: center
        color: $colorTextMain
        font-size: $fontSizeStandard-1
        font-weight: 600
        text-transform: uppercase
        letter-spacing: 0.2px
        svg
          display: flex
          justify-content: center
          align-items: center
          border: 1px solid $colorLink
          padding: 1px
          border-radius: 50%
          width: 18px
          height: 18px
          fill: $colorLink
          margin: 0 $paddingMedium
          cursor: pointer
          &:hover
            border-color: $colorLinkHover
            fill: $colorLinkHover
      &__days
        display: flex
        flex-wrap: wrap


.eventsCalendarDayWrapper
  width: 13.42%
  margin-right: 1%
  margin-bottom: 1%
  &:nth-child(7n)
    margin-right: 0
  &.hasEvent
    .eventsCalendarDayWrapper
      &__eventsCalendarDay
        cursor: pointer
        border-color: $colorLink
        &:hover
          border-color: $colorLinkHover
          .eventsCalendarDayWrapper__eventsCalendarDay__top
            background: $colorLinkHover
            border-color: $colorLinkHover
          .eventsCalendarDayWrapper__eventsCalendarDay__date
            color: $colorLinkHover
        &__top
          background: $colorLink
          border-color: $colorLink
        &__date
          color: $colorLink
  &.passed
    .eventsCalendarDayWrapper
      &__eventsCalendarDay
        opacity: 0.6
        &__date
          opacity: 0.6
    &.hasEvent
      .eventsCalendarDayWrapper
        &__eventsCalendarDay
          opacity: 0.4
          &__date
            opacity: 1
  &.empty
    .eventsCalendarDayWrapper
      &__eventsCalendarDay
        content: ''
        background: transparent
        border-color: transparent
        padding: 1% 0 0 0
        &__date
          font-size: $fontSizeStandard-4
          font-weight: 400
          color: $colorTextLight
          text-transform: uppercase
          padding: 0
  &.today
    .eventsCalendarDayWrapper
      &__eventsCalendarDay
        position: relative
        &:before
          content: ""
          position: absolute
          top: 11px
          right: 6px
          width: 5px
          height: 5px
          clip-path: circle()
          background: $colorChartGreen
  &__eventsCalendarDay
    border: 1px solid $grey-300
    border-radius: 4px
    overflow: hidden
    &__top
      height: 6px
      width: 100%
      border-bottom: 1px solid $grey-300
      background: $grey-200
    &__date
      background: white
      padding: 30% 0 12% 0
      font-size: $fontSizeStandard3
      font-weight: 600
      flex-grow: 0
      color: $grey-700
      display: flex
      align-items: center
      justify-content: center
    &__notification
      font-size: $fontSizeStandard-3
      line-height: $fontSizeStandard-3
      margin: 0 4px 4px 8px
      height: $fontSizeStandard-3
      font-weight: 600
      color: $colorTextLight
    &__eventsWrapper
      &__content
        margin-top: $paddingBig

.eventsFlowWrapper
  height: 100%
  overflow: hidden // Important for responsive solution
  &__eventsFlow
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden // Important for responsive solution
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      overflow: hidden // Important for responsive solution
      &__top
        overflow: hidden // Important for responsive solution
        position: relative
        flex-grow: 1
        display: flex
        flex-direction: column
        &__items
          flex-grow: 1
          overflow: auto
          @extend .scrollbarRegular
        &__itemsHidden
          position: absolute
          top: 0
          z-index: -1
          max-height: 10px
          overflow: scroll
          @extend .scrollbarHidden
      &__bottom
        margin: $paddingMedium 0
        display: flex
        justify-content: center
        .actionButtonWrapper
          margin-right: $paddingBig
          &:last-of-type
            margin-right: 0

.eventsFlowItemWrapper
  position: relative
  white-space: normal
  border-bottom: 1px solid $grey-300
  padding: $paddingBig $paddingMedium
  &:last-child
    border-bottom: 0
  &:hover
    .eventsFlowItemWrapper__eventsFlowItem__right__right
      display: flex
  &.eventsFlowItemResponsiveWidth1
    padding: $paddingBig 0
    .eventsFlowItemWrapper__eventsFlowItem
      &__left
        display: none
      &__right
        flex-direction: column
        align-items: flex-start
        &__left
          &__action
            & > div
              font-size: $fontSizeStandard2
              line-height: $fontSizeStandard2
              word-break: break-all
            a
              word-break: break-all
        &__right
          margin-top: $paddingSmall
          display: flex
          &__edit
            justify-content: flex-start
  &__eventsFlowItem
    width: 100%
    word-break: break-word
    display: flex
    span.highlight
      font-weight: 600
    span.marginRight
      margin-right: 6px
    &__left
      display: flex
      align-items: center
      margin-right: $paddingBig
    &__right
      flex-grow: 1
      display: flex
      align-items: center
      flex-wrap: wrap
      gap: $paddingMedium
      &__left
        flex-direction: column
        justify-content: center
        flex-grow: 1
        &__action
          & > div
            font-size: $fontSizeStandard3
            line-height: $fontSizeStandard4
            font-weight: 600
        &__comment
          display: flex
          align-items: center
          flex-wrap: wrap
          color: $colorTextLight
          span.comment
            color: $colorTextMain
            font-weight: 600
        &__footer
          display: flex
          align-items: center
          justify-content: flex-start
          flex-wrap: wrap
          color: $colorTextLight
          margin-top: 4px
          &__date
            margin-right: 6px
          &__user
            word-break: break-word
      &__right
        display: none
        align-items: center
        &__edit
          min-width: 60px
          display: flex
          align-items: center
          justify-content: flex-end
          gap: $paddingMedium
          flex-wrap: wrap
.eventsFlowItemPopupWrapper
  &__eventsFlowItemPopup
    &__content
      margin: $paddingBig 0
