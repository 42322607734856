    .analyse {
      width: 47.5%;
      padding: 1em;
      box-shadow: 0 0 15px 0 rgba(70, 110, 211, 0.2) inset;
      margin: 1em;
      min-width: 300px;
      border-radius: 8px;
    }

    .analyse>h4 {
      margin-bottom: 1em;
    }

    .tooltip {
      background-color: black;
      color: white;
      padding: 1em;
    }

    @media (max-width: 1414px) {
      .analyse {
        width: 100%
      }
    }
