.itemWithButtonsWrapper
  &__itemWithButtons
    display: flex
    justify-content: flex-start
    align-items: stretch
    overflow: hidden
    padding: $paddingSmall $paddingMedium
    border-bottom: 1px solid $grey-300
    &.selectable
      cursor: pointer
      &:hover
        color: $colorLink
        .itemWithButtonsWrapper__itemWithButtons__right__name
          color: $colorLink
        .itemWithButtonsWrapper__itemWithButtons__left__icon__content
          svg
            fill: $colorLink
      &.selectDisabled
        cursor: default
        &:hover
          border-color: transparent
        .itemWithButtonsWrapper__itemWithButtons__left__icon__content
          svg
            fill: $colorTextLight-1
            cursor: default
        .itemWithButtonsWrapper__itemWithButtons__right__name
          color: $colorTextLight-1
    .tooltipWrapper__tooltip__indicator
      display: flex
      align-items: center
      justify-content: center
    svg
      margin: 0 !important
    &__left
      padding-right: $paddingMedium
      &.selected
        .itemWithButtonsWrapper__itemWithButtons__left__icon__content
          svg
            fill: $colorTextMain
      &__icon
        width: 100%
        height: 100%
        display: flex
        align-items: center
        &__content
          display: flex
          align-items: center
          justify-content: center
          svg
            width: 16px
            height: 16px
            fill: $colorLink
    &__right
      display: flex
      justify-content: space-between
      align-items: center
      flex-grow: 1
      overflow: hidden
      &__name
        display: flex
        align-items: center
        justify-content: flex-start
        flex-grow: 1
        font-size: $fontSizeStandard
        //line-height: $fontSizeStandard3
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        color: $colorTextMain
        font-weight: 600
        padding: 0
        span.itemLabel
          font-weight: 400
          color: $colorTextLight
          font-size: $fontSizeStandard-1
          margin-right: $paddingSmall
          white-space: nowrap
        svg.warning
          width: 18px
          height: 18px
          cursor: default
          fill: $colorFail
          margin: 0 $paddingSmall 0 0 !important
          &:hover
            fill: $colorFail
      &__buttons
        display: flex
        align-items: center
        justify-content: flex-end
        margin-left: $paddingMedium
        gap: $paddingSmall
        .tooltipWrapper__tooltip__indicator
          display: flex
          justify-content: center
          align-items: center
