$labelWidth: 60px
.prospectGroupsWrapper
  &__prospectGroups
    &__content
      display: flex
      justify-content: space-between
      align-items: flex-start
      gap: $paddingBig
      &__left
        min-width: calc(50% - #{$paddingBig})
        width: 100%
        svg
          cursor: pointer
          fill: $colorLink
          &:hover
            fill: $colorLinkHover
        &__formHolder
          padding-top: 3px
          span.label
            width: $labelWidth
            margin-top: $paddingSmall
            margin-right: $paddingMedium
            word-break: break-all
          &__content
            margin-top: 1px
            margin-bottom: $paddingBig
            position: relative
            input
              padding: $paddingSmall
              width: calc(100% - #{$labelWidth} - #{$paddingMedium})
            .infoWrapper, .infoInlineWrapper
              margin-top: $paddingSmall !important
              margin-left: calc(#{$labelWidth} + #{$paddingMedium}) !important
              width: calc(100% - #{$labelWidth} - #{$paddingMedium}) !important
              a
                margin-left: 4px
            &__name
              display: flex
              margin-bottom: $paddingSmall
            &__searchWrapper
              display: flex
              position: relative
              &__searchHolder
                width: calc(100% - #{$labelWidth} - #{$paddingMedium})
                input
                  width: 100%
                &.active
                  @extend .shadowRegular
                  top: 0
                  left: calc(#{$labelWidth} + #{$paddingMedium})
                  position: absolute
                  z-index: 2
                  background: white
                  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
                &__suggestionsHolder
                  @extend .scrollbarRegular
                  z-index: 2
                  margin-top: $paddingBig
                  max-width: 320px
                  min-width: 100%
                  max-height: 220px
                  overflow-y: auto
                  &__itemWrapper
                    padding: $paddingMedium
                    border-bottom: 1px solid $grey-300
                    &:last-of-type
                      border-bottom: 0
                    &__item
                      cursor: pointer
                      display: flex
                      align-items: center
                      justify-content: space-between
                      &:first-of-type
                        margin-top: 0
                      &:hover
                        color: $colorLink
                      &__left
                        flex-grow: 1
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                        strong
                          font-weight: 600
                      &__right
                        margin-left: $paddingMedium
                        svg
                          width: 14px
                          height: 14px
            &__fill
              height: 41px
            &__chipsHolder
              display: flex
              margin-top: $paddingSmall
              span.label
                margin-top: $paddingMedium
              &.fill
                margin-top: 40px
              &__chips
                display: flex
                margin-top: $paddingSmall
                width: calc(100% - #{$labelWidth} - #{$paddingMedium})
                flex-wrap: wrap
                gap: $paddingSmall
                &__chip
                  @extend .chip
                  max-width: calc(50% - #{$paddingSmall})
                  &__left
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                  &__right
                    width: calc(14px + #{$paddingSmall})
                    display: flex
                    align-items: center
            &__values
              display: flex
            &__invalid
              margin-left: calc(#{$labelWidth} + #{$paddingMedium})
              margin-top: $paddingMedium
              word-break: break-word
              &__info
                display: flex
                align-items: center
                font-weight: 600
                svg
                  fill: $colorFail
                  margin-right: $paddingSmall
                  width: 14px
                  height: 14px
              &__values
                color: $colorTextLight
          &__footer
            //margin-bottom: $paddingSmall
            margin-left: calc(#{$labelWidth} + #{$paddingMedium})
            display: flex
            justify-content: center
            //padding: 0 10%
            align-items: center
            gap: $paddingBig
      &__right
        width: 100%
        min-width: 50%
        display: flex
        flex-wrap: wrap
        //flex-shrink: 1
        gap: $paddingSmall
        overflow: hidden
        padding-bottom: 2px // We need overflow hidden, so add padding to display box shadow.
        &__row
          display: block
          width: 100%
        &__group
          width: calc(50% - (#{$paddingSmall} / 2))
          &.editMode
            width: 100%
