.saveToListWrapper
  &__saveToList
    &__content
      margin-top: $paddingBig
      &__menu
        margin-bottom: $paddingBig
      &__text
        margin-bottom: $paddingBig
      &__newList
        display: flex
        align-items: flex-end
        width: 100%
        margin-top: $paddingBig
        margin-bottom: $paddingMedium
        p
          margin-right: $paddingMedium
          white-space: nowrap
        input
          width: 40%
