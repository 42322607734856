$fontHomeSerif: 'Source Serif Pro', Georgia, serif

.headingH2
	text-transform: none
	color: $colorTextDark
	font-weight: 700
	font-size: $fontSizeStandard10
	line-height: 0.94em
	letter-spacing: 0.8px
	&.subHeading
		font-size: $fontSizeStandard8
		line-height: 0.88em
	& + h4
		margin-top: -4px

.headingH3
	text-transform: none
	font-size: $fontSizeStandard6
	line-height: 1em
	color: $colorTextDark
	font-weight: 700
	letter-spacing: 0.6px

.headingH4
	text-transform: none
	font-weight: 300
	color: $colorTextMain
	font-size: $fontSizeStandard6
	line-height: 1.32em
	letter-spacing: 0.4px

.headingH5
	color: $colorTextDark
	text-transform: none
	font-size: $fontSizeStandard3
	line-height: 1.1em

.headingH6
	color: $colorTextDark
	text-transform: none
	font-size: $fontSizeStandard3
	line-height: 1.1em
	font-weight: 600

.popupWrapper
	.widgetHeaderWrapper
		&__widgetHeader
			h2
				@extend .headingH3
			&__right
				&__dashboard
					&__button
						svg
							fill: white

.homeWrapper
	::selection
		background: $homeMainColor
		color: $colorTextDark
	&__home
		height: 100vh
		width: 100vw
		overflow-y: auto
		overflow-x: hidden
		scroll-behavior: smooth
		position: relative
		h2
			@extend .headingH2
		h3
			@extend .headingH3
		h4
			@extend .headingH4
		h5
			@extend .headingH5
		h6
			@extend .headingH6
		p
			color: $colorTextDark
			font-size: $fontSizeStandard3
			line-height: $fontSizeStandard6
			font-weight: 400
			margin-top: $paddingMedium
			&:first-of-type
				margin-top: 0
		input
			@extend .inputStyle

.homeHeaderWrapper
	&__homeHeader
		position: relative
		&__topWrapper
			position: fixed
			top: 0
			z-index: 3
			width: 100%
			display: flex
			align-items: stretch
			background-color: white // Background is dynamically adjusted in component.
			padding-right: 14px // Adjust for scrollbar.
			height: $heightHomepageTopMenu
			&.scrollHeader
				@extend .shadowSmallDark
			&__top
				margin: 0 auto
				width: 100%
				padding: 0 calc(#{$paddingBig} * 2)
				display: flex
				justify-content: space-between
				align-items: center
				&__left
					width: 134px
					height: 38px
					background-image: url('../../images/bilprospekt_logo_darkgrey.png')
					background-repeat: no-repeat
					background-size: 130px
				&__right
					display: flex
					justify-content: flex-end
					align-items: center
					gap: calc(#{$paddingBig} * 2)
					&__item
						font-size: $fontSizeStandard3
						font-weight: 700
						letter-spacing: 0.6px
						color: $colorTextDark
						cursor: pointer
						&:hover
							color: $colorLink
						&.login
							display: flex
							align-items: center
							gap: $paddingSmall
							svg
								width: 16px
								height: 16px
		&__bottom
			height: 740px // Height dynamically set in component
			overflow: hidden
			margin: 0 auto
			width: 100%
			display: flex
			justify-content: space-between
			&__left
				width: 42%
				height: 100%
				min-width: 500px
				display: flex
				flex-direction: column
				justify-content: center
				padding: 0 calc(#{$paddingBig} * 2)
				&__holder
					&__elevatorPitch
						h2.subHeading
							margin-bottom: $paddingBig
					&__buttonWrapper
						margin-top: $paddingBig
						&__button
							@extend .homeSpecialButton
							font-size: $fontSizeStandard5 !important
							line-height: 1em !important
							svg
								width: 20px !important
								height: 20px !important
								-webkit-animation: bounceDown 3s ease infinite
								-moz-animation: bounceDown 3s ease infinite
								-o-animation: bounceDown 3s ease infinite
								animation: bounceDown 3s ease infinite
			&__right
				width: 58%
				height: 100%
				overflow: hidden
				-webkit-clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%)
				clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%)
				img
					height: 100%
					object-fit: cover
					width: unset
					max-width: unset
		&__login
			display: flex
			flex-direction: column
			justify-content: center
			position: relative
			&__content
				&__inputs
					display: flex
					flex-direction: column
					gap: $paddingBig
					&__row
						display: flex
						justify-content: flex-start
						align-items: center
						flex-wrap: wrap
						&__label
							width: 100%
							color: $colorTextLight
							font-size: $fontSizeStandard3
							margin-bottom: $paddingSmall
						input
							@extend .inputStyle
							border: 0 !important
							background: $grey-100 !important
							width: calc(100% - 54px)
							height: 54px !important
							padding-left: $paddingBig !important
							padding-right: $paddingBig !important
						&__notification
							height: 54px
							width: 54px
							min-width: 54px
							display: flex
							align-items: center
							justify-content: center
							background: $grey-100
							&.valid
								svg
									visibility: visible
							&.invalid
								svg
									visibility: hidden
							svg
								width: 16px
								height: 16px
			&__footer
				margin-top: $paddingBig
				display: flex
				align-content: center
				justify-content: space-between
				button
					background: transparent
					cursor: pointer
					color: $colorLink
					font-size: $fontSizeStandard2
					font-weight: 600
					letter-spacing: 0.4px
					border: 0
					padding: 0
					&:hover
						color: $colorLinkHover !important
					&.disabled
						border-color: $grey-300 !important
						color: $grey-400 !important
						pointer-events: none !important

			&__contactInfo
				margin-top: $paddingBig
				font-size: $fontSizeStandard1
				font-weight: 600

			&__failed
				color: red
				margin-top: $paddingBig
				font-size: $fontSizeStandard1
				font-weight: 600

.homeInfoWrapper
	&__homeInfo
		margin: 0 auto
		width: 100%
		padding: calc(#{$paddingBig} * 4) $paddingBig
		max-width: $widthHomepageContent
		display: flex
		flex-direction: column
		gap: calc(#{$paddingBig} * 4)
		&__section
			display: flex
			align-items: stretch
			//max-width: 75.1%
			max-width: 88%
			align-self: center
			&:nth-child(odd)
				align-self: flex-start
				.textHolder
					position: relative
					&:before
						position: absolute
						right: 0
						top: 0
						content: ""
						width: 3px
						height: 100%
						background: $homeMainColor
			&:nth-child(even)
				align-self: flex-end
				.textHolder
					position: relative
					&:before
						position: absolute
						left: 0
						top: 0
						content: ""
						width: 3px
						height: 100%
						background: $homeMainColor
			.imageHolder
				width: 28%
				overflow: hidden
			.textHolder
				width: 72%
				padding: 0 calc(#{$paddingBig} * 2)
			h3
				margin-bottom: $paddingBig
			img
				height: 100%
				object-fit: cover
				width: 100%
				max-width: unset

.homeFlowWrapper
	background: $homeSecondaryBackground
	&__homeFlow
		color: $colorTextMain
		padding: calc(#{$paddingBig} * 3) $paddingBig
		margin: 0 auto
		width: 100%
		max-width: $widthHomepageContent
		&__content
			display: flex
			flex-wrap: wrap
			gap: calc(#{$paddingBig} * 4)
			&__item
				width: calc(33.33% - (#{$paddingBig} * 8 / 3))
				display: flex
				align-items: flex-start
				&__left
					padding-right: $paddingBig
					&__icon
						border: 3px solid $homeMainColorDark
						border-radius: 50%
						width: 62px
						min-width: 62px
						height: 62px
						display: flex
						align-items: center
						justify-content: center
						svg
							width: 32px
							height: 32px
							fill: $homeMainColorDark
				&__right
					flex-grow: 1
					h3
						margin-bottom: $paddingMedium
						word-break: break-word
					p
						font-size: $fontSizeStandard3
						line-height: $fontSizeStandard5
						color: $colorTextDark

.homeReviewsWrapper
	&__homeReviews
		width: 100%
		&__content
			width: 100%
			background: $homeMainColor
			&__items
				.slick-track
					display: flex
					align-items: center
				&__itemWrapper
					width: 100%
					display: flex
					align-items: center
					&__item
						margin: 0 auto
						color: $colorTextDark
						padding: calc(#{$paddingBig} * 2) 10%
						max-width: $widthHomepageContent
						&__quote
							font-size: $fontSizeStandard12
							line-height: 0.2em
							font-weight: 700
							font-family: $fontHomeSerif
							position: relative
							top: 26px
						&__text
							&__row
								margin: $paddingBig 0 0 0
								p
									position: relative
									overflow: hidden
									background-color: transparent
									&:after
										position: absolute
										content: ''
										top: 0
										right: 0
										bottom: 0
										left: 0
										pointer-events: none
										mix-blend-mode: lighten
									span
										color: $colorTextDark
										background-color: $homeMainColorDark
										-webkit-box-decoration-break: clone
										box-decoration-break: clone
										font-size: $fontSizeStandard7
										line-height: 1.44em
										margin: 0
										font-family: $fontHomeSerif
										font-weight: 700
										letter-spacing: 0.4px
										padding: 6px 12px
						&__reviewer
							display: flex
							align-items: flex-end
							flex-direction: column
							margin-top: $paddingBig
							font-size: $fontSizeStandard3
							&__user
								font-weight: 700
								font-size: $fontSizeStandard4
								line-height: 1.3em
							&__dealer
								font-weight: 400
								font-size: $fontSizeStandard2
								line-height: 1.2em
						&__img
							margin-top: $paddingSmall
							display: flex
							justify-content: flex-end
							img
								max-height: 18px

.homeTeamWrapper
	&__homeTeam
		margin: 0 auto
		padding: calc(#{$paddingBig} * 2) $paddingBig calc(#{$paddingBig} * 3) $paddingBig
		width: 100%
		max-width: $widthHomepageContent
		&__content
			display: flex
			justify-content: flex-start
			flex-wrap: wrap
			gap: calc(#{$paddingBig} * 2) calc(#{$paddingBig} * 5)
			margin-top: calc(#{$paddingBig} * 2)
			&__item
				width: calc(33.33% - (#{$paddingBig} * 10 / 3))
				position: relative
				display: flex
				flex-direction: column
				align-items: center
				justify-content: flex-start
				&:hover
					.homeTeamWrapper__homeTeam__content__item__image__contact
						visibility: visible
				&__image
					position: relative
					overflow: hidden
					display: flex
					justify-content: center
					align-items: center
					width: 64%
					border-radius: 50%
					img
						width: 100% // Make sure original images is square, same width and height
						position: relative
						bottom: 0
						clip-path: circle()
				&__information
					margin-top: $paddingBig
					display: flex
					flex-direction: column
					align-items: center
					&__row
						display: flex
						flex-direction: column
						justify-content: center
						align-items: center
						//width: 100%
						margin-top: 0
						&.minimize
							display: none
						&.infoRow
							margin-top: $paddingMedium
						&.flexRow
							flex-direction: row
							flex-wrap: wrap
							gap: $paddingSmall
						&__icon
							border-radius: 50%
							border: 1px solid $colorTextDark
							cursor: pointer
							width: 32px
							min-width: 32px
							height: 32px
							display: flex
							align-items: center
							justify-content: center
							&:hover, &.active
								border-color: $colorLink
								svg
									fill: $colorLink
							svg
								width: 16px
								height: 16px
								fill: $colorTextDark
						h3
							margin: 4px 0 $paddingSmall 0
							line-height: 1.1em
							text-align: center
							word-break: break-word
							white-space: normal
						p
							font-size: $fontSizeStandard-1
							text-align: center
						p.position
							width: 100%
							font-size: $fontSizeStandard1
							line-height: $fontSizeStandard3
							text-align: center
							color: $colorTextLight

.homeContactWrapper
	&__homeContact
		margin: 0
		width: 100%
		display: flex
		align-items: stretch
		color: $colorTextDark
		p, h2, h3, h4, h5, span
			color: $colorTextDark
		&__header
			margin-bottom: calc(#{$paddingBig} * 2)
		&__left
			background: $homeMainColor
			width: 50%
			display: flex
			flex-direction: column
			align-items: flex-end
			padding: calc(#{$paddingBig} * 2) 0 calc(#{$paddingBig} * 4) 0
			&__content
				max-width: calc((#{$widthHomepageContent} / 2) - (#{$paddingBig} * 2))
				margin: 0 calc(#{$paddingBig} * 2)
				&__info
					margin-top: calc(#{$paddingBig} * 2)
					p
						font-size: $fontSizeStandard2
						line-height: $fontSizeStandard5
		&__right
			background: $homeSecondaryBackground
			width: 50%
			padding: calc(#{$paddingBig} * 2) 0 calc(#{$paddingBig} * 4) 0
			display: flex
			flex-direction: column
			justify-content: stretch
			img
				width: 100%
				margin-top: $paddingMedium
			&__content
				max-width: calc((#{$widthHomepageContent} / 2) - (#{$paddingBig} * 2))
				margin: 0 calc(#{$paddingBig} * 2)
				&__item
					display: flex
					flex-direction: column
					align-items: flex-start
					gap: $paddingSmall
					margin-top: $paddingBig
					&:first-child
						margin-top: 0
					&__row
						display: flex
						justify-content: flex-start
						font-size: $fontSizeStandard2
						line-height: $fontSizeStandard3
						button
							@extend .homeSpecialButton
						&__label
							width: 60px
							margin-right: $paddingMedium
							span
								//font-weight: 600
								color: $colorTextDark
						&__value
							display: flex
							flex-direction: column
							&.marginTop
								margin-top: $paddingMedium
					img
						width: 100%

.homeFormWrapper
	&__homeForm
		&__content
			margin-top: $paddingBig
			&__info
				margin-bottom: $paddingBig
				p
					font-size: $fontSizeStandard
					line-height: $fontSizeStandard3
			&__row
				display: flex
				justify-content: flex-start
				align-items: center
				margin-top: $paddingSmall
				color: $colorTextDark
				input
					@extend .inputStyle
					background: $homeMainColorDark !important
					border: 0 !important
				&__icon
					height: 42px
					width: 42px
					min-width: 42px
					align-items: center
					justify-content: center
					display: none
					background: $homeMainColorDark
					svg
						fill: $colorTextDark
						width: 14px
						height: 14px
				&__notification
					height: 42px
					width: 42px
					min-width: 42px
					display: flex
					align-items: center
					justify-content: center
					background: $homeMainColorDark
					&.valid
						svg
							visibility: visible
					&.invalid
						svg
							visibility: hidden
					svg
						width: 16px
						height: 16px
			&__button
				margin-top: $paddingMedium
				display: flex
				justify-content: flex-end
				&.disabled
					button
						&:hover
							cursor: not-allowed
							background: $grey-100 !important
				button
					@extend .homeSpecialButton
					border-color: $grey-800 !important
					color: $grey-900 !important
					&:hover
						border-color: $grey-900	!important
						background: white !important

.homeSpecialButton
	cursor: pointer
	display: inline-flex
	align-items: center
	justify-content: center
	background: transparent !important
	border-radius: 30px
	padding: 0.44em 1em
	border: 2px solid
	color: $homeMainColorDarker
	font-size: $fontSizeStandard3
	line-height: $fontSizeStandard3
	font-weight: 600
	&:hover
		border-color: $colorLink
		color: $colorLink
	svg
		margin-left: $paddingSmall
		width: 18px
		height: 18px
	&.disabled
		border-color: $grey-600
		color: $grey-600
		pointer-events: none

.inputStyle
	height: 42px
	padding: $paddingSmall calc(#{$paddingMedium} * 1.5)
	font-size: $fontSizeStandard1
	color: $colorTextDark
	background: transparent
	border: 1px solid $homeMainColor
	&:focus
		&::placeholder
			visibility: hidden
		&:-ms-input-placeholder
			visibility: hidden
		&::-ms-input-placeholder
			visibility: hidden
	&:-webkit-autofill
		-webkit-box-shadow: 0 0 0 30px $grey-100 inset !important // Remove chrome autofill background color
	&::placeholder
		text-transform: uppercase
		letter-spacing: 1.7px
		color: $colorTextDark
		font-weight: 600
		font-size: $fontSizeStandard-4
	&:-ms-input-placeholder
		text-transform: uppercase
		letter-spacing: 1.7px
		color: $colorTextDark
		font-weight: 600
		font-size: $fontSizeStandard-4
	&::-ms-input-placeholder
		text-transform: uppercase
		letter-spacing: 1.7px
		color: $colorTextDark
		font-weight: 600
		font-size: $fontSizeStandard-4
