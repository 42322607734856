.toggleOptionWrapper
  width: 100%
  &__toggleOption
    display: flex
    align-items: center
    justify-content: space-between
    color: $colorTextMain
    gap: $paddingBig
    &.disabled
      pointer-events: none
      color: $colorTextLight-2
      .toggleOptionWrapper__toggleOption__icon
        svg
          fill: $grey-200
    &__label
      font-size: $fontSizeStandard1
      font-weight: 600
      flex-grow: 1
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      &__main
        font-weight: 600
        font-size: $fontSizeStandard1
        line-height: $fontSizeStandard3
        word-break: break-word
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
      &__sub
        font-size: $fontSizeStandard-1
        line-height: $fontSizeStandard1
        color: $colorTextLight
        word-break: break-word
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
    &__icon
      //width: 38px
      //margin-left: $paddingMedium
      cursor: pointer
      display: flex
      align-items: center
      &:hover
        svg
          fill: $colorLinkHover !important
      &.active
        svg
          fill: $colorLink
      svg
        width: 38px
        height: 38px
        fill: $grey-300

.excludeToggleOption
  & .toggleOptionWrapper__toggleOption__label__main
    font-size: 15px
  & .toggleOptionWrapper__toggleOption
    gap: $paddingSmall
