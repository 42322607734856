#printHidden
  display: none

// Print component style is found in /public/print.css


// (Keep below to convert below to CSS.)
//$amber-50:    #fff8e1
//$amber-100:   #ffecb3
//$amber-200:   #ffe082
//$amber-300:   #ffd54f
//$amber-400:   #ffca28
//$amber-500:   #ffc107
//$amber-600:   #ffb300
//$amber-700:   #ffa000
//$amber-800:   #ff8f00
//$amber-900:   #ff6f00
//$amber-A100:  #ffe57f
//$amber-A200:  #ffd740
//$amber-A400:  #ffc400
//$amber-A700:  #ffab00
//$grey-50:   #fafafa
//$grey-100:  #f5f5f5
//$grey-200:  #eeeeee
//$grey-300:  #e0e0e0
//$grey-400:  #bdbdbd
//$grey-500:  #9e9e9e
//$grey-600:  #757575
//$grey-700:  #616161
//$grey-800:  #424242
//$grey-900:  #212121
//$heightSmallRoundIcon: 24px
//$heightSmallRoundIconSvg: 12px
//$paddingMedium: 12px
//$paddingSmall: 8px
//$fontSizeStandard: 13px
//$fontSizeStandard-1: 12px
//$colorTextMain: $grey-800
//$colorTextDark: $grey-900
//$colorTextLight: $grey-600
//$colorTextLight-1: $grey-500
//$colorInformatory: $amber-600
//$colorEventPassedDate: $amber-800
//$colorEventWithinOneDay: #91d47f
//$colorEvent: $colorInformatory
//
//
//h5
//  font-size: $fontSizeStandard-1
//  font-weight: 600
//  line-height: 18px
//  text-transform: uppercase
//body#printWindow
//  background: white
//  color: $colorTextMain
//  font-size: $fontSizeStandard
//  overflow-x: hidden
//  display: flex
//  justify-content: center
//  box-sizing: border-box
//  line-height: 20px
//  font-family: 'Source sans pro', sans-serif
//  font-weight: 400
//  .printWrapper
//    width: 210mm
//    padding: 6mm
//    color: $colorTextDark
//    &__print
//      &__header
//        display: flex
//        justify-content: space-between
//        font-weight: 600
//        font-size: 3em
//        line-height: 1em
//        img
//          height: 10mm
//      &__content
//        margin: 6mm 0
//        &__section
//          border-bottom: 1px solid $grey-300
//          padding: 8mm 0
//          &:last-of-type
//            border: 0
//          &__header
//            h5
//              font-size: 1.4em
//              margin-bottom: 3mm
//              text-transform: none
//          &__content
//            display: flex
//            flex-wrap: wrap
//            &.noFlex
//              display: block
//            .capitalize
//              text-transform: capitalize
//            &__row
//              flex-basis: 100%
//              &:nth-child(odd)
//                background: $grey-100
//              span.label
//                display: none
//              .activitiesWrapper__activities__content__itemWrapper__item__icon
//                border-color: $grey-400
//                .iconHolder
//                  svg
//                    fill: $colorTextMain
//              .activitiesWrapper__activities__content__itemWrapper__item__date
//                flex-basis: 20%
//              .activitiesWrapper__activities__content__itemWrapper__item__user
//                flex-basis: 16%
//            &__halfRow
//              flex-basis: calc(50% - 4mm)
//              margin-top: 2mm
//              display: flex
//              &:nth-child(odd)
//                margin-right: 2mm
//              &:nth-child(even)
//                margin-left: 2mm
//              &__left
//                margin-right: 2mm
//                color: $colorTextLight
//            &__fleetHolder
//              padding: 2mm 2mm 0 2mm
//              flex-basis: 100%
//              &__type
//                &__header
//                  display: flex
//                  align-items: center
//                  margin-top: 4mm
//                  color: $colorTextLight-1
//                  p
//                    margin-left: 2mm
//                &__content
//                  &__vehicle
//                    display: flex
//                    align-items: center
//                    justify-content: space-between
//                    margin: 1mm 3mm
//                    &__left
//                      display: flex
//                      align-items: center
//                      font-size: 0.9em
//                      .highlight
//                        font-weight: 600
//                      .marginRight
//                        margin-right: 2mm
//      &__footer
//        display: flex
//        justify-content: space-between
//        border-top: 1px solid $grey-300
//        padding: 2mm 0
//        color: $colorTextLight-1
//
//
//.activitiesPrintWrapper
//  width: 100%
//  &__activitiesPrint
//    &__content
//      &__itemWrapper
//        position: relative
//        &:nth-child(even)
//          background: $grey-50
        &__item
          padding: $paddingMedium
          display: flex
          align-items: stretch
          flex-basis: 100%
          &__left
            display: flex
            align-items: center
            &__icon
              display: flex
              align-items: center
              justify-content: center
              height: $heightSmallRoundIcon
              width: $heightSmallRoundIcon
              border: 1px solid $colorEvent
              border-radius: 50%
              svg
                fill: $colorEvent !important
                width: $heightSmallRoundIconSvg !important
                height: $heightSmallRoundIconSvg !important
          &__center
            margin: 0 $paddingMedium
            flex-grow: 1
            .lightAndItalic
              font-style: italic
              color: $colorTextLight-1
            .highlight
              font-weight: 600
              display: inline-flex
              align-items: center
              & > div
                font-weight: 600
            .marginLeft
              margin-left: 4px
            .marginRight
              margin-right: 4px
            .extraMarginRight
              margin-right: $paddingSmall
            &__additionalLine
              display: flex
              flex-direction: column
          &__right
            width: 100px
            word-break: break-word
            text-align: right
