.wrapper {
  --headerBgColor: var(--colorNavigationHeader);
  --headerColor: var(--grey-100);
  --headerBgColorActive: var(--colorNavigationDarker);
  --headerColorActive: var(--colorTextLight-3);
  --navlinkBgColor: var(--colorNavigation);
  --navlinkBgColorChild: var(--colorNavigationDark);
  --navlinkBgColorActive: var(--colorNavigationDarker);
  --navlinkColor: var(--grey-300);
  --navlinkColorActive: var(--grey-50);
  --navlinkBorderColor: #41425d59;
  --headerSize: 17px;
  --fontSize: 15px;
  --borderActive: var(--colorHeaderBackground);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 2px;
  font-size: var(--fontSize);
  background-color: var(--colorNavigationBackground);
  .nav {
    margin-bottom: 20vh;
  }
  .details {
    background-color: var(--headerBgColor);
    color: var(--headerColor);
    summary {
      list-style-position: outside;
      &:before {
        content: '⏵';
        font-size: 19px;
        margin-right: 16px;
      }
      font-size: var(--headerSize);
      padding: 6px 18px;
      border-bottom: 1px solid rgba(185, 185, 185, 0.2);
      &:hover {
        color: var(--headerColorActive);
        background-color: var(--headerBgColorActive);
        cursor: pointer;
      }
    }
  }
  details[open].details {
    summary:before {
      content: '⏷';
    }
  }
  .updatedate {
    height: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colorTextLight-3);
    margin-top: 36px;
  }
  .listitem {
    list-style-type: none;
    display: flex;
    gap: 8px;
  }
  .navlogo {
    background: url('../../images/bilprospekt_logo_white.png') no-repeat 50% 50%;
    background-size: auto 30px;
    height: 100px;
    width: 100%;
  }
  .navlink {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background-color: var(--navlinkBgColor);
    color: var(--navlinkColor);
    border: 1px solid var(--navlinkBorderColor);
    border-left: 2px solid var(--navlinkBgColor);
    border-right: none;
    &.active,
    &.childItem.active,
    &.childItem:hover,
    &:hover {
      color: var(--navlinkColorActive);
      background-color: var(--navlinkBgColorActive);
      border-left-color: var(--borderActive);
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .navlink.childItem {
    padding: 14px 18px;
    background-color: var(--navlinkBgColorChild);
  }
  .settingsButton {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: var(--fontSize);
    color: var(--navlinkColor);
    &:hover {
      cursor: pointer;
      color: var(--navlinkColorActive);
    }
  }
  .mobileOnly {
    display: none;
    @media only screen and (max-width: 589px) {
      display: block;
    }
  }
  .footer {
    background-color: var(--grey-800);
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 5vh;
    height: 10vh;
    cursor: pointer;
    svg {
      width: 35px;
      height: 35px;
      fill: white;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: var(--grey-500);
      svg {
        fill: var(--grey-800);
      }
    }
  }
}
