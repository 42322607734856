.agileBoardWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__agileBoard
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__content
      display: flex
      justify-content: flex-start
      align-items: stretch
      flex-grow: 1
      &__left
        display: flex
        flex-direction: column
        flex-grow: 1
        &__top
          display: flex
          justify-content: space-between
          height: 34px
          margin: $paddingSmall $paddingMedium $paddingMedium $paddingMedium
          &__colorExplanation
            display: flex
            justify-content: flex-start
            align-items: center
            font-size: $fontSizeStandard-3
            color: $colorTextLight
            &__item
              display: flex
              justify-content: flex-start
              align-items: center
              margin-right: $paddingBig
              .circle
                border-radius: 50%
                height: 6px
                width: 6px
                margin-right: 6px
              &.event
                .circle
                  background: $colorEvent
              &.eventWithinOneDay
                .circle
                  background: $colorEventWithinOneDay
              &.eventPassedDate
                .circle
                  background: $colorEventPassedDate
          &__search
            &__container
              display: flex
              justify-content: flex-end
              align-items: center
              flex-direction: row
              height: 36px
              svg
                width: 20px
                height: 20px
                fill: $colorLink
                cursor: pointer
                margin-right: .3em
                &:hover
                  fill: $colorLinkHover
              input
                margin-right: $paddingMedium
                max-width: 24%
                min-width: 250px
                -webkit-animation: fullWidthRight 0.3s
                -moz-animation: fullWidthRight 0.3s
                -o-animation: fullWidthRight 0.3s
                animation: fullWidthRight 0.3s
              span
                color: lightgrey
        &__bottom
          height: 100%
          position: relative
          &__infobox
            background: white
            margin: $paddingMedium 0 0 $paddingMedium
            padding: $paddingBig
      &__right
        position: fixed
        top: 0
        right: 0
        z-index: 7
        height: 100vh
        @extend .shadowPreview
        @extend .widgetWidthAnimation
        width: $widthPreviewWindow
        max-width: 100vw
        &.minimize
          @extend .widgetWidthAnimationMinimized

.agileKanbanBoardWrapper
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  padding: 0 $paddingGrid
  &__agileKanbanBoard
    height: 100%
    &__content
      height: 100%
      position: relative
      &.columns3, &.columns2, &columns1, &.columns0
        & > .react-trello-board
          & > div
            & > .smooth-dnd-container
              &.horizontal
                grid-auto-columns: calc(33.33% - (#{$paddingGrid} - 1px))
      &.columns4
        & > .react-trello-board
          & > div
            & > .smooth-dnd-container
              &.horizontal
                grid-auto-columns: calc(25% - (#{$paddingGrid} - 1px))
      & > .react-trello-board // Override react-trello style
        display: block
        height: 100%
        background: unset
        padding: 0
        color: $colorTextMain
        //@extend .scrollbarRegular
        & > div
          height: 100%
          width: 100%
          & > .smooth-dnd-container
            &.horizontal
              height: 100%
              display: grid
              grid-gap: $paddingGrid
              grid-auto-flow: column
              grid-auto-columns: calc(20% - (#{$paddingGrid} - 1px)) // Adjusted above, also in media queries.
              & > .smooth-dnd-draggable-wrapper
                min-height: 100% // Important
                height: auto // Important
                & > section
                  width: 100%
                  min-width: 100%
                  min-height: 100% // Important
                  height: auto // Important
                  display: inline-flex
                  flex-direction: column
                  padding: 0
                  margin: 0
                  background: unset
                  & > header
                    padding: 0
                    margin-bottom: 4px
                    & > span
                      font-size: $fontSizeStandard-1
                      color: $colorTextMain
                  & > div
                    min-width: unset
                    width: 100%
                    max-height: 100vh
                    height: 100%
                    min-height: 100%
                    height: auto
                    margin-top: 0
                    padding: 6px
                    background: darken($grey-50, 1%)
                    overflow-y: auto
                    display: flex
                    flex-direction: column
                    //@extend .scrollbarSmallDark
                    @extend .scrollbarRegular
                    & > .smooth-dnd-container
                      &.vertical
                        flex-grow: 1
                        & > .smooth-dnd-draggable-wrapper
                          margin-top: 6px
                          overflow: visible
                          cursor: pointer !important
                          &:first-child
                            margin-top: 0
      &__getMore
        position: absolute
        bottom: $paddingGrid * 3
        left: $paddingGrid * 2
        &__button
          height: $heightSmallRoundIcon
          width: $heightSmallRoundIcon
          display: flex
          align-items: center
          justify-content: center
          background: $colorLink
          border-radius: 50%
          cursor: pointer
          border: 1px solid white
          @extend .shadowSmall
          &:hover
            background: $colorLinkHover
          svg
            height: $heightSmallRoundIconSvg
            width: $heightSmallRoundIconSvg
            fill: white

.agileKanbanBoardItemWrapper
  cursor: pointer !important
  &__agileKanbanBoardItem
    padding: $paddingMedium
    border-radius: 2px
    border: 1px solid transparent
    @extend .shadowSmall
    background: white
    width: 100%
    &:hover
      border-color: $colorLink
    &.openInPreview
      border-color: $colorLink
    &__content
      display: flex
      align-items: center
      justify-content: flex-start
      width: inherit
      &__user
        display: flex
        align-items: center
        svg
          fill: $colorTextLight
          width: 12px
          height: 12px
          margin-right: $paddingSmall
      &__event
        margin-right: $paddingMedium
        &:hover
          .agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event__defaultHidden
            display: block
          .agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event__passed
            display: none
          .agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event__withinOneDay
            display: none
          .agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event__hasEvent
            display: none
        svg
          @extend .shadowSoft
          display: flex
          align-items: center
          justify-content: center
          padding: $paddingSmall
          background: transparent
          border: 1px solid transparent
          border-radius: 50%
          width: $iconSizeBig
          height: $iconSizeBig
          fill: $grey-600
          cursor: pointer
          &:hover
            fill: $colorLink
        &__defaultHidden
          display: none
        &__defaultVisible
          display: block
        &__passed
          svg
            -webkit-box-shadow: unset
            -moz-box-shadow: unset
            box-shadow: unset
            border-color: $colorEventPassedDate !important
            background: $colorEventPassedDateOpacity !important
            fill: white !important
        &__withinOneDay
          svg
            -webkit-box-shadow: unset
            -moz-box-shadow: unset
            box-shadow: unset
            border-color: $colorEventWithinOneDay !important
            background: $colorEventWithinOneDayOpacity !important
            fill: white !important
        &__hasEvent
          svg
            -webkit-box-shadow: unset
            -moz-box-shadow: unset
            box-shadow: unset
            border-color: $colorEvent !important
            background: $colorEventOpacity !important
            fill: white !important
      &__main
        flex-grow: 1
        & .listDate
          font-size: 13px
        &__mainTop
          &__dealName
            font-size: $fontSizeStandard2
            font-weight: 600
            word-break: break-all
            display: flex
            align-items: center
          &__prospectName
            font-weight: normal
            font-size: $fontSizeStandard
            display: flex
            align-items: center
            flex-wrap: wrap
            gap: $paddingSmall
          &__informationHolder
            font-size: $fontSizeStandard-2
            display: inline-flex
            align-items: center
            gap: $paddingSmall
            &__icon
              display: flex
              align-items: center
              justify-content: center
              position: relative
              top: 2px
              svg
                //fill: $colorInformatory
                fill: $colorSecondary-700
                width: 18px
                height: 18px
              &.dark
                svg
                  fill: $colorPrimary-700
              & .tooltipWrapper__tooltip__indicator
                display: flex
                align-items: center
        &__mainBottom
          display: flex
          align-items: center
          justify-content: flex-start
          font-size: $fontSizeStandard-2
          flex-wrap: wrap
          &__label
            color: $colorTextLight-1
            margin-right: 4px
.card-source-tag
  display: inline-block
  padding: 2px
  border-radius: 8px
  font-size: 12px
  letter-spacing: 0.5px
.card-source-tag.blue
  color: blue
  border: 1px solid blue
.card-source-tag.violet
  color: violet
  border: 1px solid violet
.card-source-tag.purple
  color: purple
  border: 1px solid purple
.card-source-tag.orange
  color: orange
  border: 1px solid orange
.card-source-tag.brown
  color: brown
  border: 1px solid brown
.card-source-tag.green
  color: green
  border: 1px solid green
.paddingWrapper
  padding: $paddingMedium
.agilePopupWrapper
  &__agilePopup
    padding: $paddingBig
    &__content
      margin: $paddingBig 0
      p
        margin-right: $paddingMedium
        white-space: nowrap
      h4
        margin-bottom: $paddingSmall
        text-transform: none
      input
        height: 32px
        width: 100%
      textarea
        height: 32px
        width: 100%
      &__row
        width: 100%
        margin-top: $paddingBig
        &.first-of-type
          margin-top: 0
        &__items
          display: flex
          flex-wrap: wrap
          .actionButtonWrapper
            margin-bottom: $paddingSmall
            margin-right: $paddingSmall
            &:last-of-type
              margin-left: 0

// Keep style similar between ProspectResultPreview, AgileBoardPreview and LeadsPreview
.agileBoardPreviewWrapper
  height: 100%
  background: white
  &__agileBoardPreview
    height: 100%
    display: flex
    flex-direction: column
    &__header
      background: $grey-50
      @extend .shadowSoft
      &__top
        margin: 0 $paddingBig
        padding: $paddingBig 0
        border-bottom: 1px solid $grey-300
        display: flex
        justify-content: space-between
        &__left
          cursor: pointer
          width: calc(100% - 20px - #{$paddingMedium})
        & .contractInfoWrapper
          display: flex
          align-items: center
          gap: 12px
          margin-top: 16px
          svg
            fill: $colorPrimary-700
        &__right
          width: 20px
          margin-left: $paddingMedium
          svg
            width: 20px
            height: 20px
            cursor: pointer
            &:hover
              fill: $colorLink
      &__bottom
        padding: $paddingMedium ($paddingBig + $paddingSmall)
        display: flex
        flex: 1
        align-items: center
        justify-content: flex-start
        width: 100%
        svg
          cursor: pointer
          width: 24px
          height: 24px
          fill: $colorLink
          &:hover
            fill: $colorLinkHover
        svg.active
          fill: $colorLinkHover !important
        &__item
          margin-left: $paddingBig
          cursor: pointer
          &:first-of-type
            margin-left: 0
          &__disabled
            @extend .agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item
            pointer-events: none
            svg
              fill: $grey-300
    &__content
      overflow-y: auto
      overflow-x: hidden
      //@extend .scrollbarSmallDark
      @extend .scrollbarRegular
      padding: $paddingMedium $paddingMedium ($paddingMedium * 2) $paddingMedium
      flex-grow: 1
      scroll-behavior: smooth
      svg
        width: 14px
        height: 14px
      &__block
        margin-top: ($paddingBig * 2)
        &:first-of-type
          margin-top: $paddingBig
        &__header
          cursor: pointer
          position: relative
          border-top: 1px solid $grey-300
          color: $colorTextLight
          &:hover
            color: $colorLink
            border-color: $colorLink
          &__title
            display: flex
            align-items: center
            position: absolute
            top: -14px
            right: $paddingMedium
            padding: 4px 4px 4px 8px
            background: white
            svg
              width: 18px
              height: 18px
        &__content
          display: flex
          flex-wrap: wrap
          margin-top: $paddingBig
          &__completeDeal
            display: flex
            justify-content: space-evenly
            align-items: center
            width: 100%
            .completeDealAsLost
              background: $colorFailOpacity
              border-color: $colorFail
              color: white
              svg
                fill: white
            .completeDealAsWon
              background: $colorSuccessOpacity
              border-color: $colorSuccess
              color: white
              svg
                fill: white
          &__dealType
            display: flex
            flex-wrap: wrap
            justify-content: center
            align-items: center
            width: 100%
            gap: $paddingSmall
          &__actions
            flex-basis: 100%
            display: flex
            align-items: center
            justify-content: space-evenly
            &__item
              cursor: pointer
              display: flex
              align-items: center
              justify-content: center
              padding: $paddingMedium
              border: 1px solid $grey-300
              border-radius: 50%
              &:hover
                border: 1px solid $colorLink
                svg
                  fill: $colorLink
              svg
                width: 24px
                height: 24px
                fill: $grey-300
          &__row
            padding: $paddingMedium
            display: flex
            align-items: center
            justify-content: space-between
            flex-basis: 100%
            &__left
              display: flex
              align-items: center
              &__content
                margin: 0 $paddingMedium
                .lightAndItalic
                  font-style: italic
                  color: $colorTextLight-1
                .highlight
                  font-weight: 600
                  display: inline-flex
                  align-items: center
                  svg
                    fill: $colorEventPassedDate
                .marginLeft
                  margin-left: 4px
                .marginRight
                  margin-right: 4px
                .extraMarginRight
                  margin-right: $paddingSmall
                &__additionalLine
                  display: block
                  margin-left: ($paddingSmall + 16px)
            &__right
              display: flex
              .tooltipWrapper
                margin-left: $paddingSmall
          &__infoRow
            display: flex
            flex-basis: 100%
            margin-bottom: 4px
            &__left
              color: $colorTextLight-1
              margin-right: $paddingSmall
              word-break: break-word
              width: 32%
              min-width: 32%
            &__right
              display: flex
              flex-grow: 1
              white-space: pre-wrap
              word-break: break-word
              flex-wrap: wrap
              &.noFlexWrap
                flex-wrap: unset
              svg
                cursor: pointer
                width: 14px
                height: 14px
                fill: $colorLink
                margin-left: $paddingSmall
                position: relative
                top: 2px
                &:hover
                  fill: $colorLinkHover
              .dropdownWrapper__dropdown__header
                align-items: flex-start !important
                min-height: unset !important
                height: unset !important
                padding: 4px !important
                svg
                  height: 18px !important
                  width: 18px !important
                  fill: $colorTextLight !important
              &__icons
                display: flex
                flex-grow: 1
                justify-content: flex-end
          &__contact
            margin-top: $paddingBig
            flex-basis: 100%
            &:first-child
              margin-top: 0
            &__content
              display: flex
              justify-content: flex-start
              flex-wrap: wrap
          &__prospectOrCar
            margin-top: $paddingBig
            flex-basis: 100%
            &:first-of-type
              margin-top: 0
            &__header
              display: flex
              align-items: center
              justify-content: space-between
              cursor: pointer
              padding: $paddingMedium
              background: $grey-100
              border: 1px solid $grey-100
              &:hover
                background: transparent
                border-color: $colorLink
                color: $colorLink
              &__left
                display: flex
                align-items: center
                p
                  font-weight: 600
                svg
                  margin-right: $paddingMedium
              &__right
                display: flex
                align-items: center
            &__content
              display: flex
              flex-wrap: wrap
              padding: $paddingMedium $paddingSmall
              &__fleetHolder
                padding: $paddingMedium $paddingMedium 0 $paddingMedium
                flex-basis: 100%
                &__type
                  &__header
                    display: flex
                    align-items: center
                    margin-top: $paddingMedium
                    p
                      margin-left: $paddingSmall
                      font-size: $fontSizeStandard-1
                      font-weight: 600
                      text-transform: uppercase
                  &__content
                    &__vehicle
                      margin: 4px $paddingMedium
                      &:hover
                        .regNumber
                          color: $colorLink
                      .tooltipWrapper__tooltip__indicator
                        display: inline
                      .regNumber
                        cursor: pointer
                        &:hover
                          color: $colorLinkHover
                      .smallerFont
                        font-size: $fontSizeStandard-1
                      .highlight
                        font-weight: 600
                      .marginRight
                        margin-right: $paddingMedium
                    &__info
                      color: $colorTextLight
                      font-style: italic
                      margin: 0 $paddingMedium
          &__activities
            flex-basis: 100%
            &__item
              margin-top: $paddingSmall
              &:first-of-type
                margin-top: 0

.agileBoardAddActivityWrapper
  &__agileBoardAddActivity
    &__content
      margin: $paddingBig * 1.5 0 $paddingMedium 0
      display: flex
      flex-wrap: wrap
      h4
        margin-bottom: $paddingSmall
        text-transform: none
      &__isPerformed
        width: calc(40% - (#{$paddingBig} / 2))
        margin-right: calc(#{$paddingBig} / 2)
        margin-bottom: $paddingMedium
        &__items
          display: flex
          flex-wrap: wrap
          .actionButtonWrapper
            margin-bottom: $paddingSmall
            margin-right: $paddingSmall
            &:last-of-type
              margin-left: 0
      &__actions
        width: calc(60% - (#{$paddingBig} / 2))
        margin-left: calc(#{$paddingBig} / 2)
        margin-bottom: $paddingMedium
        &.disabled
          pointer-events: none
          h4
            color: $grey-400
          .agileBoardAddActivityWrapper__agileBoardAddActivity__content__actions__items__item
            color: $grey-300
            background: $grey-50
            border-color: $grey-100
            svg
              fill: $grey-300
        &__items
          display: flex
          flex-wrap: wrap
          .actionButtonWrapper
            margin-bottom: $paddingSmall
            margin-right: $paddingSmall
            &:last-of-type
              margin-right: 0
      &__date
        width: calc(40% - (#{$paddingBig} / 2))
        margin-right: calc(#{$paddingBig} / 2)
        //Stupid hacky fix to add error message and still have input fields line up
        h4
          display: inline-block
          margin-bottom: 6px
          &:nth-child(2)
            color: red
            padding-left: 5px
        &:hover
          .react-datepicker-wrapper
            input
              border-color: $colorLink !important
        &.active
          .react-datepicker-wrapper
            input
              border-color: $grey-400
        &.disabled
          pointer-events: none
          h4
            color: $grey-400
          .react-datepicker-wrapper
            input
              color: transparent
              border-color: $grey-300
        .react-datepicker-wrapper
          display: block
          input
            width: 100%
            border-color: $colorLink
            height: 32px
            cursor: pointer
      &__comment
        width: calc(60% - (#{$paddingBig} / 2))
        margin-left: calc(#{$paddingBig} / 2)
        &:hover
          textarea
            border-color: $colorLink !important
        &.active
          textarea
            border-color: $grey-400
        &.disabled
          pointer-events: none
          h4
            color: $grey-400
          textarea
            border-color: $grey-300
        textarea
          width: 100%
          min-width: 100%
          border-color: $colorLink
          height: 32px

.agileBoardPopup__dealType
  display: flex
  flex-wrap: wrap
  justify-content: space-evenly
  align-items: center
  flex-direction: row
  width: 100%
  &__content
    width: 100%
    display: flex
    justify-content: space-evenly
    flex-direction: row
    &__buttons
      display: flex
      justify-content: center
      gap: $paddingSmall
      flex-direction: row
      position: relative
      align-content: center
      align-items: center
      &__button
        text-decoration: none
        padding: 5px 0
    &__container
      display: flex
      justify-content: space-evenly
      flex-direction: row

.agileBoardPopupWrapper
  height: 100%
  min-height: 60vh
  background: white
  &__agilePopup
    height: 100%
    display: flex
    flex-direction: column
    &__header
      box-shadow: 1px 1px 1px $grey-300
      background: $grey-50
      @extend .shadowSoft
      &__gridResponsive
        display: grid
        grid-template-columns: 6fr 6fr
        //grid-template-columns: 4fr 5fr 3fr
        justify-content: center
        grid-gap: 1rem

      &__buttons
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-start
        cursor: pointer
        padding: $paddingSmall 0px $paddingSmall $paddingSmall
        background-color: $grey-100
        border: 1px solid $grey-100
        margin-left: $paddingSmall
        min-width: 10vh
        height: 35px
        &:hover
          background: transparent
          border-color: $colorLink
          color: $colorLink
        @media screen and (max-width: 768px)
          display: inline
          width: 100%
          align-items: stretch
          justify-content: center
          height: auto
          flex-wrap: nowrap
        &__hamburgerMenuItems
          width: 100%
          cursor: pointer
          display: flex
          padding: $paddingSmall 0px
          &:hover
            background: transparent
            border-color: $colorLink
            color: $colorLink
        &__text
          font-size: 10px
          white-space: nowrap
          text-overflow: ellipsis
          overflow: hidden
      &__subText
        font-size: 15px
        width: 100%
        display: flex
        &__left
          color: $grey-500
          min-width: 150px
          @media screen and (max-width: 500px)
            min-width: 70px

        &__container
          margin-bottom: 5px
        &__inlineFlex
          display: inline-flex
          @media screen and (max-width: 1000px)
            display: inline
        &__text
          //margin-left: 4px
      &__subHeadline
        cursor: pointer
        border-bottom: 1px solid $grey-300
        padding-bottom: $paddingSmall
      &__top
        //border-bottom: 1px solid $grey-300
        display: flex
        justify-content: space-between
        align-items: center
        &__left
          position: relative
          width: 100%
          display: flex
          justify-content: space-between
          padding: $paddingBig $paddingMedium * 1.5
          @media (max-width: 768px)
            flex-direction: column
            gap: $paddingMedium
        &__right
          position: absolute
          top: 0
          right: 0
          width: 20px
          box-shadow: 2px 2px 2px 2px
          margin-top: $paddingSmall
          svg
            width: 20px
            height: 20px
            cursor: pointer
            &:hover
              fill: $colorLink
        & .contractInfoWrapper
          display: flex
          align-items: center
          gap: 12px
          margin-top: 16px
          svg
            fill: $colorPrimary-700
      &__bottom
        margin-left: $paddingSmall
        display: flex
        align-items: center
        justify-content: flex-start
        @media screen and (max-width: 768px)
          padding: 2px
          gap: 10px

        &__item
          margin-left: $paddingSmall
          cursor: pointer
          &:first-of-type
            margin-left: 0
          &__disabled
            @extend .agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item
            pointer-events: none
            svg
              fill: $grey-300
          @media screen and (max-width: 768px)
            margin-left: 0
    &__dealtype
      width: 100%
      &__content
        &__buttons
          display: flex
          justify-content: center
          gap: $paddingSmall
          flex-direction: row
          position: relative
          align-content: center
          align-items: center
          @media only screen and (max-width: 565px)
            flex-direction: row
        &__button
          position: relative
          text-decoration: none
          padding: 5px 0
    &__content
      overflow-y: auto
      overflow-x: hidden
      //display: grid
      //grid-template-columns: repeat(2, 1fr)
      @extend .scrollbarRegular
      padding: $paddingMedium $paddingMedium ($paddingMedium * 2) $paddingMedium
      flex-grow: 1
      scroll-behavior: smooth
      display: flex
      flex-wrap: wrap
      svg
        width: 14px
        height: 14px
      &__icons
        &__buttons
          display: flex
          position: sticky
          top: 0
          align-items: center
          z-index: 1
          padding: $paddingSmall 0px 0px $paddingSmall
          margin-bottom: $paddingMedium
          &__iconContainer
            display: flex
            align-items: center
            justify-content: flex-end
          &__hamburgerIcon
            display: flex
            align-items: center
            cursor: pointer
            margin-left: 10px
            margin-right: 10px
            svg
              display: flex
              align-items: center
      &__center
        display: flex
        flex-grow: 0
        justify-content: center
        align-items: center
        flex-direction: row
        text-align: center
        margin-top: 25px
        &__flexEnd
          display: flex
          justify-content: flex-end
          align-items: center
          svg
            cursor: pointer
            min-width: 16px
            min-height: 16px
            max-width: 32px
            max-height: 32px
            fill: $colorLink
          &:hover
            fill: $colorLinkHover
        svg.active
          fill: $colorLinkHover !important
      &__deal
        width: 100%
        &__subContent
          width: 50%
        &__type-label
          display: flex
          align-items: flex-end
          color: $grey-500
          //margin-right: 4px
          font-size: 15px
          width: 150px

        &__completeType
          display: flex
          flex-direction: column
          justify-content: space-between
          gap: $paddingSmall

        &__flexContainer
          display: flex
          width: 100%
          &__subContent
            display: flex
            width: 100%
            flex-direction: column
            //justify-content: space-between
          &__column
            display: flex
            flex-direction: column
            width: fit-content

          &__row
            display: flex
            flex-direction: row
            align-items: center
            p
              font-size: 15px

        &__container
          display: flex
          justify-content: flex-end
          flex-direction: row
          margin-bottom: 10px
          &__center
            display: flex
            justify-content: center
            align-items: center
          &__spaceBetween
            display: flex
            justify-content: space-between
            align-items: center
      &__block
        margin: $paddingSmall 0px $paddingSmall 0px
        // width: 50%
        &:last-child(odd)
          width: 100%
        &:last-child:nth-child(odd)
          width: 100%
        &__custom
          width: 100%
          margin: 10px 0 10px 0
          &__deal
            margin-bottom: 4px
            width: 90%
            display: flex
            flex-direction: column
            gap: 10px
            justify-content: center
            height: 50%
          &__margin
            margin: 16px 0 0 0
            width: 100%
        &__header
          margin-bottom: 25px
          cursor: pointer
          position: relative
          border-top: 1px solid $grey-300
          color: $colorTextLight
          &:hover
            color: $colorLink
            border-color: $colorLink
          &:not(:first-child)
            margin-top: $paddingBig * 2
          &__icon
            border: 2px solid $grey-300
            border-radius: 50%
            margin-left: 1px
            margin-right: 1px
          &__title
            display: flex
            align-items: center
            position: absolute
            top: -14px
            right: $paddingMedium
            padding: 4px 4px 4px 8px
            background: white
            svg
              width: 18px
              height: 18px
            &__custom
              display: flex
              align-items: center
              padding: 4px 0px 4px 0px
              background: white
              color: $colorTextLight
        &__dropdown
          text-align: center
          align-content: center
          display: flex
          justify-content: center
          align-items: center
        &__buttonContainer
          display: flex
          width: 100%
          text-align: center
          justify-content: center
          margin-top: 16px
          gap: $paddingSmall
        &__content
          display: flex
          flex-direction: column
          justify-content: flex-start
          flex-wrap: wrap
          width: 98%
          &__dropdown
            display: flex
            justify-content: flex-start
            flex-wrap: wrap
            width: 100%
          &__row
            padding: $paddingMedium
            display: flex
            align-items: center
            justify-content: space-between
            flex-basis: 100%
            &__custom
              display: flex
              align-items: center
              justify-content: space-between
              flex-basis: 100%
            &__left
              display: flex
              align-items: center
              &__content
                margin: $paddingMedium $paddingMedium
                .lightAndItalic
                  font-style: italic
                  color: $colorTextLight-1
                .highlight
                  font-weight: 600
                  display: inline-flex
                  align-items: center
                  svg
                    fill: $colorEventPassedDate
                .marginLeft
                  margin-left: 4px
                .marginRight
                  margin-right: 4px
                .extraMarginRight
                  margin-right: $paddingSmall
                &__additionalLine
                  display: block
                  margin-left: ($paddingSmall + 16px)
            &__right
              display: flex
              .tooltipWrapper
                margin-left: $paddingSmall
          &__dealType
            width: 100%
            margin-bottom: 1px
          &__completeDeal
            display: flex
            justify-content: space-between
            gap: $paddingSmall
            .completeDealAsLost
              background: $colorFailOpacity
              border-color: $colorFail
              color: white
              svg
                fill: white
            .completeDealAsWon
              background: $colorSuccessOpacity
              border-color: $colorSuccess
              color: white
              svg
                fill: white
          &__center
            display: flex
            justify-content: center
            flex-wrap: wrap
          &__flexContainer
            width: auto
            display: flex
            gap: $paddingSmall
          &__contact
            flex-basis: 100%
            &:first-child
              margin-top: 0
          &__prospectOrCar
            flex: 1
            > .tooltipWrapper .inlineTooltip
              width: 100%
            &:first-of-type
              margin-top: 0
            &__header
              display: flex
              align-items: center
              justify-content: flex-start
              cursor: pointer
              padding: $paddingSmall
              width: 100%
              background-color: $grey-100
              border: 1px solid $grey-100
              &:hover
                background: transparent
                border-color: $colorLink
                color: $colorLink
              &__title
                font-size: 10px
              &__left
                display: flex
                align-items: center
                flex: 1
                p
                  font-weight: 600
                svg
                  margin-right: $paddingMedium
              &__right
                display: flex
                align-items: center
                justify-content: flex-end
            &__activities
              flex-basis: 100%
            &__item
              margin-top: $paddingSmall
              &:first-of-type
                margin-top: 0
            &__content
              display: flex
              flex-direction: column
              &__fleetHolder
                display: flex
                margin-right: -10px
                &__title
                  color: $colorTextLight-1
                &__type
                  padding-bottom: 20px
                  &__header
                    display: flex
                    align-items: center
                    margin-top: $paddingMedium
                    p
                      margin-left: $paddingSmall
                      font-size: $fontSizeStandard-1
                      font-weight: 600
                      text-transform: uppercase
                  &__content
                    &__vehicle
                      margin: 4px $paddingMedium
                      &:hover
                        .regNumber
                          color: $colorLink
                      .tooltipWrapper__tooltip__indicator
                        display: inline
                      .regNumber
                        cursor: pointer
                        &:hover
                          color: $colorLinkHover
                      .smallerFont
                        font-size: $fontSizeStandard-1
                      .highlight
                        font-weight: 600
                      .marginRight
                        margin-right: $paddingMedium
                      &__info
                        color: $colorTextLight
                        font-style: italic
                        margin: 0 $paddingMedium
          &__input
            display: flex
            align-items: center
          &__infoRow
            display: flex
            flex-direction: row
            padding: $paddingSmall * 0.2
            @media screen and (max-width: 900px)
              flex-direction: column
            &__left
              color: $colorTextLight-1
              margin-right: $paddingSmall
              word-break: break-word
              width: 120px
              min-width: 120px
            &__right
              display: flex
              flex-grow: 1
              white-space: pre-wrap
              flex-wrap: wrap
              &.noFlexWrap
                flex-wrap: unset
              svg
                cursor: pointer
                width: 18px
                height: 18px
                fill: $colorLink
                margin-left: $paddingSmall
                position: relative
                top: 2px
                &:hover
                  fill: $colorLinkHover
              .dropdownWrapper__dropdown__header
                align-items: flex-start !important
                min-height: unset !important
                height: unset !important
                padding: 4px !important
                svg
                  height: 18px !important
                  width: 18px !important
                  fill: $colorTextLight !important
              &__icons
                display: flex
                padding-left: $paddingBig
                min-width: 100px
                justify-content: flex-end
                // flex-grow: 1
                // justify-content: flex-end
.tooltip-container
  display: flex
  justify-content: flex-end
  position: absolute
  right: 5px
  top: 5px
  &:hover
    cursor: pointer
.row-container
  display: flex
  flex-direction: column
  gap: $paddingSmall * 0.5
.row-container > div
  display: flex
.row-container > div > p:first-child
  width: 125px
