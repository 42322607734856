.widgetHeaderWrapper
  &.widgetHeaderResponsiveWidth1
    .widgetHeaderWrapper__widgetHeader__left
      h2
        word-break: break-word
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        line-height: $fontSizeStandard6
      h3
        word-break: break-word
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
  &__widgetHeader
    display: flex
    justify-content: space-between
    align-items: stretch
    gap: $paddingMedium
    &__left
      max-width: calc(100% - (22px + #{$paddingMedium}))
      color: $colorTextMain
      display: inline-block
      span.listBullet
        margin: 0 $paddingSmall
      h2
        font-size: $fontSizeStandard5
        line-height: $fontSizeStandard5
        font-weight: 700
        text-transform: none
        span
          font-size: $fontSizeStandard5
          line-height: $fontSizeStandard5
          font-weight: 700
      h3
        font-size: $fontSizeStandard1
        font-weight: normal
        color: $colorTextLight
        text-transform: none
        line-height: $fontSizeStandard3
        margin-top: 4px
        span
          font-size: $fontSizeStandard1
          font-weight: normal
          color: $colorTextLight
          line-height: $fontSizeStandard2
    &__default
      width: 100%
    &__center
      display: flex
      justify-content: center
      width: 100%
    &__right
      display: flex
      justify-content: flex-end
      &__dashboard
        &__button
          cursor: pointer
          svg
            width: 22px
            height: 22px
            fill: $colorLink
            &:hover
              fill: $colorLinkHover
