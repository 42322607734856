// Leads component
.leadsWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__leads
    display: flex
    flex-direction: column
    flex-grow: 1
    &__content
      display: flex
      justify-content: flex-start
      flex-direction: column
      flex-grow: 1
      &__left
        display: flex
        flex-direction: column
        flex-grow: 1
        &__bottom
          background: white
          padding: $paddingMedium $paddingGrid 0 $paddingGrid
          flex-grow: 1
          display: flex
          flex-direction: column
      &__right
        position: fixed
        top: 0
        right: 0
        z-index: 7
        height: 100vh
        @extend .shadowPreview
        @extend .widgetWidthAnimation
        width: $widthPreviewWindow
        max-width: calc(100vw - #{$widthMinimizedNavigation})
        &.minimize
          @extend .widgetWidthAnimationMinimized
  &__movingLeads
    background: rgba(0, 0, 0, 0.2)
    cursor: default
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 9
    display: flex
    align-items: flex-start
    justify-content: center
    &__content
      height: 100%
      width: 100%
      display: flex
      align-items: center
      justify-content: center

// External leads info popup
.externalLeadsWrapper
  &__externalLeads
    &__content
      &__section
        margin: 20px 0
        p
          margin: 10px 0
        h4
          text-transform: none
        &__imgContainer
          max-width: 100%
          img
            width: 100%
            height: auto
            border: 1px solid $grey-200

// Keep style similar between ProspectResultPreview, AgileBoardPreview and LeadsPreview
.leadsPreviewWrapper
  height: 100%
  background: white
  &__leadsPreview
    height: 100%
    display: flex
    flex-direction: column
    &__header
      background: $grey-50
      @extend .shadowSoft
      &__top
        margin: 0 $paddingBig
        padding: $paddingBig 0
        border-bottom: 1px solid $grey-300
        display: flex
        justify-content: space-between
        &__left
          max-width: calc(100% - 20px - #{$paddingMedium})
        &__right
          width: 20px
          margin-left: $paddingMedium
          svg
            width: 20px
            height: 20px
            cursor: pointer
            &:hover
              fill: $colorLink
      &__bottom
        padding: $paddingMedium ($paddingBig + $paddingSmall)
        display: flex
        align-items: center
        justify-content: flex-start
        svg
          cursor: pointer
          width: 16px
          height: 16px
          fill: $colorLink
          &:hover
            fill: $colorLinkHover
        svg.active
          fill: $colorLinkHover !important
        &__item
          margin-left: $paddingBig
          &:first-of-type
            margin-left: 0
    &__content
      overflow-y: auto
      overflow-x: hidden
      //@extend .scrollbarSmallDark
      @extend .scrollbarRegular
      padding: $paddingMedium $paddingMedium ($paddingMedium * 2) $paddingMedium
      flex-grow: 1
      scroll-behavior: smooth
      &__block
        margin-top: ($paddingBig * 2)
        &:first-of-type
          margin-top: $paddingBig
        &__header
          cursor: pointer
          position: relative
          border-top: 1px solid $grey-300
          color: $colorTextLight-1
          &:hover
            color: $colorLink
          &__title
            display: flex
            align-items: center
            position: absolute
            top: -14px
            right: $paddingMedium
            padding: 4px
            background: white
            svg
              width: 18px
              height: 18px
        &__content
          display: flex
          flex-wrap: wrap
          margin-top: $paddingBig
          &__actions
            flex-basis: 100%
            display: flex
            align-items: center
            justify-content: space-evenly
            &__item
              cursor: pointer
              display: flex
              align-items: center
              justify-content: center
              padding: $paddingMedium
              border: 1px solid $grey-300
              border-radius: 50%
              &:hover
                border: 1px solid $colorLink
                svg
                  fill: $colorLink
              svg
                width: 24px
                height: 24px
                fill: $grey-300
          &__infoRow
            display: flex
            flex-basis: 100%
            margin-bottom: 4px
            &__left
              color: $colorTextLight-1
              margin-right: $paddingSmall
              word-break: break-word
              width: 32%
              min-width: 32%
            &__right
              display: flex
              flex-grow: 1
              white-space: pre-wrap
              word-break: break-word
              flex-wrap: wrap

// Leads upload component
.leadsUploadWrapper
  &__avtalskunder
    &__header
      .widgetHeaderWrapper
        &__widgetHeader
          &__left
            h2
              color: $grey-800
  &__leadsUpload
    &__header
      &.widgetHeaderWrapper_widgetHeader
        h2
          color: red
    &__content
      margin-top: $paddingBig
      img
        max-width: 100%
      p
        margin: $paddingMedium 0
        span
          font-weight: bold
      & .widgetFooterWrapper__widgetFooter
        border-top: none
      &__tableWrapper
        max-height: 55vh
        overflow: auto
        -webkit-overflow-scrolling: touch
        .MuiTableContainer-root
          overflow: visible
        .MuiFormHelperText-root
          padding: 10px 0
          &.selected
            color: $colorSuccess
          &.not-selected
            color: $colorFail
        .MuiTableCell-root
          position: relative
          white-space: nowrap
          text-overflow: ellipsis
          max-width: 200px
          overflow: hidden
        .MuiNativeSelect-root
          min-width: 150px
        .MuiNativeSelect-select
          font-size: 12px
          padding-left: 15px
      &__error
        color: $deep-orange-600
      &__new
        padding: $paddingBig * 1.5 0 $paddingBig * 1.25
        display: flex
        flex-direction: column
        gap: $paddingSmall
        & .leadsUploadWrapper__leadsUpload__content__input
          input
            padding: $paddingSmall $paddingBig
            background-color: $grey-50
      &__lists
        padding: $paddingBig 0
        display: flex
        flex-direction: column
        gap: $paddingSmall
        &__list
          display: flex
          align-items: center
          &__checkbox
            display: flex
            align-items: center
            margin-left: .5em
          &__checkbox
            svg
              width: 14px
              height: 14px

.leadsPopupWrapper
  &__leadsPopup
    &__content
      margin: $paddingBig 0
.moveAllLeadsWrapper
  min-height: 70vh
  & .typeSelectionWrapper
    display: flex
    padding: 12px 6px
    justify-content: center
    gap: 18px
  & .listForm
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 36px
    gap: 24px
    & > input
      max-width: 300px
    & .buttonContainer
      padding-top: 36px
      display: flex
      gap: 12px
  & .existingViewWrapper
    padding: $paddingBig
    & .textWrapper
      display: flex
      flex-direction: column
      align-items: center
      gap: $paddingMedium
    & .listName
      font-weight: 700
    & .buttonWrapper
      display: flex
      gap: $paddingBig * 2
      padding: $paddingBig
  & .button
    border: none
    background-color: transparent
    padding: 6px 24px
    &:hover
      cursor: pointer
      background-color: $grey-50
  & .button.active
    border-bottom: 2px solid blue
  & .button.confirm
    background-color: $colorPrimary-100
    &:hover
      background-color: $colorPrimary-500
      color: $grey-50
  & .button.cancel
    background-color: $grey-100
    &:hover
      background-color: $grey-200
