.summaryWrapper
    width: 100%
    height: 100%
    display: flex
    justify-content: space-between
    flex-direction: column
    flex-wrap: wrap
    gap: 1rem
    opacity: 1
    background: white
    &__popup
        justify-content: center
    &__message
        max-height: 100px
        width: 100%
        background-color: $colorPrimary-500
        padding: 1rem
        color: white
        text-align: center
        h2
            margin-bottom: 0.5rem
            text-transform: none
    &__message::after
        background-color: $colorPrimary-500
    &__itemWrapper
        width: 100%
        height: auto
        display: grid
        grid-template-columns: repeat(5, 1fr)
        grid-column-gap: 0px
        grid-row-gap: 1rem
        transition: 1s
    &__item
        height: auto
        margin-bottom: 1em
        display: flex
        opacity: 0
        flex-direction: column
        align-items: center
        animation: slideInTop .5s forwards 1 ease-out
        transition: 1s
        text-align: center
        &__amount
            font-weight: 600
            font-size: 2rem
        h2
            margin: 2px 0 2px 0
            &::after
                transition: num 5s
                content: counter(attr(data-amount))
        &:nth-of-type(2)
            animation-delay: 1s
        &:nth-of-type(3)
            animation-delay: 1.5s
        &:nth-of-type(4)
            animation-delay: 2s
        &:nth-of-type(5)
            animation-delay: 2.5s
        &:nth-of-type(6)
            animation-delay: 3s
        &:nth-of-type(7)
            animation-delay: 3.5s
        &:nth-of-type(8)
            animation-delay: 4s
        &:nth-of-type(9)
            animation-delay: 4.5s
        &:nth-of-type(10)
            animation-delay: 5s
        &__iconWrapper
            position: relative
            &__icon
                margin-bottom: 0.5rem
                padding: 0.5rem
                border: 2px solid #ffc908
                border-radius: 50%
                display: grid
                place-content: center
                background-color: white
            &__celebrate
                position: absolute
                color: #ffc908
                top: -10%
                left: 55%
                animation: grow 1s infinite alternate-reverse
    &__totalWrapper
        width: 100%
        max-height: 100px
        display: flex
        justify-content: flex-end
        padding: 1rem
        border-top: 2px solid
        border-color: $grey-400
        opacity: 1
        transition: 0.3s
        &__hidden
            opacity: 0

@keyframes slideInTop
    0%
        opacity: 0
        transform: translateY(-100%)
    90%
        opacity: 1
        transform: translateY(0%)
    100%
        opacity: 1
        transform: translateY(0%)
@keyframes grow
    0%
        transform: scale(80%)
    100%
        transform: scale(100%)
