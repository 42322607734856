.routerWrapper
  &__router
    display: flex
    justify-content: space-between
    align-items: stretch
    &__left
      position: fixed
      top: 0
      left: 0
      // z-index: 7
      height: 100vh
      width: $widthNavigation
      max-width: $widthNavigation
      min-width: 0
      @extend .widgetWidthAnimation
      &.moduleMode
        width: 0 !important
      &.regular
        &.responsiveMode
          width: 100%
          max-width: 100%
      &.hidden
        width: 0
      &.minimize
        @extend .widgetWidthAnimationMinimized
        opacity: 1
        overflow: visible
        width: $widthMinimizedNavigation
        max-width: $widthMinimizedNavigation !important
    &__center
      background: $grey-100
      flex-grow: 1
      min-width: 0
      margin-left: calc(#{$widthNavigation} - 1px)
      min-height: 100vh
      display: flex
      flex-direction: column
      justify-content: flex-start
      @extend .widgetWidthAnimation
      &.responsiveMode
        margin-left: 0 !important
      &.moduleMode
        margin-left: 0 !important
      &.minimize
        margin-left: calc(#{$widthMinimizedNavigation} - 1px)
    &__right
      position: fixed
      top: 0
      right: 0
      z-index: 7 // Dynamically changed in component.
      height: 100vh
      @extend .shadowPreview
