@media (max-width: $breakpoint2)
  .popupWrapper
    &__popup
      width: 70%
      &.big
        width: 80% !important
      &.medium
        width: 60% !important
      &.small
        width: 44% !important
      &.onlyContent
        width: unset !important

@media (max-width: $breakpoint3)
  .popupWrapper
    &__popup
      width: 86%
      &.big
        width: 86% !important
      &.medium
        width: 76% !important
      &.small
        width: 60% !important
      &.onlyContent
        width: unset !important

@media (max-width: $breakpoint6)
  .popupWrapper
    padding: 4% 0
    &__popup
      width: 86%
      &.big
        width: 86% !important
      &.medium
        width: 86% !important
      &.small
        width: 86% !important
      &.onlyContent
        width: unset !important

