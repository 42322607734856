.label {
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
  }
}
.input {
  appearance: auto;
  width: auto;
  display: flex;
  &:hover {
    cursor: pointer;
    border: 1px solid var(--colorPrimary-100);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
