.ordersWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__orders
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__navigationMenu
      margin: $paddingBig $paddingBig + $paddingMedium 0 $paddingBig + $paddingMedium
      position: relative
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      padding: $paddingBig $paddingBig 0 $paddingBig
      span.label
        @extend .orderInformationLabel
      p.paddedBottom
        @extend .orderInformationParagraphPaddedBottom
      .margin
        margin: 0 4px
      strong
        font-weight: 600
      .marginBottom
        margin-bottom: $paddingMedium
      &__top
        display: flex
        flex-wrap: wrap
        align-items: flex-start
        &.bottomMargin
          margin-bottom: $paddingBig
        &__left
          width: calc(50% - (#{$paddingBig} / 2))
          margin: 0 calc(#{$paddingBig} / 2) 0 0
        &__right
          width: calc(50% - (#{$paddingBig} / 2))
          margin: 0 0 0 calc(#{$paddingBig} / 2)
        &__section
          width: 100%
          margin-top: $paddingMedium
          padding: $paddingMedium
          &:nth-child(1)
            margin-top: 0
          p
            margin: $paddingMedium 0 0 0
            &:first-child
              margin-top: 0
          .marginLeft
            margin-left: 6px
          span.link
            cursor: pointer
            color: $colorLink
            &:hover
              color: $colorLinkHover
          &__header
            margin-bottom: $paddingMedium
      &__bottom
        display: flex
        flex-direction: column
        flex-grow: 1
        &__menu
          margin: 4px 0 $paddingBig 0
          border-bottom: 1px solid $grey-300
          padding-bottom: calc(#{$paddingBig} * 1.4)
        &__orderInformation
          display: flex
          flex-wrap: wrap
          margin-bottom: $paddingBig
          &__left
            width: calc(50% - (#{$paddingBig} / 2))
            margin-right: calc(#{$paddingBig} / 2)
            padding: $paddingMedium
            .collapsibleWrapper__collapsible__content
              .ordersWrapper__orders__content__bottom__orderInformation__left__section
                &:first-of-type
                  margin-top: $paddingBig
            &__section
              margin-bottom: $paddingBig + $paddingMedium
              &:last-of-type
                margin-bottom: 0
              &__title
                margin-bottom: $paddingMedium
              &__content
                margin-bottom: $paddingBig
                .highlightWrapper
                  margin-top: $paddingSmall
                p
                  margin: $paddingMedium 0 0 0
                  &:first-child
                    margin-top: 0
                  &.noMargin
                    margin: 0
                &__flexHolder
                  margin: $paddingBig 0
                  display: flex
                  align-items: flex-start
                  justify-content: flex-start
                  flex-direction: column
                  gap: $paddingGrid
          &__right
            width: calc(50% - (#{$paddingBig} / 2))
            margin-left: calc(#{$paddingBig} / 2)
            padding: $paddingMedium

.ordersInformationWrapper
  &__ordersInformation
    &__content
      &__section
        margin-bottom: $paddingBig * 2
        &:last-of-type
          margin-bottom: 0
        &__block
          margin-bottom: calc(#{$paddingBig} * 1.5)
          &.approved
            display: flex
            align-items: center
            .iconStyled
              margin-right: $paddingMedium
            //svg
            //  width: 18px
            //  height: 18px
            //  margin-right: $paddingSmall
          //.approvedIcon
          //  display: flex
          //  align-items: center
          //  justify-content: center
          //  height: $heightSmallRoundIcon
          //  min-width: $heightSmallRoundIcon
          //  width: $heightSmallRoundIcon
          //  background: $colorSuccess
          //  border-radius: 50%
          //  margin-right: $paddingSmall
          //  svg
          //    height: $heightSmallRoundIconSvg
          //    width: $heightSmallRoundIconSvg
          //    fill: white
          h4
            text-transform: none
          span.label
            @extend .orderInformationLabel
          strong.highlight
            //background: linear-gradient(to top, $colorSecondary-600, $colorSecondary-400)
            font-weight: 700
            //margin: 0 5px
            //font-size: $fontSizeStandard4
            //padding: 2px 7px
            //border-radius: 8px
            //color: white
          strong
            font-weight: 600
          div.flexParent
            display: flex
            margin: 4px 0
            flex-wrap: wrap
            gap: $paddingMedium
            &.marginTop
              margin-top: $paddingMedium
          .marginTop
            margin-top: $paddingMedium
          .includeValue
            display: flex
            align-items: center
            cursor: pointer
            color: $colorLink
            &:hover
              color: $colorLinkHover
              svg
                fill: $colorLinkHover
            &.disabled
              pointer-events: none
              color: $colorTextLight-2
              svg
                fill: $colorTextLight-2
            svg
              margin-right: $paddingSmall
              width: 14px
              height: 14px
              fill: $colorLink
          .priceSum
            margin: $paddingMedium 0
            border-top: 1px solid $grey-300
            padding-top: $paddingMedium
            font-size: $fontSizeStandard4
            font-weight: 700
        &__title
          margin-bottom: $paddingMedium
    &__footer
      margin-top: $paddingSmall
      & .minHeight40
        min-height: 40px

.orderInformationLabel
  display: inline-block
  color: $colorTextMain
  margin: 0 6px 4px 0
  font-weight: 600
  font-size: $fontSizeStandard1

.orderInformationParagraphPaddedBottom
  padding-bottom: $paddingMedium
