.wrapper {
  min-height: 50vh;
  width: 100%;
  display: flex;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 12px;
}

.activity {
  padding: 12px;
  border: 1px solid var(--colorSecondary-700);
  border-radius: 12px;
  font-size: 14px;
  width: 80%;
  display: flex;
  align-items: center;
}

.activityInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4px;
}

.icon {
  justify-self: flex-start;

  width: 26px;
  height: 26px;
}

.header {
  font-size: 18px;
}
