@media (max-width: $breakpoint6)
  .colleaguesRowsWrapper
    &__colleaguesRows
      &__content
        &__colleagues
          &__dealer
            &__users
              &__item
                width: 100%
                &:nth-child(odd)
                  margin-left: 0
                  margin-right: 0
                &:nth-child(even)
                  margin-left: 0
                  margin-right: 0
