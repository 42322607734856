.resetPasswordWrapper
	width: 100vw
	&__resetPassword
		margin: $paddingMedium auto
		padding: $paddingBig * 3 0
		width: 94%
		max-width: $widthHomepageContent
		display: flex
		flex-direction: column
		align-items: center
		background-image: url('../../images/bilprospekt_logo_blue.png')
		background-size: 120px
		background-repeat: no-repeat
		background-position: 0 0
		&__header
			display: flex
			justify-content: center
			margin: 0 $paddingBig
			max-width: 380px
			width: calc(100% - (#{$paddingBig} * 2))
			h2
				text-transform: none
				color: $colorTextMain
				font-weight: 700
				font-size: $fontSizeStandard9
				line-height: $fontSizeStandard9
		&__content
			display: flex
			flex-direction: column
			align-items: center
			margin: $paddingBig
			max-width: 360px
			width: calc(100% - (#{$paddingBig} * 2))
			&__info
				span.label
					font-size: $fontSizeStandard2
					color: $colorTextLight
				span.name
					color: $colorTextMain
					font-size: $fontSizeStandard2
					font-weight: 600
					margin-left: $paddingSmall
					margin-bottom: $paddingSmall
			&__inputs
				width: 100%
				&__row
					display: flex
					justify-content: flex-start
					align-items: flex-end
					width: 100%
					input
						width: calc(100% - #{$paddingSmall} - 16px)
						margin-top: $paddingBig
						margin-left: 16px
					&__icon
						width: 16px
						margin-left: $paddingSmall
						svg
							width: 16px
							height: 16px
							fill: $colorLink
			&__hint
				height: 20px
				margin-top: $paddingSmall
				color: $colorFail
				font-size: $fontSizeStandard-1
				margin-left: 16px
		&__footer
			max-width: 360px
			width: calc(100% - (#{$paddingBig} * 2))
			margin: 0 $paddingBig
			display: flex
			justify-content: space-between
