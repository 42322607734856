.wrapper {
  box-shadow: 0 0 15px 0 rgba(70, 110, 211, 0.2) inset;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    justify-content: start;
  }
}
