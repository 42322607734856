.dashboardWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  height: 100vh
  &__dashboard
    display: flex
    flex-direction: column
    flex-grow: 1
    overflow: hidden
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      overflow: auto
      @extend .scrollbarRegular
