@media (max-width: $breakpoint4)
	.carInfoWrapper
		&__carInfo
			&__content
				&__item
					width: calc(50% - ((1 * 2px) / 2))
					&:nth-child(3n)
						margin-right: 0
					&:nth-child(even)
						margin-right: 0
					&:nth-child(odd)
						margin-right: 2px
	.carVehicleDataWrapper
		&__carVehicleData
			&__content
				&__block
					&__content
						&__item
							width: calc(50% - ((1 * 2px) / 2))
							&:nth-child(3n)
								margin-right: 0
							&:nth-child(even)
								margin-right: 0
							&:nth-child(odd)
								margin-right: 2px

@media (max-width: $breakpoint6)
	.carUserHistoryWrapper
		&__carUserHistory
			&__content
				&__row
					&__leftLabel
						width: 66%
					&__centerLabel
						display: none
					&__rightLabel
						width: calc(34% - #{$paddingBig})
					&__left
						width: 66%
					&__center
						display: none
					&__right
						width: calc(34% - #{$paddingBig})

@media (max-width: $breakpoint8)
	.carInfoWrapper
		&__carInfo
			&__content
				&__item
					width: 100%
					margin-right: 0
					&:nth-child(odd)
						margin-right: 0
	.carVehicleDataWrapper
		&__carVehicleData
			&__content
				&__block
					&__content
						&__item
							width: 100%
							margin-right: 0
							&:nth-child(odd)
								margin-right: 0

@media (max-width: $breakpoint10)
	.carUserHistoryWrapper
		&__carUserHistory
			&__content
				&__row
					&__leftLabel
						width: 100%
					&__rightLabel
						display: none
					&__left
						width: 100%
					&__right
						display: none
