.popupHolderWrapper
  &__popupHolder
    &__popupContent
      &__header
        margin-bottom: $paddingBig
      &__content
        &__section
          &__row
            display: flex
            align-items: center
            padding: $paddingMedium
            &__left
              margin-right: $paddingMedium
              flex-shrink: 0
              display: flex
              align-items: center
            &__right
              flex-grow: 1
              p
                font-size: $fontSizeStandard1
                line-height: $fontSizeStandard4
                font-weight: 600
                color: $colorTextMain
                &.label
                  color: $colorTextLight
                  font-size: $fontSizeStandard-1
                  line-height: $fontSizeStandard2
                  font-weight: 400

