.createListSubscriptionWrapper
  &__createListSubscription
    &__content
      p
        margin-bottom: $paddingMedium
      span
        &.bullet
          margin: 0 $paddingSmall
        &.marginRight
          margin-right: $paddingSmall
      &__section
        margin-top: $paddingBig
        padding-top: $paddingBig
        border-top: 1px solid $grey-300
        &:first-child
          border: 0
          margin-top: 0
          padding-top: 0
        &__sectionHeader
          margin-bottom: $paddingMedium
        &__listHolder
          display: flex
          flex-wrap: wrap
          span.bullet
            margin: 0 $paddingSmall
          p
            margin: 0
            display: flex
            flex-wrap: wrap
          &__left
            font-weight: 600
            white-space: nowrap
            margin-right: $paddingSmall
          &__right
            display: flex
            flex-wrap: wrap
        &__invalid
          margin: $paddingMedium 0
        &__subscription
          &__options
            margin-top: $paddingBig
            &__subMenu
              margin-bottom: $paddingBig
        &__mailings
          svg
            margin-right: $paddingSmall
            width: 16px
            height: 18px
            fill: $colorLink
            cursor: pointer
            &:hover
              fill: $colorLinkHover
          &__info
            display: flex
            justify-content: space-between
            p
              a
                margin-left: 6px
            &__left
              width: calc(50% - #{$paddingBig})
            &__right
              width: calc(50% - #{$paddingBig})
          &__section
            margin: calc(#{$paddingBig} * 1.5) 0 0 0
            &__header
              display: flex
              &__left
                width: 24px
                font-size: $fontSizeStandard3
                font-weight: 700
                margin-right: $paddingSmall
                text-align: right
              &__right
                flex-grow: 1
                font-size: $fontSizeStandard3
                font-weight: 700
            &__content
              margin-top: $paddingMedium
              margin-left: calc(24px + #{$paddingSmall})
              .postageItemWrapper
                display: flex
                align-items: center
                margin-bottom: 4px
                &:last-child
                  margin-bottom: 0
                &__postageItem
                  display: flex
                  align-items: center
                  margin-left: $paddingMedium
                  cursor: pointer
                  color: $colorLink
                  &:first-child
                    margin-left: 0
                  &:hover
                    color: $colorLinkHover
                    svg
                      fill: $colorLinkHover
                  &.disabled
                    pointer-events: none
                    color: $colorTextLight-2
                    svg
                      fill: $colorTextLight-2
                  p
                    margin: 0
                  svg
                    margin-right: $paddingSmall
                    width: 16px
                    height: 16px
                    fill: $colorLink
              &__row
                margin-top: $paddingMedium
                &:first-child
                  margin-top: 0
        &__summary
          h4
            text-transform: none
            margin-bottom: 4px
          p
            display: flex
            flex-wrap: wrap
            span
              white-space: nowrap
          &__section
            margin-top: $paddingMedium
            &:first-child
              margin-top: 0
            &__item
              display: flex
              align-items: flex-start
              margin-bottom: 4px
              &:last-child
                margin-bottom: 0
              p
                margin: 0
              .iconStyled
                margin-right: $paddingMedium
    &__footer
      margin-top: $paddingBig
