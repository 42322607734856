@media (max-width: $breakpoint6)
  .searchMainWrapper
    &__searchMain
      &.open
        position: fixed
        width: calc(100vw - (#{$paddingBig} * 2))
        left: $paddingBig
        top: 9px

@media (max-width: $breakpoint9)
  .searchMainWrapper
    width: $heightHeaderIcons
    &__searchMain
      width: $heightHeaderIcons
