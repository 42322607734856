.createNewsItemWrapper
  &__createNewsItem
    &__content
      margin: 0 0 $paddingBig * 2 0
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      &__item
        margin-top: $paddingBig
        width: 100%
        &.half
          width: calc(50% - (#{$paddingBig} / 2))
        &__label
          color: $colorTextLight
          font-size: $fontSizeStandard-2
          margin: 0 0 4px 0
        &__content
          svg.clearImage
            color: $colorLink
            cursor: pointer
            margin-left: $paddingMedium
            width: 16px
            height: 16px
            position: relative
            top: 2px
            &:hover
              color: $colorLinkHover
          input
            height: 36px
          &__newsType
            display: flex
            justify-content: flex-start
            gap: $paddingSmall
            span.tag
              @extend .newsTypeTag
          &__editor
            .ql-toolbar // react-quill adjustments
              background: $grey-100
              border-color: $grey-300
            .ql-container
              border-color: $grey-300
            .ql-picker
              width: 148px !important
            .ql-picker-item
              white-space: nowrap
            .ql-tooltip
              left: 0 !important
