@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap')

.christmasWrapper
    background-color: white
    display: flex
    width: 100%
    height: 100%
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 2rem
    &__sled
        width: clamp(100px, 60%, 500px)
        animation: oscillate 4s infinite ease-in-out alternate-reverse
    &__logo
        margin-top: 2rem
        filter: invert(100%)
        width: clamp(150px, 30%, 500px)
    h1
        font-size: 4rem
        font-family: 'Ms Madi', cursive
        transform: rotate(-5deg)
    h2
        margin-top: 1rem
        font-size: 1.5rem
        font-family: 'Ms Madi', cursive
        text-transform: none
        transform: rotate(-5deg)

@keyframes oscillate
    0%
        opacity: 0.8
        transform: scale(97%) rotate(5deg)
    50%
        opacity: 1
        transform: scale(100%) rotate(0deg)
    100%
        opacity: 0.8
        transform: scale(97%) rotate(-5deg)
@keyframes reveal
    0%
        opacity: 0
    100%
        opacity: 1
