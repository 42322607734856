.pdfPreviewWrapper
  &__pdfPreview
    @extend .shadowSmallDark
    padding: $paddingMedium
    &__document
      display: flex
      justify-content: center
      max-height: 400px
      overflow-y: auto
      overflow-x: hidden
      @extend .scrollbarRegular
    &__pagination
      display: flex
      align-items: center
      justify-content: center
      gap: $paddingBig
      margin: $paddingMedium auto 0 auto
