.loadingWrapper
	width: 100%
	height: 100%
	padding: $paddingBig 0
	display: flex
	justify-content: center
	align-items: center
	z-index: 6
	.MuiCircularProgress-root
		width: 32px !important
		height: 32px !important
	.MuiCircularProgress-colorPrimary
		color: $colorLink
	svg.MuiCircularProgress-svg
		width: 32px
		height: 32px
	&.informatory
		.MuiCircularProgress-colorPrimary
			color: $colorInformatory
	&.white
		.MuiCircularProgress-colorPrimary
			color: white

.loadingWrapperSmall
	width: 90%
	max-width: 70px
	padding: $paddingSmall 0
	display: flex
	align-items: center
	justify-content: flex-start
	.MuiLinearProgress-root
		height: 1px
		flex-grow: 1
		min-width: 40px
		max-width: 100px
	.MuiLinearProgress-colorPrimary
		background: rgba(0, 183, 207, 0.3) // Same rgb as $colorLink
	.MuiLinearProgress-barColorPrimary
		background: $colorLink
	&.white
		.MuiLinearProgress-colorPrimary
			background: rgba(255, 255, 255, 0.3)
		.MuiLinearProgress-barColorPrimary
			background: white

span.loadingWrapperSmall
	display: inline-flex
