.colleaguesRowsWrapper
  &__colleaguesRows
    &__content
      &__selectedUsers
        display: flex
        align-items: flex-start
        margin-bottom: $paddingBig
        p
          margin: 0 !important
        span.bullet
          margin: 0 $paddingSmall
          color: $colorTextLight
        &__label
          white-space: nowrap
          margin-right: $paddingSmall
          font-weight: 600
        &__users
          flex-grow: 1
          display: flex
          align-items: center
          flex-wrap: wrap
      &__colleagues
        &__dealer
          margin-top: $paddingBig
          &:first-of-type
            margin-top: 0
          &__toggleAll
            width: 100%
            display: inline-flex
            align-items: flex-start
            margin-top: $paddingMedium
            cursor: pointer
            color: $colorTextMain
            font-size: $fontSizeStandard1
            line-height: $fontSizeStandard5
            font-weight: 600
            &:hover
              color: $colorLink
              svg
                fill: $colorLink
            svg
              width: 16px
              height: 16px
              fill: $colorTextLight
              margin-right: $paddingSmall
              position: relative
              top: 4px
            &.active
              color: $colorLink
              svg
                fill: $colorLink
          &__users
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            &__item
              width: calc(50% - #{$paddingMedium})
              display: flex
              align-items: flex-start
              color: $colorTextMain
              cursor: pointer
              font-size: $fontSizeStandard1
              line-height: $fontSizeStandard5
              &:hover
                color: $colorLink
                svg
                  fill: $colorLink
              &:nth-child(odd)
                margin-right: calc(#{$paddingMedium} / 2)
              &:nth-child(even)
                margin-left: calc(#{$paddingMedium} / 2)
              &.disabled
                color: $colorTextLight-1
                cursor: pointer
                pointer-events: none
                svg
                  fill: $colorTextLight-2
              &.active
                color: $colorLink
                svg
                  fill: $colorLink !important
              svg
                width: 16px
                height: 16px
                fill: $colorTextLight
                margin-right: $paddingSmall
                position: relative
                top: 4px
