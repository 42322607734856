:root {
  --changeFontSize: 20px;
}

.widgetsWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: clamp(40vh, 400px, 42vh);
  gap: 8px;
  padding: 4px;
  min-height: 50vh;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: clamp(30vh, 300px, 40vh);
  }
}

.noscroll {
  overflow: hidden;
  max-height: 90vh;
}

.container {
  min-height: 90vh;
}
