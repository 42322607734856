.gdprWrapper
  &__gdpr
    &__content
      margin: 0 auto
      padding: ($paddingBig * 2) 0 0 0
      width: 70%
      min-width: 320px
      overflow: auto
      -webkit-overflow-scrolling: touch
      &__bottom
        max-height: 50vh
        overflow: auto
        margin: $paddingBig 0
        background: white
        padding: $paddingBig
        h3
          text-transform: none
          margin-bottom: 4px
        p
          margin-bottom: $paddingSmall
        &__section
          margin-top: $paddingBig
          &:first-of-type
            margin-top: 0
          .subHeadline
            font-weight: 600
            margin-bottom: 0
    &__footer
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      margin: 0 auto $paddingBig auto
      width: 70%
      &__links
        display: flex
        flex-wrap: wrap
        margin-bottom: $paddingMedium
        a
          margin-right: $paddingMedium
