.wrapper {
  --activeBorder: 2px solid #6262f0;
  --activeBg: #d9d9ff;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 16px;
  border-bottom: 1px groove lightgray;

  .container {
    display: flex;
    gap: 36px;
  }

  .tabs {
    display: flex;
    gap: 8px;
  }

  @media only screen and (max-width: 900px) {
    gap: 12px;
    flex-direction: column;

    .tabs {
      display: flex;
      flex-wrap: wrap;
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .button {
    font-size: 17px;
    padding: 4px 12px;
    background-color: transparent;
    border: 2px solid transparent;
    cursor: pointer;

    &.active {
      border-bottom: var(--activeBorder);
    }

    &.inactive {}

    &.link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      gap: 2px;

      &>svg {
        height: 17px;
      }
    }
  }

  .button:hover {
    border-bottom: var(--activeBorder);
  }

  .dropdown {
    display: flex;
    cursor: pointer;

    &>svg {
      align-self: center;
    }

    &:not(.open) {
      .dropdownContent {
        display: none;
        background-color: #ffffff;
      }
    }

    &.open {
      .dropdownContent {
        display: flex;
        gap: 4px;
        flex-direction: column;
        position: fixed;
        z-index: 1;
        box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
      }
    }

    .actionWrapper {
      border: 1px solid lightgray;
      border-bottom: 1px solid gray;
      padding: 12px 16px;
      text-align: center;

      &:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }

    .action {
      font-size: 16px;
      border: none;
      background: none;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: var(--activeBorder);
        cursor: pointer;
      }
    }
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}
