.activityWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__activity
    display: flex
    flex-direction: column
    flex-grow: 1
    background: white
    &__content
      //background: white
      //border-left: 1px solid $grey-300
      flex-grow: 1
      display: flex
      flex-direction: column
      position: relative
      &__item
        border-bottom: 1px solid $grey-300
        padding: $paddingBig ($paddingBig * 1.3) // Give some space to preview button
        &:last-of-type
          border-bottom: 0

.activityInspectWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__activityInspect
    display: flex
    flex-direction: column
    flex-grow: 1
    &__content
      //margin-top: calc(#{$paddingBig} - #{$paddingMedium})
      flex-grow: 1
      padding: $paddingBig $paddingGrid 0 $paddingGrid
      display: flex
      flex-direction: column
.activity-card-border
  border: 1px solid rgba(0,0,0,0.2)
  border-radius: 8px
  margin-bottom: 12px
  box-shadow: 5px 5px 12px 0 rgba(0,0,0,0.1)
