@media (max-width: $breakpoint2)
  .dealFilesWrapper
    &__dealFiles
      &__content
        &__files
          &__file
            width: calc(50% - (#{$paddingSmall} / 2))
            &:nth-child(3n)
              margin-right: $paddingSmall
            &:nth-child(even)
              margin-right: 0
            &:nth-child(1), &:nth-child(2)
              margin-top: 0
            &:nth-child(3n)
              margin-top: $paddingSmall

@media (max-width: $breakpoint3)
  .dealProspectsWrapper
    &__dealProspects
      &__content
        flex-direction: column
        justify-content: flex-start
        &__prospects
          width: 100%
          margin-top: $paddingBig
        &__search
          width: 100%
          margin: 0
  .dealCarsWrapper
    &__dealCars
      &__content
        flex-direction: column
        justify-content: flex-start
        &__cars
          width: 100%
          margin-top: $paddingBig
        &__search
          width: 100%
          margin: 0

@media (max-width: $breakpoint4)
  .dealMainWrapper
    &__dealMain
      &__content
        &__item
          width: calc(50% - ((1 * 2px) / 2))
          &:nth-child(3n)
            margin-right: 0
          &:nth-child(even)
            margin-right: 0
          &:nth-child(odd)
            margin-right: 2px

@media (max-width: $breakpoint7)
  .dealFilesWrapper
    &__dealFiles
      &__content
        &__files
          &__file
            width: 100%
            margin-top: $paddingSmall
            &:nth-child(even)
              margin-right: 0
            &:nth-child(1), &:nth-child(2), &:nth-child(3)
              margin-right: 0
              margin-top: $paddingSmall

@media (max-width: $breakpoint8)
  .dealMainWrapper
    &__dealMain
      &__content
        &__item
          width: 100%
          margin-right: 0
          &:nth-child(odd)
            margin-right: 0
