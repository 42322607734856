.widgetFooterWrapper
  &__widgetFooter
    color: $colorTextMain
    border-top: 1px solid $grey-300
    display: flex
    justify-content: space-between
    flex-direction: row-reverse
    flex-wrap: wrap-reverse
    &.noBorder
      border: 0
    button
      margin-top: $paddingMedium
    &__container
      display: flex
      gap: $paddingSmall
      &__flex
        flex: 1
        margin-left: 6px
