$popup-background-color: #84a59d

  
.popupMap
  display: flex
  flex-wrap: nowrap
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  align-content: stretch
  height: 95vh
  width: 95vw
  padding: 8px 8px 8px 8px
  background-color: #fff
  .map_top
    margin-top: .5em
    
.map
  height: 100%
  width: 100%
  flex-basis: auto
  outline: none
  &:focus
    outline: none  

.map-overlay 
  position: absolute
  bottom: 0
  right: 0
  background-color: #fff
  opacity: 0.9
  margin-right: 20px
  font-family: Arial, sans-serif
  overflow: auto
  border-radius: 3px

#legend 
  padding: 10px
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
  line-height: 18px
  height: 150px
  margin-bottom: 20px
  width: 100px

.legend-key 
  display: inline-block
  border-radius: 20%
  width: 10px
  height: 10px
  margin-right: 5px

.map_foot
  max-height: 8em
  overflow-y: auto
.mapboxgl-ctrl-logo
    display: none !important 
.zip
  fontFamily: "Helvetica Neue"
  color: "blueGrey"

.zips
  fontFamily: 'Helvetica Neue'
  color: 'blueGrey'

.item__row__separator
    display: flex
    justify-content: space-between
    align-items: center

.buttons__tight__right
    display: flex
    flex-wrap: nowrap
    flex-direction: row
    justify-content: flex-end
    align-items: center
    align-content: center
    &__item
        flex: 0 0 auto
        margin: 2px

.buttons__tight__left
    display: flex
    flex-wrap: nowrap
    flex-direction: row
    justify-content: flex-start
    align-items: center
    align-content: center
    &__item
        flex: 0 0 auto
        margin: 2px

.buttons__center__group
    display: flex
    flex-wrap: nowrap
    justify-content: center
    align-items: center
    &__item
        flex: 0 0 auto
        margin: 4px

@keyframes popupfadein
  0%
    opacity: 0
  20%
    opacity: 0
  40%
    opacity: 0.2
  70%
    opacity: 0.5
  100%
    opacity: 1

.mapboxgl-popup
  animation: popupfadein 0.3s
  background: none
  pointer-events: none
  pointer-events: none


.mapboxgl-popup-close-button 
    display: none


.mapboxgl-popup-content 
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif
    padding: 0
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2)
    background: $popup-background-color
    pointer-events: none
    pointer-events: none
    
.mapboxgl-popup-content-wrapper
    padding: 1%
    pointer-events: none
    pointer-events: none

.mapboxgl-popup-content h5
    margin: 0
    display: block
    padding: 10px
    border-radius: 4px 4px 4px 4px
    font-weight: 700
    pointer-events: none
    pointer-events: none

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip 
    border-bottom-color: $popup-background-color
       
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip
    border-top-color: $popup-background-color

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip
    border-right-color: $popup-background-color

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip
    border-left-color: $popup-background-color


.mapboxgl-popup-content h4 
    margin: 0
    display: block
    padding: 10px
    font-weight: 400


.mapboxgl-popup-content div 
    padding: 10px

.icon-active
    color: #18A1DE

.MuiOutlinedInput-root
  input
    padding: $paddingMedium
