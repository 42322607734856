.infoWrapper
  width: 100%
  white-space: normal
  @media (max-width: 500px)
    .infoWrapper
      &__info
        flex-direction: column
        gap: $paddingSmall
  &.inline
    width: auto
    .infoWrapper
      &__info
        padding: $paddingMedium
        &__left
          svg
            width: 22px !important
            height: 22px !important
        h4
          font-size: $fontSizeStandard
          line-height: $fontSizeStandard

  &__info
    background: white
    padding: $paddingBig
    color: $colorTextMain
    border-top: 1px solid $colorInformatory
    border-right: 1px solid $colorInformatory
    border-bottom: 1px solid $colorInformatory
    border-left: 6px solid $colorInformatory
    border-radius: 4px
    display: flex
    align-items: stretch
    gap: $paddingBig
    &__left
      width: 24px
      display: flex
      align-items: flex-start
      justify-content: center
      svg
        fill: $colorInformatory !important
        width: 26px !important
        height: 26px !important
    h4
      margin-bottom: $paddingSmall
      text-transform: none
      font-size: $fontSizeStandard4
      line-height: $fontSizeStandard5
      font-weight: 700
    p
      margin-bottom: $paddingSmall !important
      &:last-child
        margin-bottom: 0 !important
