.details {
  --detailsColor: var(--grey-200);
  margin-top: 18px;
  user-select: none;
  background-color: var(--detailsColor);
  border-radius: 5px;
  & > summary {
    cursor: pointer;
    background-color: var(--grey-100);
    border-radius: 5px;
    padding: 12px 8px;
    border: 1px solid transparent;
    &:hover {
      background-color: var(--detailsColor);
      border: 1px solid var(--grey-400);
    }
  }
  & * {
    user-select: auto;
  }
  .orderStepHolder {
    padding: 12px 16px;
  }
}
