.arrow
    overflow: hidden
    height: 100%
    background-color: white
    padding: 1rem
    display: flex
    align-items: center
    color: $grey-600
    cursor: pointer
    border-top-right-radius: 5px
    border-bottom-right-radius: 5px
    &:hover
        background-color: $colorPrimary-500
        transition: 0.5s
        color: white
        .arrow__icon
            animation: moveHorizontal 1s infinite
    &.backwards
        transform: rotate(-180deg)
.relative
    position: relative

.genericWrapper
    img
        height: clamp(400px, 40%, 200px)
        width: clamp(400px, 40%, 200px)
        object-fit: contain
        border-radius: 5px
        max-width: 100%
        max-height: 100%
    a
        color: white

.close
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    color: white
    top: -1.25rem
    padding: .5rem
    right: 1em
    transition: 0.3s
    cursor: pointer
    border-radius: 50%
    background-color: $colorPrimary-500
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.6)
    &:hover
        opacity: 1
        transition: 0.3s
        box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4)
        transform: scale(105%)
.pageDisplay
    text-align: end
    padding-right: 1rem
    color: $grey-200
    font-size: 1.5rem
    background-color: $colorPrimary-500
.pageIndicator
    display: flex
    gap: 1rem
    padding: 0.5rem
    width: 100%
    justify-content: center
    color: $grey-200
    font-size: 1.5rem
    background-color: $colorPrimary-500
@keyframes moveHorizontal
    0%
        transform: translateX(-1rem)
        opacity: 0
    50%
        opacity: 1
    100%
        opacity: 0
        transform: translateX(1rem)
