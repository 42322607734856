.widgetKanbanBoardWrapper
  display: flex
  flex-direction: column
  flex-grow: 1
  &__widgetKanbanBoard
    display: flex
    flex-direction: column
    flex-grow: 1
    &__content
      width: 100%
      flex-grow: 1
      padding: 0 $paddingGrid $paddingGrid $paddingGrid
      display: flex
      flex-direction: column
      & > .react-trello-board // Override react-trello style
        display: flex
        flex-direction: column
        height: 100%
        width: 100%
        background: unset
        padding: 0
        overflow: hidden
        color: $colorTextMain
        & > div
          height: 100%
          width: 100%
          & > .smooth-dnd-container
            &.horizontal
              flex-grow: 1
              display: grid
              grid-gap: $paddingGrid
              grid-auto-flow: column
              grid-auto-columns: calc(50% - (#{$paddingGrid} / 2))
              width: 100%
              & > section
                width: 100%
                min-width: 100%
                max-height: 100%
                display: inline-flex
                flex-direction: column
                padding: 0
                margin: 0
                background: unset
                & > header
                  display: none
                & > div
                  min-width: unset
                  width: 100%
                  overflow-y: visible
                  flex-grow: 1
                  max-height: 100%
                  margin-top: 0
                  display: flex
                  flex-direction: column
                  & > .smooth-dnd-container
                    &.vertical
                      flex-grow: 1
                      & > .smooth-dnd-draggable-wrapper
                        overflow: visible
                        cursor: move
                        cursor: -webkit-grab
                        cursor:    -moz-grab
                        cursor:         grab
                        &:active
                          cursor: -webkit-grabbing
                          cursor:    -moz-grabbing
                          cursor:         grabbing
      &__info
        margin-top: $paddingGrid
        background: white
        padding: $paddingBig
        width: 50%
        @extend .paperSheet

.widgetKanbanBoardItemWrapper
  &__widgetKanbanBoardItem
    margin-top: $paddingGrid
    @extend .paperSheet
    overflow: hidden
    padding: $paddingBig
