@media (max-width: $breakpoint0)
  .settingsWrapper
    &__settings
      &__content
        &__right
          .settingsPageWrapper
            &__settingsPage
              &__section
                &__content
                  .toggleOptionsHolder
                    flex-wrap: wrap
                    &__item
                      width: 100%
                      margin: 0
                      padding: 0
                  .prospectGroupsWrapper
                    &__prospectGroups
                      &__content
                        &__right
                          &__group
                            width: 100%
                  .prospectPresetsWrapper
                    &__prospectPresets
                      &__content
                        &__preset
                          width: 100%
                  .pdfArchiveManageFilesWrapper
                    &__pdfArchiveManageFiles
                      &__content
                        &__archive
                          &__items
                            &__item
                              width: 100%

@media (max-width: $breakpoint1)
  .settingsWrapper
    &__settings
      &__content
        &__right
          .settingsPageWrapper
            &__settingsPage
              &__section
                &__content
                  .prospectGroupsWrapper
                    &__prospectGroups
                      &__content
                        flex-direction: column
                        &__left
                          width: 100%
                        &__right
                          width: 100%

@media (max-width: $breakpoint2)
  .settingsWrapper
    &__settings
      &__content
        &__right
          .settingsPageWrapper
            &__settingsPage
              &__section
                &__content
                  &__holder
                    div.inputHolder
                      flex-direction: column
                      height: unset
                      align-items: flex-start
                      gap: $paddingSmall
                      &__left
                        width: 100%
                        max-width: 100%
                  &__flexHolder
                    gap: $paddingSmall
                    &__half
                      width: 100%
                      margin: 0
                      &:last-of-type
                        margin-bottom: $paddingSmall
                    &__third
                      .userImage
                        min-width: 90px
                        width: 90px
                        height: 90px
                        svg
                          width: 70px
                          height: 70px
                      .userImageBtn
                        top: 70px
                        left: -20px
                    &__twoThirds
                      .userInfoRow
                        &.connections
                          display: none
                        &__left
                          display: none
                        &__right
                          form
                            input
                              max-width: 100%
                  &__pdfArchiveWrapper
                    &__pdfArchive
                      &__content
                        &__info
                          flex-direction: column
                          &__left
                            width: 100%
                            margin: 0
                          &__right
                            width: 100%
                            margin: $paddingBig 0 0 0
                  .formStyled
                    &.password
                      .formStyled__row
                        order: 5
                        &:nth-child(1)
                          order: 1
                        &:nth-child(2)
                          order: 3
                        &:nth-child(3)
                          order: 2
                        &:nth-child(4)
                          order: 4
                    &__row
                      &.half
                        width: 100%
                        margin-left: $paddingBig
                        margin-right: $paddingBig
                        &:nth-child(odd)
                          margin-right: $paddingBig

@media (max-width: $breakpoint3)
  .settingsWrapper
    &__settings
      &__content
        &__left
          &__item
            &__row
              &.main
                span
                  display: none
                svg
                  width: 20px
                  height: 20px
              &.sub
                display: none
        &__right
          .settingsPageWrapper
            &__settingsPage
              &__section
                &__header
                  padding: 11px $paddingBig 10px $paddingBig
                &__content
                  &__flexHolder
                    &.userInfoContainer
                      .settingsPageWrapper__settingsPage__section__content__flexHolder__third
                        display: none
                      .settingsPageWrapper__settingsPage__section__content__flexHolder__twoThirds
                        width: 100%
                  &__pdfArchiveWrapper
                    &__pdfArchive
                      &__content
                        &__manageFiles
                          .pdfArchiveManageFilesWrapper
                            &__pdfArchiveManageFiles
                              &__content
                                &__upload
                                  &__rowOne
                                    display: block
                                    &__file
                                      display: block
                                      &__custom
                                        margin-bottom: $paddingSmall
@media (max-width: $breakpoint7)
  .settingsWrapper
    &__settings
      &__content
        .inputOptionsHolder
          &__item
            width: 100%
        &__right
          .settingsPageWrapper
            &__settingsPage
              &__section
                &__content
                  &__holder
                    div.inputHolder
                      &__left
                        flex-direction: column
                        align-items: flex-start
                        gap: 0
                  .formStyled
                    padding-top: $paddingMedium
                    &__row
                      margin-left: $paddingMedium
                      margin-right: $paddingMedium
                      &.extraPadding
                        padding: $paddingMedium
                      &.half
                        margin-left: $paddingMedium
                        margin-right: $paddingMedium
                        &:nth-child(odd)
                          margin-right: $paddingMedium
                          margin-left: $paddingMedium
                      &.buttons
                        flex-direction: column
                        margin-left: 0
                        margin-right: 0
                        & > div
                          width: 100%
                      .answer
                        &__option
                          margin-right: $paddingBig


@media (max-width: $breakpoint8)
  .settingsWrapper
    &__settings
      &__content
        &__right
          .settingsPageWrapper
            &__settingsPage
              &__section
                &__content
                  .prospectGroupsWrapper
                    &__prospectGroups
                      &__content
                        &__left
                          &__formHolder
                            &__content
                              .infoWrapper
                                margin-left: 0 !important
                                width: 100% !important
                              span.label
                                display: none
                              input
                                width: 100%
                              &__searchWrapper
                                &__searchHolder
                                  width: 100%
                              &__chipsHolder
                                &__chips
                                  width: 100%
                                  &__chip
                                    width: 100%
                                    max-width: 100%
                                    margin-right: 0
                                    &__left
                                      flex-grow: 1
                            &__footer
                              margin: 0

@media (max-width: $breakpoint9)
  .settingsWrapper
    &__settings
      &__content
        &__right
          .settingsPageWrapper
            .infoWrapper
              &__info
                p
                  display: none // Hide info text, only keep headlines

@media (max-width: $breakpoint10)
  .settingsWrapper
    &__settings
      &__content
        &__right
          div#toggleWidgets, div#toggleWidgetsAnalyse
            .settingsPageWrapper
              &__settingsPage
                &__section
                  &__content
                    p
                      &:nth-child(1), &:nth-child(2)
                        display: none
                    &__holder
                      &.marginBottom
                        margin-bottom: 0
          div#manageColumns
            .settingsPageWrapper
              &__settingsPage
                &__section
                  &__content
                    p
                      &:nth-child(1), &:nth-child(2)
                        display: none
                    .inputHolder
                      margin-top: 0
          div#pdfArchive
            .settingsPageWrapper
              &__settingsPage
                &__section
                  &__content
                    .settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper__pdfArchive__content__info
                      display: none
          div#groupsPostcodes, div#groupsDealer, div#groupsUserIdsShort, div#prospectPresets
            .settingsPageWrapper
              &__settingsPage
                &__section
                  &__content
                    p
                      &:nth-child(1)
                        display: none
                    .prospectPresetsWrapper__prospectPresets__content__row
                      &.marginBottomMedium
                        margin-bottom: 0
