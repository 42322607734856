.dragAndDropWrapper
    &__container
        border: 2px dotted #ccc
        border-radius: 5px
        padding: 20px
        width: 100%
        margin-bottom: 10px
        height: 150px
        display: flex
        justify-content: center
        align-items: center
        &__hiddenInput
            display: none
