.activitiesWrapper
  white-space: normal
  width: 100%
  height: 100%
  overflow: hidden
  &__activities
    height: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    &__content
      margin-top: $paddingBig
      height: 100%
      display: flex
      flex-direction: column
      flex-grow: 1
      overflow: hidden
      &__processingRate__red
        font-weight: bold
        color: $colorFail
      &__processingRate__green
        font-weight: bold
        color: $colorSuccess
      &__processingRate__yellow
        font-weight: bold
        color: $colorInformatory
      @extend .widgetHeightAnimation
      &.minimize
        @extend .widgetHeightAnimationMinimized
      &.activitiesResponsiveWidth1
        .activitiesWrapper__activities__content__top
          margin-bottom: $paddingMedium
          &__left
            width: 100%
            min-width: 100%
          &__right
            width: 100%
            min-width: 100%
            &__selectActivities
              width: 100%
              min-width: 100%
      &__top
        margin-bottom: calc(#{$paddingBig} * 1.5)
        display: flex
        justify-content: space-between
        align-items: flex-start
        gap: $paddingBig
        flex-wrap: wrap
        &.noMarginBottom
          margin-bottom: 0
        &__left
          width: calc(50% - #{$paddingMedium})
          min-width: 300px
          max-width: 380px
          &.embedded
            width: 72%
        &__right
          display: flex
          justify-content: flex-end
          align-items: flex-end
          &__selectActivities
            min-width: 240px
      &__middle
        flex-grow: 1
        overflow: hidden // Important for responsive solution
      &__bottom
        margin-top: $paddingBig

.activitiesFlowWrapper
  width: 100%
  white-space: normal
  height: 100%
  overflow: hidden // Important for responsive solution
  &__activitiesFlow
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden // Important for responsive solution
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      overflow: hidden // Important for responsive solution
      &__middle
        overflow: hidden // Important for responsive solution
        position: relative
        flex-grow: 1
        display: flex
        flex-direction: column
        &__items
          //flex-grow: 1
          overflow: auto // Important for responsive solution
          max-height: 100%
          @extend .scrollbarRegular

          .buttonContainer
            display: flex
            justify-content: center
            align-items: center
            gap: $paddingBig

        &__itemsHidden
          position: absolute
          top: 0
          z-index: -1
          max-height: 10px
          overflow: scroll
          //display: none
          @extend .scrollbarHidden
      &__bottom
        margin: $paddingMedium 0
        display: flex
        justify-content: center
        .actionButtonWrapper
          margin-right: $paddingBig
          &:last-of-type
            margin-right: 0

.activitiesFlowItemWrapper
  position: relative
  white-space: normal
  color: $colorTextMain
  border-bottom: 1px solid $grey-300
  padding: $paddingBig $paddingMedium
  &:last-child
    border-bottom: none

  &:hover
    .activitiesFlowItemWrapper__editBox
      display: flex
    .activitiesFlowItemWrapper__activitiesFlowItem__right__right
      display: flex
  &.activitiesFlowItemResponsiveWidth1
    padding: $paddingBig 0
    .activitiesFlowItemWrapper__activitiesFlowItem
      &__left
        display: none
      &__right
        flex-direction: column
        align-items: flex-start
        &__left
          &__action
            & > div
              font-size: $fontSizeStandard2
              line-height: $fontSizeStandard2
              word-break: break-all
            a
              word-break: break-all
        &__right
          margin-top: $paddingSmall
          display: flex
          &__edit
            justify-content: flex-start
  &__editBox
    display: none
    justify-content: center
    align-items: center
    gap: $paddingBig
    padding: $paddingSmall 0 $paddingMedium * 1.5 0
  &__activitiesFlowItem
    width: 100%
    word-break: break-word
    display: flex
    span.highlight
      font-weight: 600
    span.marginRight
      margin-right: 6px
    &__left
      display: flex
      align-items: center
      margin-right: $paddingBig
    &__right
      flex-grow: 1
      display: flex
      align-items: center
      flex-wrap: wrap
      gap: $paddingMedium
      &__left
        flex-direction: column
        justify-content: center
        flex-grow: 1
        &__action
          & > div
            font-size: $fontSizeStandard3
            line-height: $fontSizeStandard4
            font-weight: 600
          &__eventDate
            margin: 0 0 $paddingSmall * 0.5
            font-size: $fontSizeStandard1
          & > p
            margin-top: 0
        &__text
          color: $colorTextLight
          &__reason
            display: flex
            align-items: center
            flex-wrap: wrap
          &__comment
            display: flex
            align-items: center
            flex-wrap: wrap
            span.comment
              color: $colorTextMain
              font-weight: 600
              line-height: 1.4em
        &__footer
          display: flex
          align-items: center
          justify-content: flex-start
          flex-wrap: wrap
          color: $colorTextLight
          margin-top: 4px
          &__date
            margin-right: 6px
          &__user
            word-break: break-word
      &__right
        display: none
        align-items: center
        &__edit
          min-width: 60px
          display: flex
          align-items: center
          justify-content: flex-end
          gap: $paddingMedium
          flex-wrap: wrap
    &__popup
      padding: $paddingBig
      &__content
        margin: $paddingBig 0
        &__row
          margin-top: $paddingBig
          &:first-of-type
            margin-top: 0
          span
            margin-bottom: 4px
            display: inline-block
            color: $colorTextLight

.activitiesExcelWrapper
  &__activitiesExcel
    &__content
      margin: $paddingBig 0
      &__initial
        &__toggle
          margin-top: 3em
          max-width: 270px
      &__complete
        display: flex
        align-items: center
        justify-content: space-between
        margin-left: 1em
        margin-right: 1em
        &__right
          margin-left: 3em
        svg
          color: $colorSuccess
